import { Component, OnInit } from '@angular/core';
import { CustomTranslationService, BreakpointObserverService } from 'shared';
import { Consts } from '../../consts';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(public customTranslationService: CustomTranslationService, public breakpointObserverService: BreakpointObserverService) {
  }

  ngOnInit(): void {
  }

  public getToday(){
    return new Date();
  }

  public getGdprLink(): string{
    return Consts.gdprRoute;
  }

  public getTermsOfTrade(): string{
    return Consts.informationRoute;
  }
}
