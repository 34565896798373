import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService, CompaniesService, Company, BreakpointObserverService } from 'shared';
import { Consts } from '../../consts';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-club-admin-companies',
  templateUrl: './club-admin-clubs.component.html',
  styleUrls: ['./club-admin-clubs.component.scss']
})
export class ClubAdminClubsComponent implements OnInit, OnDestroy {
  public clubs$: Observable<Company[]>;
  private subscription = new Subscription();

  constructor(public authService: AuthService,
              private companyService: CompaniesService,
              public breakObserverService: BreakpointObserverService) {
    this.subscription.add(this.authService.userChanged$
      .subscribe(u => {
          if (u) {
            this.clubs$ = this.companyService.getAdminsCompanies().pipe(map(companies => companies.filter(c => !c.isSponsor)));
          }
        }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editClub(clubId: number): string {
    return `/${Consts.createEditClubRoute}/${clubId}`;
  }

  public addClub(): string {
    return `/${Consts.createEditClubRoute}`;
  }
}
