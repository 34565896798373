<div class="container-fluid bigger-body-font-size">
  <div class="row">
    <div class="col-md-12">
      <div class="banner d-flex justify-content-center align-items-center" style="background-image: url('assets/user/banner.png')">
        <img src="assets/user/title.png" class="title"/>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-12">
      <div class="big-header-container">
        <h1 class="big-header font-italic">{{'user.companies' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5" *ngFor="let club of (clubs$ | async)">
    <div class="col-md-12">
      <div class="container-fluid ps-0 pe-0">
        <div class="row event" *ngFor="let club of (clubs$ | async)">
          <div class="col-md-3">
            <div class="event-name">
              {{club.name}}
            </div>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <a class="content-button button-text text-uppercase font-italic" [routerLink]="goToClub(club.id)">
              {{'user.goToClub' | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
