<div class="tabs-header-container align-items-center ps-4 pe-4">
  <div class="tabs-header ">{{'tab-event-invite-sponsor.title' | translate}}</div>
</div>
<div class="ps-4 pe-4">
  <div class="tabs-content mt-4 mb-4">
    <div class="mt-2" *ngIf="!sponsorLink">{{'tab-event-invite-sponsor.notParticipating' | translate}}</div>
    <div class="mt-2" *ngIf="sponsorLink">
      <div class="mb-2">{{'tab-event-invite-sponsor.text' | translate}}</div>
      <div *ngIf="sponsorLink">
        <div class="mb-2">
          {{getSponsorLinkUrl()}}
        </div>
        <div>
          <button [cdkCopyToClipboard]="getSponsorLinkUrl()" (cdkCopyToClipboardCopied)="copied($event)" class="content-button-small" type="button" name="copy">
            <span>{{'global.copy' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
