import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { AuthService, EventShopUser, UsersService, SearchUser } from 'shared';
import { Observable, Subscription } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tab-event-user-admin',
  templateUrl: './tab-event-user-admin.component.html',
  styleUrls: ['./tab-event-user-admin.component.scss']
})
export class TabEventUserAdminComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public eventId: number;

  public adminUsers$: Observable<EventShopUser[]>;

  public user: EventShopUser;
  public userSearch: string;
  public foundUsers: SearchUser[];
  private subscription = new Subscription();
  @ViewChild('modal') modalElement: ElementRef;
  private ngbModalRef: NgbModalRef;

  constructor(private eventsService: EventsService, private authService: AuthService, private usersService: UsersService, private ngbModal: NgbModal) {
    this.subscription.add(this.authService.userChanged$.subscribe(u => this.user = u));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes): void {
    if (changes["eventId"] && this.eventId) {
      this.adminUsers$ = this.eventsService.getAdmins(this.eventId);
    }
  }

  public removeUser(userId: number) {
    this.eventsService.removeAdmin(this.eventId, userId)
      .pipe(first())
      .subscribe(_ => this.adminUsers$ = this.eventsService.getAdmins(this.eventId));
  }

  public addUser(userId: number) {
    this.cancelButtonPressed();
    this.eventsService.addAdmin(this.eventId, userId).pipe(first())
      .subscribe(_ => this.adminUsers$ = this.eventsService.getAdmins(this.eventId));
  }

  public searchUsers() {
    this.usersService.searchUsers({searchString: this.userSearch})
      .pipe(first()).subscribe(r => {
      this.foundUsers = r.searchResult;
      this.ngbModalRef = this.ngbModal.open(this.modalElement, {size: 'xl', scrollable: true, centered: true})
    });
  }

  public cancelButtonPressed() {
    this.ngbModalRef.close();
  }
}
