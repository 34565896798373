<div class="background">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-5">
        <h1 class="big-header-black">{{'contact.title' | translate}}</h1>
      </div>
    </div>
    <div class="row justify-content-center mt-5 pb-5">
      <div class="col-md-8">
        <div class="body-text text-center">
          {{'contact.bodyText1' | translate}}
        </div>
        <div class=" mt-4 mb-4 d-flex justify-content-center">
          <form [formGroup]="contactForm" (ngSubmit)="sendEmail()">
            <div class="form-group">
              <label class="form-label" for="name">{{'contact.name' | translate}}*</label>
              <input id="name" class="form-control content-input" value="" formControlName="name" type="text" required/>
            </div>
            <div class="form-group">
              <label class="form-label" for="email">{{'contact.email' | translate}}*</label>
              <input id="email" class="form-control content-input" value="" type="email" required formControlName="email" />
            </div>
            <div class="form-group">
              <label class="form-label" for="subject">{{'contact.subject' | translate}}*</label>
              <input id="subject" class="form-control content-input" value="" type="text" required formControlName="subject" />
            </div>
            <div class="form-group">
              <label class="form-label" for="message">{{'contact.message' | translate}}*</label>
              <textarea id="message" class="form-control content-input" value="" type="text" required formControlName="message" ></textarea>
            </div>
            <input id="webUrl" class="visually-hidden" type="text" formControlName="webUrl" >

            <div class="d-flex justify-content-center">
              <div class="pt-1 pb-2">
                <button class="content-button button-text text-uppercase font-italic" type="submit" name="update">
                  {{'contact.send' | translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="body-text text-center mt-3">{{'contact.bodyText2' | translate}}</div>
      </div>
    </div>
  </div>
</div>
