import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { AdminGuard, CompanyAdminGuard, SignedInGuard } from 'shared';
import { CompanySearchComponent } from './components/company-search/company-search.component';
import { CreateEditClubComponent } from './components/create-edit-club/create-edit-club.component';
import { EventsComponent } from './components/events/events.component';
import { SponsorlinkComponent } from './components/sponsorlink/sponsorlink.component';
import { Consts } from './consts';
import { InformationComponent } from './components/information/information.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { GdprComponent } from './components/gdpr/gdpr.component';
import { SponsorTermsComponent } from './components/sponsor-terms/sponsor-terms.component';
import { SignUpSucceededUserComponent } from './components/sign-up-succeeded-user/sign-up-succeeded-user.component';
import { EventTermsComponent } from './components/event-terms/event-terms.component';
import { ParticipateTermsComponent } from './components/participate-terms/participate-terms.component';
import { EditUserInfoComponent } from './components/edit-user-info/edit-user-info.component';
import { TabAdminEventValidateComponent } from './components/tab-admin-event-validate/tab-admin-event-validate.component';
import { EventComponent } from './components/event/event.component';
import { AdminComponent } from './components/admin/admin.component';
import { CreateEditEventComponent } from './components/create-edit-event/create-edit-event.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ClubAdminClubsComponent } from './components/club-admin-clubs/club-admin-clubs.component';
import { ClubAdminEventsComponent } from './components/club-admin-events/club-admin-events.component';
import { UserClubsComponent } from './components/user-clubs/user-clubs.component';
import { SponsoringEventsComponent } from './components/sponsoring-events/sponsoring-events.component';
import { UserEventsComponent } from './components/user-events/user-events.component';
import { EventLinkComponent } from './components/event-link/event-link.component';
import { UserRegisterActivityComponent } from './components/user-register-activity/user-register-activity.component';
import { UserEditSignupComponent } from './components/user-edit-signup/user-edit-signup.component';
import { SponsorPaymentComponent } from './components/sponsor-payment/sponsor-payment.component';
import { WaitingForPaymentComponent } from './components/waiting-for-payment/waiting-for-payment.component';
import { PaymentCompleteComponent } from './components/payment-complete/payment-complete.component';
import { SignUpSucceededSponsorComponent } from './components/sign-up-succeeded-sponsor/sign-up-succeeded-sponsor.component';
import { SignUpSucceededEventComponent } from './components/sign-up-succeeded-event/sign-up-succeeded-event.component';
import { UserSignUpComponent } from './components/user-sign-up/user-sign-up.component';
import { UserChangePasswordComponent } from './components/user-change-password/user-change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/reset-password-link.component';
import { CreateEditCompanyComponent } from './components/create-edit-company/create-edit-company.component';
import { CompaniesAdminCompaniesComponent } from './components/companies-admin-companies/companies-admin-companies.component';
import { InformationArrangeComponent } from './components/information-arrange/information-arrange.component';
import {
  InformationParticipateComponent
} from './components/information-participate/information-participate.component';
import { InformationSponsorsComponent } from './components/information-sponsors/information-sponsors.component';
import { ClubTermsComponent } from './components/club-terms/club-terms.component';
import { UserSignInComponent } from './components/user-sign-in/user-sign-in.component';

export const routes: Routes = [
  {path: 'company-search', component: CompanySearchComponent},
  {path: `${Consts.createEditClubRoute}/:clubId`, component: CreateEditClubComponent},
  {path: `${Consts.sponsorLinkRoute}/:id`, component: SponsorlinkComponent},
  {path: `${Consts.inviteLinkRoute}/:id`, component: EventLinkComponent},
  {path: Consts.informationRoute, component: InformationComponent},
  {path: Consts.informationArrangeRoute, component: InformationArrangeComponent},
  {path: Consts.informationParticipateRoute, component: InformationParticipateComponent},
  {path: Consts.informationSponsorsRoute, component: InformationSponsorsComponent},
  {path: Consts.signUpRoute, component: SignUpComponent},
  {path: Consts.eventsRoute, component: EventsComponent},
  {path: Consts.aboutRoute, component: AboutComponent},
  {path: Consts.contactRoute, component: ContactComponent},
  {path: Consts.sponsorTermsRoute, component: SponsorTermsComponent},
  {path: Consts.participateTermsRoute, component: ParticipateTermsComponent},
  {path: Consts.eventTermsRoute, component: EventTermsComponent},
  {path: Consts.gdprRoute, component: GdprComponent},
  {path: `${Consts.signUpSucceededUserRoute}/:eventId`, component: SignUpSucceededUserComponent},
  {path: `${Consts.signUpSucceededSponsorRoute}/:eventId`, component: SignUpSucceededSponsorComponent},
  {path: `${Consts.signUpSucceededEventRoute}/:eventId`, component: SignUpSucceededEventComponent},
  {path: `${Consts.eventRoute}/:eventId`, component: EventComponent},
  {path: `${Consts.userEditSignUp}/:eventId`, component: UserEditSignupComponent},
  {path: Consts.paymentPending, component: WaitingForPaymentComponent},
  {path: Consts.paymentComplete, component: PaymentCompleteComponent},
  {path: Consts.userSignUpRoute, component: UserSignUpComponent},
  {path: Consts.userChangePasswordRoute, component: UserChangePasswordComponent},
  {path: Consts.userResetPasswordLinkRoute, component: ResetPasswordLinkComponent},
  {path: Consts.userResetPasswordRoute, component: ResetPasswordComponent},
  {path: Consts.clubTerms, component: ClubTermsComponent},

  {path: Consts.userSignIn, component: UserSignInComponent},
  {path: Consts.userInfoRoute, canActivate: [SignedInGuard], component: UserInfoComponent},
  {path: `${Consts.sponsorPayment}/:eventId`, canActivate: [SignedInGuard], component: SponsorPaymentComponent},
  {path: Consts.editUserInfoRoute, canActivate: [SignedInGuard], component: EditUserInfoComponent},
  {path: Consts.userClubsRoute, canActivate: [SignedInGuard], component: UserClubsComponent},
  {path: Consts.userEventsRoute, canActivate: [SignedInGuard], component: UserEventsComponent},
  {path: Consts.userSponsorLinksRoute, canActivate: [SignedInGuard], component: SponsoringEventsComponent},
  {path: `${Consts.registerActivityRoute}/:eventId`, canActivate: [SignedInGuard], component: UserRegisterActivityComponent},

  {path: `${Consts.editEventRoute}/:eventId`, canActivate: [CompanyAdminGuard], component: CreateEditEventComponent},
  {path: Consts.createEditClubRoute, canActivate: [CompanyAdminGuard], component: CreateEditClubComponent},
  {path: `${Consts.createEditClubRoute}/:clubId`, canActivate: [CompanyAdminGuard], component: CreateEditClubComponent},
  {path: `${Consts.createEditCompanyRoute}/:companyId`, canActivate: [CompanyAdminGuard], component: CreateEditCompanyComponent},
  {path: Consts.createEditCompanyRoute,  canActivate: [CompanyAdminGuard], component: CreateEditCompanyComponent},
  {path: Consts.adminClubsRoute, canActivate: [CompanyAdminGuard], component: ClubAdminClubsComponent},
  {path: Consts.adminCompaniesRoute, canActivate: [CompanyAdminGuard], component: CompaniesAdminCompaniesComponent},
  {path: Consts.adminEventsRoute, canActivate: [CompanyAdminGuard], component: ClubAdminEventsComponent},

  {
    path: Consts.adminRoot, canActivate: [AdminGuard], children: [
      {path: '', component: AdminComponent},
      {path: `${Consts.adminEventValidate}/:eventId`, component: TabAdminEventValidateComponent}
    ]
  },
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', scrollOffset: [0,0] })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
