import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CompaniesService, Company } from 'shared';

@Component({
  selector: 'app-user-companies',
  templateUrl: './user-clubs.component.html',
  styleUrls: ['./user-clubs.component.scss']
})
export class UserClubsComponent implements OnInit {
  public clubs$: Observable<Company[]>;

  constructor(private companyService: CompaniesService) {
    this.clubs$ = this.companyService.getUsersCompanies();
  }

  ngOnInit() {
  }

  public goToClub(clubId: number): string {
    return '/';
  }
}
