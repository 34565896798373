<div class="container-fluid bigger-body-font-size">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{'SignUpSucceededEvent.title' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8 body-text">
        <div>
          {{'SignUpSucceededEvent.description' | translate}}
        </div>
        <div>
          <span>{{'SignUpSucceededEvent.linkText' | translate}} </span><a routerLink="{{eventLink()}}">{{'SignUpSucceededEvent.link' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

