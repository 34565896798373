<div class="podium-container d-flex">
  <div class="podium">
    <div class="podium-element">
      <div class="podium-text">{{second | async}}</div>
      <div class="podium-text podium-amount">{{secondAmount | async}}</div>
      <div class="podium-item podium-item-second">2</div>
    </div>
    <div class="podium-element">
      <div class="podium-text">{{first | async}}</div>
      <div class="podium-text podium-amount">{{firstAmount | async}}</div>
      <div class="podium-item podium-item-first">1</div>
    </div>
    <div class="podium-element">
      <div class="podium-text">{{third | async}}</div>
      <div class="podium-text podium-amount">{{thirdAmount | async}}</div>
      <div class="podium-item podium-item-third"><div class="podium-item-third-text">3</div></div>
    </div>
  </div>
</div>
