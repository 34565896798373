import { Component, Input, OnInit } from '@angular/core';
import { SponsorEvent } from '../../models/sponsor-event';
import { GetSponsorEventCompanyOverviewResponse } from '../../models/GetSponsorEventCompanyOverviewResponse';
import { CustomTranslationService, TabsComponent } from 'shared';

import * as moment from 'moment';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-tab-event',
  templateUrl: './tab-event.component.html',
  styleUrls: ['./tab-event.component.scss']
})
export class TabEventComponent implements OnInit {
  @Input()
  public event: SponsorEvent

  @Input()
  public sponsorEventCompanyOverview: GetSponsorEventCompanyOverviewResponse

  constructor(public customTranslationService: CustomTranslationService, private tabsComponent: TabsComponent, public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

  public editEvent() {
    const tab = this.tabsComponent.tabs.find(t => t.name === 'tab-event-edit');
    this.tabsComponent.selectTab(tab);
  }
}
