import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { Consts } from '../../consts';
import { CompaniesService, AuthService, Company, BreakpointObserverService } from 'shared';

@Component({
  selector: 'app-companies-admin-companies',
  templateUrl: './companies-admin-companies.component.html',
  styleUrls: ['./companies-admin-companies.component.scss']
})
export class CompaniesAdminCompaniesComponent implements OnInit {
  public companies: Observable<Company[]>;
  private subscription = new Subscription();

  constructor(public authService: AuthService,
              private companyService: CompaniesService,
              public breakObserverService: BreakpointObserverService) {
    this.subscription.add(this.authService.userChanged$
      .subscribe(u => {
        if (u) {
          this.companies = this.companyService.getAdminsCompanies().pipe(map(companies => companies.filter(c => c.isSponsor)));
        }
      }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editCompany(clubId: number): string {
    return `/${Consts.createEditCompanyRoute}/${clubId}`;
  }

  public addCompany(): string {
    return `/${Consts.createEditCompanyRoute}`;
  }
}
