import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { apiUrlToken } from 'shared';
import { GetSponsorRunUserDataFromLinkDto } from '../models/GetSponsorRunUserDataFromLinkDto';
import { GetSponsorSummeryResponse } from '../models/get-sponsor-summery-response';
import { GetPaymentLinkRequest } from '../models/get-payment-link-request';
import { GetPaymentLinkResponse } from '../models/get-payment-link-response';
import { CheckPaymentRequest } from '../models/check-payment-request';
import { CheckPaymentResponse } from '../models/check-payment-response';
import { GetPaymentSummery } from '../models/get-payment-summery';
import { PaymentSummeryResponse } from '../models/payment-summery-response';

@Injectable({
  providedIn: 'root'
})
export class SponsorRunSponsorService {
  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getSponsorRunUserDataFromLink(link: string): Observable<GetSponsorRunUserDataFromLinkDto> {
    return this.http.get<GetSponsorRunUserDataFromLinkDto>(`${this.apiUrl}/api/SponsorRunUser/GetSponsorRunUserDataFromLink?link=` + link);
  }

  public getEventFromInviteLink(link: string): Observable<GetSponsorRunUserDataFromLinkDto> {
    return this.http.get<GetSponsorRunUserDataFromLinkDto>(`${this.apiUrl}/api/SponsorRunUser/GetEventFromInviteLink?link=` + link);
  }

  public getSponsorSummery(): Observable<GetSponsorSummeryResponse[]> {
    return this.http.get<GetSponsorSummeryResponse[]>(`${this.apiUrl}/api/SponsorRunSponsor/GetSponsorSummery`);
  }

  public checkPayment(checkPaymentRequest: CheckPaymentRequest): Observable<CheckPaymentResponse> {
    return this.http.post<CheckPaymentResponse>(`${this.apiUrl}/api/SponsorRunSponsor/CheckPayment`, checkPaymentRequest);
  }

  public createPayment(getPaymentLinkRequest: GetPaymentLinkRequest): Observable<GetPaymentLinkResponse> {
    return this.http.post<GetPaymentLinkResponse>(`${this.apiUrl}/api/SponsorRunSponsor/CreatePayment`, getPaymentLinkRequest);
  }

  public getPaymentSummery(getPaymentSummery: GetPaymentSummery): Observable<PaymentSummeryResponse> {
    return this.http.post<PaymentSummeryResponse>(`${this.apiUrl}/api/SponsorRunSponsor/GetPaymentSummery`, getPaymentSummery);
  }
}
