import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { SponsorRunSponsorService } from '../../services/sponsor-run-sponsor.service';
import { interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { PaymentCompleteComponent } from '../payment-complete/payment-complete.component';
import { Consts } from '../../consts';

@Component({
  selector: 'app-waiting-for-payment',
  templateUrl: './waiting-for-payment.component.html',
  styleUrls: ['./waiting-for-payment.component.scss']
})
export class WaitingForPaymentComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private modalService: ModalService, private sponsorService: SponsorRunSponsorService, private router: Router) {
    this.subscription.add(this.activatedRoute.queryParams.subscribe(queryParam => {
      if (queryParam.transactionId) {
        const source = interval(5000);
        this.subscription = source.subscribe(val =>
          this.sponsorService.checkPayment({transactionId: queryParam.transactionId}).pipe(first())
            .subscribe(r => {
              if (r.isPayed) {
                this.router.navigate([Consts.paymentComplete]);
              }
            })
        );
      } else {
        this.modalService.showModal('modal.error', 'modal.description');
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
