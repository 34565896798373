import { Component } from '@angular/core';
import { CustomTranslationService } from 'shared';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private customTranslationService: CustomTranslationService, private titleService: Title) {
    customTranslationService.setLang('da-dk');
    titleService.setTitle('joinandmove.dk');
  }
}
