<div class="container-fluid bigger-body-font-size">
  <div class="row">
    <div class="col-md-12 ps-0 pe-0">
      <div class="tabs-header-container">
        <div class="tabs-header align-items-center">{{'gdpr.title' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <div>
          <div>
            <strong class="underline">Personoplysninger og formål:</strong>
          </div>
          <div>
            <div>
              Når du benytter vores website indsamler og behandler vi en række oplysninger, som kan henføres til dig. Det sker f.eks. ved at du opretter dig som bruger på vores hjemmeside, tilmelder dig vores nyhedsbrev, deltager i konkurrencer eller undersøgelser, registrerer dig som bruger, øvrig brug af services eller foretager køb via websitet.
            </div>
            <div class="mt-2">
              I det omfang du selv giver eksplicit samtykke hertil og selv indtaster informationerne, indsamler og behandler vi typisk følgende typer af oplysninger: Navn, brugernavn, telefonnummer, e-mail, adresse og betalingsoplysninger (via ekstern betalingsløsning). Det vil typisk være i forbindelse med oprettelse af login eller ved køb.
            </div>
            <div class="mt-2">
              Oplysningerne bruges til at identificere dig som bruger og for at kontakte dig i relation til de relevante arrangementer, som vi løbende afholder, eller i forbindelse med tilbud fra samarbejdspartnere.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong class="underline">Periode for opbevaring:</strong>
            </div>
            <div>
              Oplysningerne opbevares så længe din bruger er aktiv ved os. Det er derfor ikke muligt at angive en generel tidsramme for, hvornår informationer slettes.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong class="underline">Videregivelse af oplysninger:</strong>
            </div>
            <div>
              Når du opretter dig som bruger på vores hjemmeside, forbeholder vi os ret til, også at dele dine kontaktoplysninger med relevante samarbejdspartnere som har relation til Eventshop ApS.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong class="underline">Indsigt og tilbagekaldelse:</strong>
            </div>
            <div>
              Du kan til enhver tid få yderligere indsigt i, hvilke personoplysninger vi behandler om dig og hvad dine personoplysninger bruges til ved henvendelse til:
              <a href="mailto:info@eventshop.dk">info@eventshop.dk</a>.
            </div>
            <div>
              Du kan slette din bruger ved at logge ind på din bruger og slette den. Når du sletter din bruger, vil vi slette alle oplysninger om dig, og som kan henføres til dig og den bruger du har slettet.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong class="underline">Klage:</strong>
            </div>
            <div>
              Hvis du vil klage over vores behandling af dine personoplysninger, har du også mulighed for at tage kontakt til Datatilsynet.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong class="underline">Om OS:</strong>
            </div>
            <div>
              Join & Move er en del af Eventshop.dk ApS, og du kan kontakte os via
              <a href="mailto:info@joinandmove.dk">info@joinandmove.dk</a> eller
              <a href="mailto:info@eventshop.dk">info@eventshop.dk</a>
            </div>
            <div>
              <div>
                Eventshop.dk ApS
              </div>
              <div>
                Paradiset 10
              </div>
              <div>
                8585 Glesborg
              </div>
              <div>
                <a href="mailto:info@eventshop.dk">info@eventshop.dk</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
