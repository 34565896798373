<app-stepper linear (selectionChange)="selectionChanged($event)">
  <cdk-step [stepControl]="sponsorUserForm">
    <ng-template cdkStepLabel>
      <span>{{!user && !company ? ('signUp.sponsor.sponsorChoice' | translate) : ''}}{{user && !company ? user.firstName + ' ' + user.lastName : ''}}{{company ? company.name : ''}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.sponsor.step1Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <div [ngClass]="(breakpointObserverService.ipad$ | async).matches ? 'w-100' : ''">
          <div>
            <form [formGroup]="sponsorUserForm" class="sign-up-form">
              <div class="form-group">
                <label class="form-label" for="sponsorType" class="form-label">
                  {{'signUp.sponsor.sponsorChoice' | translate}}
                </label>
                <select id="sponsorType" class="form-select content-input" formControlName="sponsorType" required (change)="sponsorTypeChanged()">
                  <option *ngFor="let sponsorType of getSponsorTypes()" value="{{sponsorType}}">{{'global.sponsorTypeItem.' + sponsorType | translate}}</option>
                </select>
              </div>
            </form>
          </div>
          <div>
            <form *ngIf="sponsorUserForm.controls.sponsorType.value == 'Private' && user" [formGroup]="sponsorUserForm" class="sign-up-form">
              <div class="form-group">
                <label class="form-label" for="name">{{'signUp.participate.name' | translate}}*</label>
                <input id="name" class="form-control content-input" value="{{user.firstName}} {{user.lastName}}" type="text" readonly/>
              </div>
              <div class="form-group">
                <label class="form-label" for="phoneNumber">{{'signUp.phone' | translate}}</label>
                <input id="phoneNumber" class="form-control content-input" value="{{user.phoneNumber}}" type="text" readonly/>
              </div>
              <div class="form-group">
                <label class="form-label" for="email">{{'signUp.email' | translate}}</label>
                <input id="email" class="form-control content-input" value="{{user.email}}" type="text" readonly/>
              </div>
              <div>
                <div class="form-group">
                  <label class="form-label" for="streetOne">{{'signUp.address1' | translate}}</label>
                  <input id="streetOne" class="form-control content-input" value="{{user.address?.streetOne}}" type="text" readonly/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="streetTwo">{{'signUp.address2' | translate}}</label>
                  <input id="streetTwo" class="form-control content-input" value="{{user.address?.streetTwo}}" type="text" readonly/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="postalCode">{{'signUp.postalCodeAndCity' | translate}}</label>
                  <div class="d-flex content-input doubleContainer">
                    <input id="postalCode" class="form-control postalCode" value="{{user.address?.postalCode}}" type="number" min="0" readonly/>
                    <input id="city" class="form-control city" value="{{user.address?.city}}" type="text" readonly/>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="pt-1 pb-2">
                  <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [routerLink]="editUserInfo()">
                    {{'user.editUserInfo' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div>
            <form *ngIf="sponsorUserForm.controls.sponsorType.value == 'Cvr'" [formGroup]="sponsorUserForm" class="sign-up-form">
              <div class="form-group">
                <label class="form-label" for="companyId">
                  {{'signUp.sponsor.companyChoice' | translate}}*
                </label>
                <select id="companyId" class="form-select content-input" formControlName="companyId" required (change)="companySelected()">
                  <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                  <option *ngFor="let company of companies" value="{{company.id}}">{{company.name}}</option>
                </select>
              </div>
              <div>
                {{'signUp.companyDoesNotExist' | translate}}
                <a [routerLink]="createCompany()">{{'signUp.clickHere' | translate}}</a>
              </div>
              <div *ngIf="company">
                <div class="form-group">
                  <label class="form-label" for="cvr">{{'global.cvr' | translate}}</label>
                  <input id="cvr" class="form-control content-input" type="text" readonly value="{{company?.cvr}}"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="contactPerson">{{'signUp.sponsor.company.contactPerson' | translate}}</label>
                  <input id="contactPerson" class="form-control content-input" type="text" readonly value="{{company?.primaryContactDisplayName}}"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="contactPhone">{{'signUp.phone' | translate}}</label>
                  <input id="contactPhone" class="form-control content-input" type="text" readonly value="{{company?.phoneNumber}}"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="contactEmail">{{'signUp.email' | translate}}</label>
                  <input id="contactEmail" class="form-control content-input" type="text" readonly value="{{company?.email}}"/>
                </div>
                <div>
                  <div class="form-group">
                    <label class="form-label" for="streetOne">{{'signUp.address1' | translate}}</label>
                    <input id="streetOne" class="form-control content-input" type="text" readonly value="{{company?.address?.streetOne}}"/>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="streetTwo">{{'signUp.address2' | translate}}</label>
                    <input id="streetTwo" class="form-control content-input" type="text" readonly value="{{company?.address?.streetTwo}}"/>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="postalCode">{{'signUp.postalCodeAndCity' | translate}}</label>
                    <div class="d-flex content-input doubleContainer">
                      <input id="postalCode" class="form-control postalCode" type="text" min="0" maxlength="4" readonly value="{{company?.address?.postalCode}}"/>
                      <input id="city" class="form-control city" type="text" readonly value="{{company?.address?.city}}"/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer class="mb-5">
      <div class="d-flex justify-content-center">
        <button class="content-button button-text text-uppercase font-italic mt-5" type="submit" name="register" [disabled]="!sponsorUserFormValid()" cdkStepperNext>
          {{'global.continue' | translate}}
        </button>
      </div>
    </footer>
  </cdk-step>
  <cdk-step [stepControl]="sponsorForm">
    <ng-template cdkStepLabel>
      <span>{{selectedEvent ? selectedEvent.name : ('signUp.sponsor.eventName' | translate)}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.sponsor.step2Title' | translate}}</h1>
      </div>
      <div class="d-flex justify-content-center">
        <div [ngClass]="(breakpointObserverService.ipad$ | async).matches ? 'w-100' : ''">
          <form [formGroup]="sponsorForm" class="sign-up-form">
            <div class="form-group">
              <label class="form-label" for="eventId">{{'signUp.chooseEvent' | translate}}*</label>
              <select id="eventId" class="form-select content-input" formControlName="eventId" required (change)="eventSelected()">
                <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                <option *ngFor="let event of events" value="{{event.id}}">{{event.name}} - {{event.location.postalCode}} {{event.location.city}}</option>
              </select>
            </div>
            <div *ngIf="selectedEvent">
              <div class="form-group">
                <label class="form-label" for="supportUserOrCommunity">{{'signUp.sponsor.support' | translate}}*</label>
                <select id="supportUserOrCommunity" class="form-select content-input" formControlName="support" required (change)="supprtUserOrCommunityChanged($event)">
                  <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                  <option value="0">{{'signUp.sponsor.supportUserOrTeam' | translate}}</option>
                  <option value="1">{{'signUp.sponsor.supportCommunity' | translate}}</option>
                </select>
              </div>
              <div *ngIf="sponsorForm.controls.supportUserOrTeam.value">
                <div class="form-group">
                  <label class="form-label" for="typeahead-focus">{{'signUp.sponsor.searchForUser' | translate}}</label>
                  <input #userSearchInput="ngbTypeahead" id="typeahead-focus" type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="userSearch" [ngbTypeahead]="search$" (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)" [inputFormatter]="searchFormatter" [resultFormatter]="searchFormatter" (selectItem)="selectUser($event)" [editable]='false' placeholder="{{'global.search' | translate}}" autocomplete="user-search"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="teamId">{{'signUp.chosenUser' | translate}}*</label>
                  <input id="teamId" class="form-control content-input" type="text" value="{{selectedUser?.name}}" readonly required [ngClass]="showUserNotSelectedError ? 'ng-touched ng-invalid' : ''"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="combination">{{'signUp.sponsor.chooseSponsorParticipateType' | translate}}:*</label>
                  <select id="combination" class="form-select content-input" formControlName="combination" required (change)="combinationSelected()">
                    <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                    <option *ngFor="let combination of selectedUser?.sponsorRunUserSignUpCombinations, let i = index" value="{{i}}">{{'global.sponsorParticipateTypeItem.' + combination.sponsorParticipationType | translate}} - {{'global.unitTypeItem.' + combination.unitType | translate}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="paymentPerUnit">{{'signUp.sponsor.paymentPerUnit' | translate}} {{sponsorForm.controls.unitType.value !== -1 ? ('global.unitTypeItem.' + sponsorForm.controls.unitType.value | translate) : ''}}:*</label>
                  <input id="paymentPerUnit" formControlName="paymentPerUnit" class="form-control content-input" type="number" placeholder="{{'global.paymentPer' | translate}}" required max="100000"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="singlePayment">{{'signUp.sponsor.singlePayment' | translate}}</label>
                  <input id="singlePayment" formControlName="singlePayment" class="form-control content-input" type="number" required placeholder="{{'global.currency' | translate}}" max="1000000"/>
                  <div class="invalid" *ngIf="sponsorForm.controls.singlePayment.invalid && (sponsorForm.controls.singlePayment.dirty || sponsorForm.controls.singlePayment.touched)">
                    {{'signUp.sponsor.singlePaymentError' | translate}}
                  </div>
                </div>
                <div>
                  <div class="form-check mt-3">
                    <input class="form-check-input" type="checkbox" value="" id="showMaxPayment" formControlName="showMaxPayment" (change)="showMaxPaymentChanged($event)">
                    <label class="form-check-label" for="showMaxPayment">
                      {{'signUp.participate.maxPayment' | translate}}
                    </label>
                  </div>
                  <div class="form-group" *ngIf="sponsorForm.controls.showMaxPayment.value">
                    <input id="maxPayment" formControlName="maxPayment" class="form-control content-input" type="number" placeholder="{{'global.currency' | translate}}" max="1000000"/>
                    <div class="invalid" *ngIf="sponsorForm.controls.maxPayment.invalid && (sponsorForm.controls.maxPayment.dirty || sponsorForm.controls.maxPayment.touched)">
                      {{'signUp.sponsor.maxPaymentError' | translate}}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="sponsorForm.controls.supportCommunity.value">
                <div class="form-group">
                  <label class="form-label" for="sponsorParticipationType">{{'signUp.sponsor.chooseCommunitySponsorParticipateType' | translate}}:*</label>
                  <select id="sponsorParticipationType" class="form-select content-input" formControlName="sponsorParticipationType" required (change)="sponsorParticipateTypeSelected($event)">
                    <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                    <option *ngFor="let sponsorParticipateType of selectedEvent?.sponsorParticipateTypes, let i = index" value="{{sponsorParticipateType}}">{{'global.sponsorParticipateTypeItem.' + sponsorParticipateType | translate}}</option>
                    <!--                  - {{'global.unitTypeItem.' + combination.unitType | translate}}</option>-->
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="unitType">{{'signUp.sponsor.chooseCommunityUnitType' | translate}}:*</label>
                  <select id="unitType" class="form-select content-input" formControlName="unitType" required (change)="unitTypeSelected($event)">
                    <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                    <
                    <option *ngFor="let unitType of getUnitTypes()" value="{{unitType}}">{{'global.unitTypeItem.' + unitType | translate}}</option>
                    <!--                  - {{'global.unitTypeItem.' + combination.unitType | translate}}</option>-->
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="paymentPerUnit">{{'signUp.sponsor.paymentPerUnit' | translate}} {{sponsorForm.controls.unitType.value !== -1 ? ('global.unitTypeItem.' + sponsorForm.controls.unitType.value | translate) : ''}}:*</label>
                  <input id="paymentPerUnit" formControlName="paymentPerUnit" class="form-control content-input" type="number" placeholder="{{'global.paymentPer' | translate}}" required max="100000"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="singlePayment">{{'signUp.sponsor.singlePayment' | translate}}</label>
                  <input id="singlePayment" formControlName="singlePayment" class="form-control content-input" type="number" required placeholder="{{'global.currency' | translate}}" max="1000000"/>
                  <div class="invalid" *ngIf="sponsorForm.controls.singlePayment.invalid && (sponsorForm.controls.singlePayment.dirty || sponsorForm.controls.singlePayment.touched)">
                    {{'signUp.sponsor.singlePaymentError' | translate}}
                  </div>
                </div>
                <div>
                  <div class="form-check mt-3">
                    <input class="form-check-input" type="checkbox" value="" id="showMaxPayment" formControlName="showMaxPayment" (change)="showMaxPaymentChanged($event)">
                    <label class="form-check-label" for="showMaxPayment">
                      {{'signUp.participate.maxPayment' | translate}}
                    </label>
                  </div>
                  <div class="form-group mt-2" *ngIf="sponsorForm.controls.showMaxPayment.value">
                    <input id="maxPayment" formControlName="maxPayment" class="form-control content-input" type="number" placeholder="{{'global.currency' | translate}}" max="1000000"/>
                    <div class="invalid" *ngIf="sponsorForm.controls.maxPayment.invalid && (sponsorForm.controls.maxPayment.dirty || sponsorForm.controls.maxPayment.touched)">
                      {{'signUp.sponsor.maxPaymentError' | translate}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" value="" id="visible" formControlName="visible">
                <label class="form-check-label" for="visible">
                  {{'signUp.sponsor.visible' | translate}}
                </label>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" value="" id="rankings" formControlName="rankings">
                <label class="form-check-label" for="rankings">
                  {{'signUp.sponsor.rank' | translate}}
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer class="mb-5">
      <div class="d-flex justify-content-center flex-wrap mt-5">
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" cdkStepperPrevious>
          {{'global.back' | translate}}
        </button>
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" [disabled]="sponsorForm.invalid || !customValidation()" cdkStepperNext>
          {{'global.continue' | translate}}
        </button>
      </div>
    </footer>
  </cdk-step>
  <cdk-step [stepControl]="gdprForm">
    <ng-template cdkStepLabel>
      <span>{{'global.summary' | translate}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.sponsor.step3Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <div class="sign-up-summary">
          <div>
            <div class="text-uppercase fw-bold">
              {{'global.summary' | translate}}
            </div>
          </div>
          <table class="summary-table">
            <div *ngIf="sponsorUserForm.controls.sponsorType.value == 'Private'">
              <tr>
                <td><span class="text-uppercase">{{'signUp.participate.name' | translate}}</span></td>
                <td>{{user?.firstName}} {{user?.lastName}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.phone' | translate}}</span></td>
                <td>{{user?.phoneNumber}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.email' | translate}}</span></td>
                <td>{{user?.email}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.address' | translate}}</span></td>
                <td>{{user?.address?.streetOne}} {{user?.address?.streetTwo}}, {{user?.address?.postalCode}} {{user?.address?.city}}</td>
              </tr>
            </div>
            <div *ngIf="sponsorUserForm.controls.sponsorType.value == 'Cvr'">
              <tr>
                <td><span class="text-uppercase">{{'signUp.sponsor.company.name' | translate}}:</span></td>
                <td>
                  {{company?.name}}
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-uppercase">{{'global.cvr' | translate}}:</span>
                </td>
                <td>
                  {{company?.cvr}}
                </td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.phone' | translate}}</span></td>
                <td>{{company?.phoneNumber}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.email' | translate}}</span></td>
                <td>{{company?.email}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.address' | translate}}</span></td>
                <td>{{company?.address?.streetOne}} {{company?.address?.streetTwo}}, {{company?.address?.postalCode}} {{company?.address?.city}}</td>
              </tr>
            </div>
            <div class="mt-3">
              <tr>
                <td><span class="text-uppercase">{{'signUp.sponsor.chosenEvent' | translate}}:</span></td>
                <td>{{selectedEvent?.name}}</td>
              </tr>
            </div>
            <div class="mt-3">
              <tr>
                <td><span class="text-uppercase">{{'signUp.sponsor.supportUserOrTeam' | translate}}:</span></td>
                <td>{{(sponsorForm.controls.supportUserOrTeam.value == true ? 'global.yes' : 'global.no') | translate}}</td>
              </tr>
              <div *ngIf="sponsorForm.controls.supportUserOrTeam.value">
                <tr *ngIf="selectedUser">
                  <td><span class="text-uppercase">{{'signUp.chosenUser' | translate}}:</span></td>
                  <td>{{selectedUser.name}}
                </tr>
                <!--                <tr *ngIf="selectedTeam">-->
                <!--                  <td>-->
                <!--                    <span class="text-uppercase">{{'signUp.chosenTeam' | translate}}:</span>-->
                <!--                  </td>-->
                <!--                  <td>-->
                <!--                    {{selectedTeam.name}}-->
                <!--                  </td>-->
                <!--                </tr>-->
                <tr>
                  <td>
                    <span class="text-uppercase">{{'signUp.sponsor.paymentPerUnit' | translate}}:</span>
                  </td>
                  <td>
                    {{sponsorForm.controls.paymentPerUnit.value}} {{'global.currency' | translate}}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-uppercase">{{'signUp.sponsor.maxPayment' | translate}}:</span>
                  </td>
                  <td>
                    {{sponsorForm.controls.maxPayment.value}} {{'global.currency' | translate}}
                  </td>
                </tr>
              </div>
            </div>
            <div class="mt-3">
              <tr>
                <td>
                  <span class="text-uppercase">{{'signUp.sponsor.supportCommunity' | translate}}:</span>
                </td>
                <td>{{(sponsorForm.controls.supportCommunity.value == true ? 'global.yes' : 'global.no') | translate}}</td>
              </tr>
              <tr *ngIf="sponsorForm.controls.supportCommunity.value">
                <td>
                  <span class="text-uppercase">{{'signUp.sponsor.singlePayment' | translate}}:</span>
                </td>
                <td>{{sponsorForm.controls.singlePayment.value}} {{'global.currency' | translate}}</td>
              </tr>
            </div>
            <div class="mt-3">
              <tr>
                <td>
                  <span class="text-uppercase">{{'signUp.sponsor.visible' | translate}}:</span></td>
                <td>{{(sponsorForm.controls.visible.value == true ? 'global.yes' : 'global.no') | translate}}</td>
              </tr>
              <tr>
                <td>
                  <span class="text-uppercase">{{'signUp.sponsor.rank' | translate}}:</span>
                </td>
                <td>{{(sponsorForm.controls.rankings.value == true ? 'global.yes' : 'global.no') | translate}}</td>
              </tr>
            </div>
          </table>
          <form class="mt-3" [formGroup]="gdprForm">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="terms" formControlName="terms">
              <label class="form-check-label" for="terms">
                {{'signUp.sponsor.terms' | translate}}
                <a [routerLink]="getTermsRoute()" target="_blank">{{'signUp.sponsor.termsUrl' | translate}}</a>
              </label>
            </div>
            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" value="" id="gdpr" formControlName="gdpr">
              <label class="form-check-label" for="gdpr">
                {{'signUp.sponsor.gdpr' | translate}}
                <a [routerLink]="getGdprRoute()" target="_blank">{{'signUp.sponsor.gdprUrl' | translate}}</a>
              </label>
            </div>
          </form>
          <div class="mt-5 mb-5 d-flex justify-content-center flex-wrap">
            <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" cdkStepperPrevious>
              {{'global.back' | translate}}
            </button>
            <button class="content-button button-text text-uppercase font-italic" type="button" name="register" [disabled]="gdprForm.invalid" (click)="sponsor()">
              {{'signUp.sponsor.sponsor' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </cdk-step>
</app-stepper>
