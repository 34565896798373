<div class="container-fluid">
  <div class="row text-center align-items-center subsection-header mb-4">
    <span>{{'admin.title' | translate}}</span>
  </div>
  <div>
    <div class="row mt-5">
      <div class="col-md-4 ps-0 pe-0 sidebar">
        <div class="ms-auto ms-4 pt-3 sidebar-group">
          <div class="sidebar-header text-uppercase">{{'admin.title' | translate}}</div>
          <div>
            <a class="sidebar-item" [ngClass]="isTabActive('tab-admin-invalid-clubs') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-admin-invalid-clubs')">{{'admin.companies' | translate}}</a>
          </div>
          <div>
            <a class="sidebar-item" [ngClass]="isTabActive('tab-admin-users') ? 'sidebar-item-active' : ''" (click)="selectTab('tab-admin-users')">{{'admin.users' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-md-8 pe-0">
        <eventShop-tabs>
          <eventShop-tab [name]="'tab-admin-invalid-clubs'">
            <app-tab-admin-invalid-clubs (clubIdEvent)="invalidClubSelected($event)"></app-tab-admin-invalid-clubs>
          </eventShop-tab>
          <eventShop-tab [name]="'tab-admin-company-validate'">
            <app-tab-admin-company-validate></app-tab-admin-company-validate>
          </eventShop-tab>
          <eventShop-tab [name]="'tab-admin-users'">
            <app-tab-admin-users (userIdEvent)="userSelected($event)"></app-tab-admin-users>
          </eventShop-tab>
          <eventShop-tab [name]="'tab-admin-user'">
            <app-tab-admin-user></app-tab-admin-user>
          </eventShop-tab>
        </eventShop-tabs>
      </div>
    </div>
  </div>
</div>
