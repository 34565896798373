<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'signUpUser.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="userForm" (ngSubmit)="signUp()">
        <div class="form-group">
          <label class="form-label" for="username">{{'signUpUser.username' | translate}}*</label>
          <input id="username" class="form-control content-input" formControlName="username" required (change)="haveToCheckUsername()" (ngModelChange)="validateUsername()"/>
          <div class="password-invalid" *ngIf="usernameValidation && !usernameValidation.special">
            {{'signUpUser.usernameSpecial' | translate}}
          </div>
          <div class="password-invalid" *ngIf="usernameValidation && !usernameValidation.spacing">
            {{'signUpUser.spacing' | translate}}
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="content-button button-text text-uppercase font-italic" style="width: 400px" type="button" name="check" (click)="checkUsername()" [disabled]="!this.utilService.usernameValid(usernameValidation)">
            {{'signUpUser.checkUsername' | translate}}
          </button>
        </div>
        <div class="form-group">
          <label class="form-label" for="password">{{'signUpUser.password' | translate}}*</label>
          <input id="password" class="form-control content-input" formControlName="password" required type="password" (ngModelChange)="validatePassword()"/>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.length">
            {{'signUpUser.length' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.capitalLetter">
            {{'signUpUser.capitalLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.smallLetter">
            {{'signUpUser.smallLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.digit">
            {{'signUpUser.digit' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.special">
            {{'signUpUser.special' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.spacing">
            {{'signUpUser.spacing' | translate}}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="repeatPassword">{{'signUpUser.repeatPassword' | translate}}*</label>
          <input id="repeatPassword" class="form-control content-input" formControlName="repeatPassword" required type="password" (ngModelChange)="validateRepeatedPassword()"/>
          <div class="password-invalid" *ngIf="userForm.controls.repeatPassword.invalid && (userForm.controls.repeatPassword.dirty || userForm.controls.repeatPassword.touched)">
            {{'signUpUser.notMatching' | translate}}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="firstName">{{'editUser.firstName' | translate}}*</label>
          <input id="firstName" class="form-control content-input" formControlName="firstName" required/>
        </div>
        <div class="form-group">
          <label class="form-label" for="lastName">{{'editUser.lastName' | translate}}*</label>
          <input id="lastName" class="form-control content-input" formControlName="lastName" required/>
        </div>
        <div class="form-group">
          <label class="form-label" for="email">{{'editUser.email' | translate}}*</label>
          <input id="email" class="form-control content-input" formControlName="email" required type="email"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="phoneNumber">{{'editUser.phoneNumber' | translate}}*</label>
          <input id="phoneNumber" class="form-control content-input" formControlName="phoneNumber" type="text" maxlength="8" required/>
        </div>
        <div formGroupName="address">
          <div class="form-group">
            <label class="form-label" for="streetOne">{{'editUser.address1' | translate}}*</label>
            <input id="streetOne" class="form-control content-input" type="text" formControlName="streetOne" required/>
          </div>
          <div class="form-group">
            <label class="form-label" for="streetTwo">{{'editUser.address2' | translate}}</label>
            <input id="streetTwo" class="form-control content-input" type="text" formControlName="streetTwo"/>
          </div>
          <div class="form-group">
            <label class="form-label" for="postalCode">{{'editUser.postalCodeAndCity' | translate}}*</label>
            <div class="d-flex content-input doubleContainer">
              <input id="postalCode" class="form-control postalCode" type="text" min="0" maxlength="4" required formControlName="postalCode"/>
              <input id="city" class="form-control city" type="text" required formControlName="city"/>
            </div>
          </div>
        </div>
        <div class="form-check mt-4">
          <input class="form-check-input" type="checkbox" value="" id="gdpr" formControlName="gdpr">
          <label class="form-check-label" for="gdpr">
            {{'signUp.sponsor.gdpr' | translate}}
            <a [routerLink]="getGdprRoute()" target="_blank">{{'signUp.sponsor.gdprUrl' | translate}}</a>
          </label>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update">
            {{'signUpUser.create' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
