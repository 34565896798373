import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CompanyEvent } from '../../models/company-event';
import { EventsService } from '../../services/events.service';
import { Consts } from '../../consts';
import { AuthService, BreakpointObserverService } from 'shared';
import { UtilService } from '../../services/util.service';
import { PastOrFuture } from '../../enums/past-or-future.enum';

@Component({
  selector: 'app-user-events',
  templateUrl: './user-events.component.html',
  styleUrls: ['./user-events.component.scss']
})
export class UserEventsComponent implements OnInit, OnDestroy {
  public events$: Observable<CompanyEvent[]>;
  private subscription = new Subscription();
  public selectedPastOrFuture = PastOrFuture.Future;
  pastOrFuture: typeof PastOrFuture = PastOrFuture;


  constructor(private eventsService: EventsService, private authService: AuthService, public utilService: UtilService, public breakObserverService: BreakpointObserverService) {
    this.subscription.add(this.authService.userChanged$
      .subscribe(u => {
        if (u) {
          this.events$ = this.eventsService.getUsersEvents(this.selectedPastOrFuture)
        }
      }));
  }

  ngOnInit() {
  }

  togglePastOrFutureEvents(pastOrFuture: PastOrFuture) {
    this.selectedPastOrFuture = pastOrFuture;
    this.events$ = this.eventsService.getUsersEvents(this.selectedPastOrFuture);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public goToEvent(eventId: number): string {
    return `/${Consts.eventRoute}/${eventId}`;
  }

  public registerActivity(eventId: number): string {
    return `/${Consts.registerActivityRoute}/${eventId}`;
  }

  public editSignUp(eventId: number): string {
    return `/${Consts.userEditSignUp}/${eventId}`;
  }
}
