<!--<div class="container-fluid container-full-width">-->
<!--  <div class="row">-->
<!--&lt;!&ndash;    <div class="video-container">&ndash;&gt;-->
<!--&lt;!&ndash;      <iframe class="video" src="https://www.youtube.com/embed/lxIDFhjYxgY?controls=0&autoplay=1&modestbranding=1&loop=1&playlist=lxIDFhjYxgY&playsinline=1&re=0&showinfo=0&mute=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!--</div>-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 support-bar d-flex justify-content-center">
      <div class="align-self-center">
        {{'home.support' | translate}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 ps-0 pe-0">
      <video autoplay muted loop id="background-video" onloadedmetadata="this.muted = true" oncanplay="this.play()">
        <source src="/assets/home/background_video.mp4" type="video/mp4">
      </video>
      <div class="banner-container">
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-3 button-margin">
      <button class="action-button text-uppercase" (click)="goToCreateEventRouteRoute()">
        {{'global.arrange' | translate}}
      </button>
    </div>
    <div class="col-md-3 button-margin">
      <button class="action-button text-uppercase" (click)="goToParticipateRoute()">
        {{'global.participate' | translate}}
      </button>
    </div>
    <div class="col-md-3 button-margin">
      <button class="action-button text-uppercase" (click)="goToSponsorRoute()">
        {{'global.sponsor' | translate}}
      </button>
    </div>
  </div>
  <div class="row justify-content-center model pt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-white font-italic"> {{'home.model.title' | translate}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="d-flex justify-content-center model pb-5" *ngIf="!(breakpointObserverService.max992$ | async)?.matches">
      <div class="model-part mt-5">
        <img src="assets/home/Modellen_01.png" class="model-image">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model1Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model1Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container">
        <img src="assets/home/Pil.png" class="model-arrow-image">
      </div>
      <div class="model-part mt-5">
        <img src="assets/home/Modellen_04.png" class="model-image">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model2Title' | translate}}
        </div>
        <div class="light-text text-center ">
          {{'home.model.model2Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container">
        <img src="assets/home/Pil.png" class="model-arrow-image">
      </div>
      <div class="model-part mt-5">
        <img src="assets/home/Modellen_03.png" class="model-image">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model3Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model3Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container">
        <img src="assets/home/Pil.png" class="model-arrow-image">
      </div>
      <div class="model-part mt-5">
        <img src="assets/home/Modellen_02.png" class="model-image">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model4Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model4Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container">
        <img src="assets/home/Pil.png" class="model-arrow-image">
      </div>
      <div class="model-part mt-5">
        <img src="assets/home/Modellen_05.png" class="model-image">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model5Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model5Text' | translate}}
        </div>
      </div>
    </div>
    <div class="model pb-5" *ngIf="(breakpointObserverService.max992$ | async)?.matches">
      <div class="mt-5">
        <img src="assets/home/Modellen_01.png" class="model-image mx-auto d-block">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model1Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model1Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container mt-5">
        <img src="assets/home/arrow-down.png" class="model-arrow-image">
      </div>
      <div>
        <img src="assets/home/Modellen_04.png" class="model-image mx-auto d-block">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model2Title' | translate}}
        </div>
        <div class="light-text text-center ">
          {{'home.model.model2Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container mt-5">
        <img src="assets/home/arrow-down.png" class="model-arrow-image">
      </div>
      <div>
        <img src="assets/home/Modellen_03.png" class="model-image mx-auto d-block">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model3Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model3Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container mt-5">
        <img src="assets/home/arrow-down.png" class="model-arrow-image">
      </div>
      <div>
        <img src="assets/home/Modellen_02.png" class="model-image mx-auto d-block">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model4Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model4Text' | translate}}
        </div>
      </div>
      <div class="model-arrow-image-container mt-5">
        <img src="assets/home/arrow-down.png" class="model-arrow-image">
      </div>
      <div>
        <img src="assets/home/Modellen_05.png" class="model-image mx-auto d-block">
        <div class="section-header-bolder light-text text-center">
          {{'home.model.model5Title' | translate}}
        </div>
        <div class="light-text text-center">
          {{'home.model.model5Text' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="row mt-5 mb-5 justify-content-center stats-container">-->
  <!--    <div class="col-md-3 text-center stat-container">-->
  <!--      <div>-->
  <!--        <img class="stats-image" src="assets/home/Ikon_01.png"/>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <div>-->
  <!--          <div class="stats-header" *ngIf="eventsCount$ | async as eventsCount">{{eventsCount | number :'':customTranslationService.getLang()}}</div>-->
  <!--          <div class="stats-text">{{'home.events' | translate}}</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-md-3 text-center stat-container">-->
  <!--      <div>-->
  <!--        <img class="stats-image" src="assets/home/Ikon_02.png"/>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <div>-->
  <!--          <div class="stats-header" *ngIf="total$ | async as total">{{total | number :'':customTranslationService.getLang()}}</div>-->
  <!--          <div class="stats-text">{{'home.total' | translate}}</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-md-3 text-center stat-container mb-0">-->
  <!--      <div>-->
  <!--        <img class="stats-image" src="assets/home/Ikon_03.png"/>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <div>-->
  <!--          <div class="stats-header" *ngIf="sponsorRunners$ | async as sponsorRunners">{{sponsorRunners | number :'':customTranslationService.getLang()}}</div>-->
  <!--          <div class="stats-text">{{'home.participants' | translate}}</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div class="row justify-content-center testimonials-container pt-5 pb-5">-->
  <!--    <div class="col-md-3 text-center testimonial-container">-->
  <!--      <div>-->
  <!--        <div class="testimonial-image-container">-->
  <!--          <img class="testimonial-image" src="assets/home/testimonial1.png"/>-->
  <!--        </div>-->
  <!--        <div class="">-->
  <!--          <h3 class="testimonial-header mt-4">-->
  <!--            {{'home.testimonial1.header' | translate}}-->
  <!--          </h3>-->
  <!--          <div class="testimonial-text mt-2">-->
  <!--            {{'home.testimonial1.text' | translate}}-->
  <!--          </div>-->
  <!--          <div class="testimonial-from mt-2">-->
  <!--            {{'home.testimonial1.from' | translate}}-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-md-3 text-center testimonial-container">-->
  <!--      <div>-->
  <!--        <div class="testimonial-image-container">-->
  <!--          <img class="testimonial-image" src="assets/home/testimonial2.png"/>-->
  <!--        </div>-->
  <!--        <div class="">-->
  <!--          <h3 class="testimonial-header mt-4">-->
  <!--            {{'home.testimonial2.header' | translate}}-->
  <!--          </h3>-->
  <!--          <div class="testimonial-text mt-2">-->
  <!--            {{'home.testimonial2.text' | translate}}-->
  <!--          </div>-->
  <!--          <div class="testimonial-from mt-2">-->
  <!--            {{'home.testimonial2.from' | translate}}-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-md-3 text-center testimonial-container mb-0">-->
  <!--      <div>-->
  <!--        <div class="testimonial-image-container">-->
  <!--          <img class="testimonial-image" src="assets/home/testimonial3.png"/>-->
  <!--        </div>-->
  <!--        <div class="">-->
  <!--          <h3 class="testimonial-header mt-4">-->
  <!--            {{'home.testimonial3.header' | translate}}-->
  <!--          </h3>-->
  <!--          <div class="testimonial-text mt-2">-->
  <!--            {{'home.testimonial3.text' | translate}}-->
  <!--          </div>-->
  <!--          <div class="testimonial-from mt-2">-->
  <!--            {{'home.testimonial3.from' | translate}}-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
