import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SponsorEvent } from '../models/sponsor-event';
import { SponsorRunUser } from '../models/sponsor-run-user';
import { FormGroup } from '@angular/forms';
import { RegisterActivityRequest } from '../models/RegisterActivityRequest';
import { first } from 'rxjs/operators';
import { Consts } from '../consts';
import { BehaviorSubject } from 'rxjs';
import { SponsorRunService } from '../services/sponsor-run.service';
import { ModalService } from '../services/modal.service';
import { Router } from '@angular/router';
import { FormViewComponent } from '../components/form-view/form-view.component';

@Component({
  selector: 'app-tab-event-register-activity',
  templateUrl: './tab-event-register-activity.component.html',
  styleUrls: ['./tab-event-register-activity.component.scss']
})
export class TabEventRegisterActivityComponent extends FormViewComponent implements OnInit {
  @Input()
  public event: SponsorEvent;
  @Input()
  public sponsorRunUser: SponsorRunUser;

  private formValidValue = false;
  private form: FormGroup;

  constructor(private sponsorService: SponsorRunService, private modalService: ModalService, private router: Router, protected elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit(): void {
  }

  public formValid(valid: boolean) {
    this.formValidValue = valid;
  }

  public setForm(form: FormGroup) {
    this.form = form;
  }

  public registerUserSponsorActivity() {
    if (this.formValidValue) {
      const request = this.form.value as RegisterActivityRequest;
      request.timeStamp = new Date();
      this.sponsorService.registerActivity(request).pipe(first())
        .subscribe(_ => {
          this.router.navigate([`/${Consts.eventRoute}/${this.event.id}`])
            .then(() => {
              window.location.reload();
            });
        })
    } else {
      this.form.markAllAsTouched();
      this.showError();
    }
  }
}
