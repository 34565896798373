import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { EventsDto } from '../models/EventsDto';
import { CompanyEvent } from '../models/company-event';
import { apiUrlToken, EventShopUser} from 'shared';
import { EventBase } from '../../../../shared/src/lib/interfaces/event-base';
import { SearchEvents } from '../models/search-events';
import { PastOrFuture } from '../enums/past-or-future.enum';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getEvents(page: number, count: number): Observable<EventsDto> {
    return this.http.get<EventsDto>(`${this.apiUrl}/api/Events/GetEvents?page=` + page + '&count=' + count);
  }

  public searchEvents(searchString: string){
    return this.http.post<EventBase[]>(`${this.apiUrl}/api/Events/SearchEvents`, {searchText: searchString} as SearchEvents);
  }

  public getUsersEvents(pastOrFuture: PastOrFuture = PastOrFuture.Future): Observable<CompanyEvent[]> {
    return this.http.get<CompanyEvent[]>(`${this.apiUrl}/api/SponsorRun/GetUsersSponsorEvents/${pastOrFuture}`);
  }

  public getAdminEvents(): Observable<CompanyEvent[]> {
    return this.http.get<CompanyEvent[]>(`${this.apiUrl}/api/SponsorRun/GetAdminSponsorRunEvents`);
  }

  public getAdmins(eventId: number): Observable<EventShopUser[]> {
    return this.http.get<EventShopUser[]>(`${this.apiUrl}/api/Events/GetAdmins/${eventId}`);
  }

  public addAdmin(eventId: number, userId: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/Events/AddAdmins/${eventId}`, [userId]);
  }

  public removeAdmin(eventId: number, userId: number): Observable<any>  {
    return this.http.post<any>(`${this.apiUrl}/api/Events/RemoveAdmins/${eventId}`, [userId]);
  }
}
