import { Component, OnInit } from '@angular/core';
import { Consts } from '../../consts';
import { Router, UrlSerializer } from '@angular/router';
import { BreakpointObserverService } from 'shared';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  constructor(private router: Router, private urlSerializer: UrlSerializer, public breakpointObserverService: BreakpointObserverService) {
  }

  ngOnInit(): void {
  }

  public goToParticipateRoute() {
    this.router.navigate([Consts.informationParticipateRoute]);
  }

  public goToCreateEventRouteRoute() {
    this.router.navigate([Consts.informationArrangeRoute]);
  }

  public goToSponsorRoute() {
    this.router.navigate([Consts.informationSponsorsRoute]);
  }

  public getCreatEventUrl(): string {
    const urlTree = this.router.createUrlTree([Consts.signUpRoute], {queryParams: {tab: Consts.arrangeTab}});
    return this.urlSerializer.serialize(urlTree);
  }
}
