<app-stepper linear (selectionChange)="selectionChanged($event)">
  <cdk-step [stepControl]="createEventForm">
    <ng-template cdkStepLabel>
      <span>{{selectedCompany ? selectedCompany.name : ('signUp.arrange.companyName'| translate)}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.arrange.step1Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <div [ngClass]="(breakpointObserverService.ipad$ | async).matches ? 'w-100' : ''">
          <form [formGroup]="createEventForm" class="sign-up-form">
            <div class="form-group">
              <label class="form-label" for="companyId">{{'signUp.arrange.chooseCompany' | translate}}</label>
              <select id="companyId" class="form-select content-input" formControlName="companyId" required (change)="companySelected()">
                <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                <option *ngFor="let company of adminsCompanies" value="{{company.id}}">{{company.name}}</option>
              </select>
            </div>
            <div>
              {{'signUp.doesNotExist' | translate}}
              <a [routerLink]="getCreateEditCompany()">{{'signUp.clickHere' | translate}}</a>
            </div>
            <div *ngIf="selectedCompany">
              <div class="form-group">
                <label class="form-label" for="cvr">{{'global.cvr' | translate}}:</label>
                <input id="cvr" class="form-control content-input" type="text" value="{{selectedCompany?.cvr}}" readonly/>
              </div>
              <div class="form-group">
                <label class="form-label" for="primaryContact">{{'global.contactPerson' | translate}}:</label>
                <input id="primaryContact" class="form-control content-input" type="text" readonly value="{{selectedCompany?.primaryContactDisplayName}}"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="phone">{{'signUp.phone' | translate}}</label>
                <input id="phone" class="form-control content-input" type="text" readonly value="{{selectedCompany?.phoneNumber}}"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="email">{{'signUp.email' | translate}}</label>
                <input id="email" class="form-control content-input" type="text" readonly value="{{selectedCompany?.email}}"/>
              </div>
              <div>
                <div class="form-group">
                  <label class="form-label" for="companyStreetOne">{{'signUp.address1' | translate}}</label>
                  <input id="companyStreetOne" class="form-control content-input" type="text" readonly value="{{selectedCompany?.address?.streetOne}}"/>
                </div>
                <div class="form-group">
                  <label class="col-md-3 col-form-label text-uppercase" for="companyStreetTwo">{{'signUp.address2' | translate}}</label>
                  <input id="companyStreetTwo" class="form-control content-input" type="text" readonly value="{{selectedCompany?.address?.streetTwo}}"/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="companyPostalCode">{{'signUp.postalCodeAndCity' | translate}}</label>
                  <div class="d-flex content-input doubleContainer">
                    <input id="companyPostalCode" class="form-control postalCode" type="number" min="0" readonly value="{{selectedCompany?.address?.postalCode}}"/>
                    <input id="companyCity" class="form-control city" type="text" required readonly value="{{selectedCompany?.address?.city}}"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="reg">{{'signUp.arrange.regAndAccount' | translate}}:</label>
                <div class="d-flex content-input doubleContainer">
                  <input id="reg" class="form-control postalCode" type="number" min="0" readonly value="{{selectedCompany?.bankAccount?.registrationNumber}}"/>
                  <input id="accountNumber" class="form-control city" type="text" required readonly value="{{selectedCompany?.bankAccount?.accountNumber}}"/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer class="mb-5">
      <div class="d-flex justify-content-center">
        <button class="content-button button-text text-uppercase font-italic mt-5" type="submit" name="register" [disabled]="createEventForm.invalid" cdkStepperNext>
          {{'global.continue' | translate}}
        </button>
      </div>
    </footer>
  </cdk-step>
  <cdk-step>
    <ng-template cdkStepLabel>
      <span>{{eventForm.controls.name.value ? eventForm.controls.name.value : ('signUp.arrange.eventName'| translate)}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.arrange.step2Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <app-create-edit-event [company]="selectedCompany" (formValid)="eventFormValid($event)" (form)="setEventForm($event)" [validate]="validate$"></app-create-edit-event>
      </div>
    </div>
    <footer class="mb-5">
      <div class="d-flex justify-content-center flex-wrap mt-5">
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" cdkStepperPrevious>
          {{'global.back' | translate}}
        </button>
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" [disabled]="eventForm.invalid" cdkStepperNext>
          {{'global.continue' | translate}}
        </button>
      </div>
    </footer>
  </cdk-step>
  <cdk-step [stepControl]="termsGdprForm">
    <ng-template cdkStepLabel>
      <span>{{'global.summary' | translate}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.arrange.step3Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <div class="sign-up-summary">
          <table class="summary-table">
            <tr>
              <td><span class="text-uppercase">{{'signUp.arrange.companyName' | translate}}:</span></td>
              <td>
                {{selectedCompany?.name}}
              </td>
            </tr>
            <tr>
              <td>{{'global.cvr' | translate}}:</td>
              <td>{{selectedCompany?.cvr}}</td>
            </tr>
            <div class="mt-3">
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.name' | translate}}:</span></td>
                <td>{{eventForm.controls.name.value}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.contactPerson' | translate}}:</span></td>
                <td>{{eventForm.controls.contactPerson.value}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.contactEmail' | translate}}:</span></td>
                <td>{{eventForm.controls.contactEmail.value}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.address' | translate}}</span></td>
                <td>{{utilService.formatAddress(selectedCompany?.address)}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.startDate' | translate}}:</span></td>
                <td>{{eventForm.controls.startDate.value | date: 'dd-MM-yyyy'}} {{eventForm.controls.startTime.value}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.endDate' | translate}}:</span></td>
                <td>{{eventForm.controls.endDate.value | date: 'dd-MM-yyyy'}} {{eventForm.controls.endTime.value}}</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.yourActivities' | translate}}*:</span></td>
                <td>
                  <span *ngFor="let type of getSummarySponsorParticipateTypes(); let i = index">{{i > 0 ? ', ' : ''}}{{'global.sponsorParticipateTypeItem.' + type | translate}}</span>
                </td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.teamType' | translate}}:</span></td>
                <td>
                  <span *ngFor="let type of getSummarySponsorTeamTypes(); let i = index">{{i > 0 ? ', ' : ''}}{{'global.sponsorTeamTypeItem.' + type | translate}}</span>
                </td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.entryFee' | translate}}:</span></td>
                <td>{{eventForm.controls.entryFee.value}},- kr</td>
              </tr>
              <tr>
                <td><span class="text-uppercase">{{'signUp.arrange.webPage' | translate}}:</span></td>
                <td>{{eventForm.controls.webPage.value}}</td>
              </tr>
              <tr>
                <td>facbook:</td>
                <td>{{eventForm.controls.facebook.value}}</td>
              </tr>
              <tr class="mt-2">
                <td class="text-uppercase">{{'signUp.arrange.description' | translate}}:</td>
                <td>{{eventForm.controls.comment.value}}</td>
              </tr>
            </div>
          </table>
          <form class="mt-3" [formGroup]="termsGdprForm">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="gdpr" formControlName="gdpr">
              <label class="form-check-label" for="gdpr">
                {{'signUp.arrange.gdpr' | translate}}
                <a [routerLink]="getGdprRoute()" target="_blank">{{'signUp.arrange.gdprUrl' | translate}}</a>
              </label>
            </div>
            <div class="form-check mt-4">
              <input class="form-check-input" type="checkbox" value="" id="terms" formControlName="terms">
              <label class="form-check-label" for="terms">
                {{'signUp.arrange.terms' | translate}}
                <a [routerLink]="getTermsRoute()" target="_blank">{{'signUp.arrange.termsUrl' | translate}}</a>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer class="mt-5 mb-5">
      <div class="d-flex justify-content-center">
        <div class="sign-up-summary">
          <div class="d-flex justify-content-center flex-wrap">
            <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" cdkStepperPrevious>
              {{'global.back' | translate}}
            </button>
            <button class="content-button button-text text-uppercase font-italic" type="button" name="register" [disabled]="!valid()" (click)="createEvent()">
              {{'signUp.arrange.signUp' | translate}}
            </button>
          </div>
          <div class="mt-5 mb-5">
            {{'signUp.arrange.editEvent' | translate}}
          </div>
        </div>
      </div>
    </footer>
  </cdk-step>
</app-stepper>
