import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrlToken } from 'shared';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {

  }

  public sendContactForm(contact: Contact): Observable<any> {
    if (contact.webUrl != null) {
      return;
    }
    return this.http.post<any>(`${this.apiUrl}/api/Contact/SendContactRequest`, contact);
  }
}
