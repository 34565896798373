<div *ngIf="registrations">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header align-items-center">{{'tab-event-latest-event-registrations.title' | translate }}</div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mt-4 mb-4">
      <div class="table-responsive">
        <table class="table tabs-table-registrations">
          <thead>
          <tr>
            <td>{{'tab-event-latest-event-registrations.tableHeaders.name' | translate}}</td>
            <td>{{'tab-event-latest-event-registrations.tableHeaders.activity' | translate}}</td>
            <td>{{'tab-event-latest-event-registrations.tableHeaders.time' | translate}}</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let registration of registrations.eventRegistrations">
            <td>{{registration.name !== '' ? registration.name : ('global.anonymous' | translate)}}</td>
            <td>{{registration.unitCount}} {{'global.unitTypeItem.' + registration.unitType | translate}}</td>
            <td>{{registration.timeStamp | date: utilService.getDateTimeFormat()}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center mt-5">
  <eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="goToPage($event)" (nextPageEvent)="goToPage($event)" (prevPageEvent)="goToPage($event)"></eventShop-pagination-component>
</div>

