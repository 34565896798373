import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { AdminService, EventShopUser} from 'shared';

@Component({
  selector: 'app-tab-admin-user',
  templateUrl: './tab-admin-user.component.html',
  styleUrls: ['./tab-admin-user.component.scss']
})
export class TabAdminUserComponent implements OnInit, OnDestroy{
  public user$: Observable<EventShopUser>;

  private previousNavigation: Navigation;
  private readonly subscription = new Subscription();

  private userId: string;

  constructor(private adminService: AdminService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.subscription.add(this.activatedRoute.queryParams.subscribe(queryParam => {
      if (queryParam.userId) {
        this.previousNavigation = this.router.getCurrentNavigation().previousNavigation;
        this.userId = queryParam.userId;
        this.user$ = this.adminService.getUser(queryParam.userId as string)
          .pipe(catchError(err => {
            this.router.navigate([Consts.adminRoot])
            return of<EventShopUser>();
          }));
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public validate() {
    this.adminService.resetUserPassword(this.userId).pipe(first())
      .subscribe(_ => {
        if (this.previousNavigation == null) {
          this.router.navigate([Consts.userInfoRoute]);
        } else {
          this.router.navigateByUrl(this.previousNavigation.extractedUrl)
            .then(() => {
              window.location.reload();
            });
        }
      });
  }
}
