import { Component, Input, OnInit } from '@angular/core';
import { SponsorEvent } from '../../models/sponsor-event';
import { Consts } from '../../consts';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-tab-event-invite-participant',
  templateUrl: './tab-event-invite-participant.component.html',
  styleUrls: ['./tab-event-invite-participant.component.scss']
})
export class TabEventInviteParticipantComponent implements OnInit {
  @Input()
  public event: SponsorEvent

  constructor(private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  public getParticipantLinkUrl(): string {
    return `${window.location.protocol}//${window.location.host}/${Consts.inviteLinkRoute}/${this.event?.link}`;
  }

  public copied($event: any){
    this.toastService.showToast('toast.copied', 'toast.link');
  }
}
