import { ErrorHandler, Injectable } from '@angular/core';
import { ModalService } from './services/modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { Integrations } from '@sentry/tracing';
import { LoaderService } from '../../../shared/src/lib/services/loader.service';

@Injectable()
export class CustomSentryErrorHandler implements ErrorHandler {
  constructor(private modalService: ModalService, private loaderService: LoaderService) {
    if (!environment.local) {
      const serverErrorsRegex = new RegExp(
        `401 Unauthorized|403 Forbidden|429 Too Many Requests`,
        'mi'
      );
      Sentry.init({
        dsn: "https://8f33f00b22f24dcc859b1af3c963318e@o516060.ingest.sentry.io/5621934",
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["https://eventshop-d-we.azurewebsites.net", "https://joinandmove.dk"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        ignoreErrors: [serverErrorsRegex]
      });
    }
  }

  handleError(error: any) {
    if (error instanceof Error) {
      this.modalService.showModal('modal.error', 'modal.description')

      if (!environment.local) {
        Sentry.captureException(error);
      }
      console.log('Error', error);
    } else if (error instanceof HttpErrorResponse) {
      if (error.status == 400 || error.status == 500) {
        this.modalService.showModal('modal.error', 'modal.description')
        if (!environment.local) {
          Sentry.captureException(error.error || error, {extra: {'payload': error.error}});
        }
        console.log('Error', error.error || error);
      }
    }
  }
}
