<app-stepper linear (selectionChange)="selectionChanged($event)">
  <cdk-step [stepControl]="userForm">
    <ng-template cdkStepLabel>
      <span>{{user ? user.firstName + ' ' + user.lastName : ('signUp.participate.username'| translate)}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.participate.step1Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <div class="container-fluid" *ngIf="user">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <form [formGroup]="userForm" class="sign-up-form">
                <div class="form-group">
                  <label class="form-label" for="name">{{'signUp.participate.name' | translate}}</label>
                  <input id="name" class="form-control content-input" value="{{user.firstName}} {{user.lastName}}" type="text" readonly/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="phoneNumber">{{'signUp.phone' | translate}}</label>
                  <input id="phoneNumber" class="form-control content-input" value="{{user.phoneNumber}}" type="text" readonly/>
                </div>
                <div class="form-group">
                  <label class="form-label" for="email">{{'signUp.email' | translate}}</label>
                  <input id="email" class="form-control content-input" value="{{user.email}}" type="text" readonly/>
                </div>
                <div>
                  <div class="form-group">
                    <label class="form-label" for="streetOne">{{'signUp.address1' | translate}}</label>
                    <input id="streetOne" class="form-control content-input" value="{{user.address?.streetOne}}" type="text" readonly/>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="streetTwo">{{'signUp.address2' | translate}}</label>
                    <input id="streetTwo" class="form-control content-input" value="{{user.address?.streetTwo}}" type="text" readonly/>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="postalCode">{{'signUp.postalCodeAndCity' | translate}}</label>
                    <div class="d-flex content-input doubleContainer">
                      <input id="postalCode" class="form-control postalCode" value="{{user.address?.postalCode}}" type="number" min="0" readonly/>
                      <input id="city" class="form-control city" value="{{user.address?.city}}" type="text" readonly/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-center">
                      <div class="pt-1 pb-2">
                        <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [routerLink]="editUserInfo()">
                          {{'user.editUserInfo' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="visible" formControlName="visible">
                    <label class="form-check-label" for="visible">
                      {{'signUp.participate.visible' | translate}}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="rankings" formControlName="rankings">
                    <label class="form-check-label" for="rankings">
                      {{'signUp.participate.rank' | translate}}
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="mb-5">
      <div class="d-flex justify-content-center">
        <button class="content-button button-text text-uppercase font-italic mt-5" type="submit" name="register" [disabled]="userForm.invalid" cdkStepperNext>
          {{'global.continue' | translate}}
        </button>
      </div>
    </footer>
  </cdk-step>
  <cdk-step [stepControl]="userSignUpForm">
    <ng-template cdkStepLabel>
      <span>{{selectedEvent ? selectedEvent.name : ('signUp.participate.eventName'| translate)}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.participate.step2Title' | translate}}</h1>
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <div [ngClass]="(breakpointObserverService.ipad$ | async).matches ? 'w-100' : ''">
          <form [formGroup]="userSignUpForm" class="sign-up-form">
            <div class="form-group">
              <label class="form-label" for="eventId">{{'signUp.chooseEvent' | translate}}*</label>
              <select id="eventId" class="form-select content-input" formControlName="eventId" required (change)="eventSelected()">
                <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                <option *ngFor="let event of events" value="{{event.id}}">{{event.name}} - {{event.location.postalCode}} {{event.location.city}}</option>
              </select>
            </div>
            <div *ngIf="alreadyParticipating()" class="already-signed-up">
              <div>{{'signUp.participate.alreadyParticipating' | translate}}</div>
              <div *ngIf="!utilService.dateBeforeNow(selectedEvent?.startDate)">{{'signUp.participate.alreadyParticipatingEdit' | translate}}
                <a [routerLink]="getEditSignUpRoute()">{{'signUp.participate.alreadyParticipatingLink' | translate}}</a>
              </div>
            </div>
            <div *ngIf="!alreadyParticipating()">
              <div class="form-group">
                <label class="form-label" for="sponsorParticipationType">{{'signUp.participate.sponsorParticipateType' | translate}}*</label>
                <select id="sponsorParticipationType" class="form-select content-input" formControlName="sponsorParticipationType" required [attr.disabled]="selectedEvent ? null : ''">
                  <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                  <option *ngFor="let sponsorParticipateType of selectedEvent?.sponsorParticipateTypes" value="{{sponsorParticipateType}}">{{'global.sponsorParticipateTypeItem.' + sponsorParticipateType | translate}}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="form-label" for="unitType">{{'signUp.participate.unitType' | translate}}*</label>
                <select id="unitType" class="form-select content-input" formControlName="unitType" required [attr.disabled]="selectedEvent ? null : ''">
                  <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                  <option *ngFor="let unitType of getUnitTypes()" value="{{unitType}}">{{'global.unitTypeItem.' + unitType | translate}}</option>
                </select>
              </div>
              <div *ngIf="showTeamOptions()" class="form-group">
                <label class="form-label" for="sponsorTeamType">{{'signUp.participate.sponsorTeamType' | translate}}*</label>
                <select id="sponsorTeamType" class="form-select content-input" formControlName="sponsorTeamType" required [attr.disabled]="selectedEvent ? null : ''">
                  <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
                  <option *ngFor="let sponsorTeamType of selectedEvent?.sponsorTeamTypes" value="{{sponsorTeamType}}">{{'global.sponsorTeamTypeItem.' + sponsorTeamType | translate}}</option>
                </select>
              </div>
              <div *ngIf="showTeamOptions()">
                <div class="form-group">
                  <label class="col-form-label col-md-3" for="teamId">{{'signUp.chosenTeam' | translate}}</label>
                  <div class="col-md-9 ps-0 pe-0">
                    <input id="teamId" class="form-control content-input" type="text" value="{{selectedTeam?.name}}" readonly required/>
                  </div>
                </div>
                <div class="form-group mt-5">
                  <label class="col-form-label col-md-3">{{'signUp.participate.searchForTeam' | translate}}</label>
                  <div class="col-md-9 ps-0 pe-0">
                    <input class="form-control content-input" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="teamSearch"/>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3"></div>
                    <div class="col-md-9 d-flex justify-content-center">
                      <button class="content-button form-button btn text-uppercase font-italic" type="button" name="search" (click)="searchTeams()">
                        {{'global.search' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-5">
                  <label class="col-form-label col-md-3">{{'signUp.participate.createTeam' | translate}}</label>
                  <div class="col-md-9 ps-0 pe-0">
                    <input class="form-control content-input" type="text" [(ngModel)]="teamName" [ngModelOptions]="{standalone: true}"/>
                    <div *ngIf="this.teamCreationError" class="custom-invalid-feedback">
                      {{'signUp.participate.teamCreateError' | translate}}
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3"></div>
                    <div class="col-md-9 d-flex justify-content-center">
                      <button class="content-button form-button btn text-uppercase font-italic" type="button" name="create" (click)="createTeam()">
                        {{'global.create' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
                <table>
                  <tbody>
                  <tr *ngFor="let team of (teams$ | async)">
                    <td>{{team.name}}</td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <button type="button" class="content-button-small" name="select" (click)="selectTeam(team)">
                          <span>{{'global.choose' | translate}}</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer class="mb-5">
      <div class="d-flex justify-content-center flex-wrap mt-5">
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" cdkStepperPrevious>
          {{'global.back' | translate}}
        </button>
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" [disabled]="userSignUpForm.invalid" cdkStepperNext>
          {{'global.continue' | translate}}
        </button>
      </div>
    </footer>
  </cdk-step>
  <cdk-step [stepControl]="paymentSignUp">
    <ng-template cdkStepLabel>
      <span>{{'signUp.participate.step3Title'| translate}}</span>
    </ng-template>
    <div>
      <div>
        <h1 class="sign-up-header text-center">{{'signUp.participate.step3Title' | translate}}</h1>
      </div>
      <div class="mt-5 container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center">
            <form [formGroup]="paymentSignUp" class="sign-up-form" *ngIf="!alreadyParticipating()">
              <div class="form-group">
                <label class="form-label" for="paymentPerUnit">{{'signUp.participate.paymentPerUnit' | translate}} {{userSignUpForm.controls.unitType.value !== -1 ? ('global.unitTypeItem.' + userSignUpForm.controls.unitType.value | translate) : ''}}</label>
                <input id="paymentPerUnit" class="form-control content-input" formControlName="paymentPerUnit" type="number" required placeholder="{{'global.paymentPer' | translate}}"/>
              </div>
              <div class="form-group">
                <label class="form-label" for="singlePayment">{{'signUp.participate.singlePayment' | translate}}</label>
                <input id="singlePayment" class="form-control content-input" formControlName="singlePayment" type="number" required placeholder="{{'global.currency' | translate}}"/>
                <div class="invalid" *ngIf="paymentSignUp.controls.singlePayment.invalid">
                  {{'signUp.participate.singlePaymentError' | translate}}
                </div>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" value="" id="showMaxPayment" formControlName="showMaxPayment" (change)="showMaxPaymentChanged($event)">
                <label class="form-check-label" for="showMaxPayment">
                  {{'signUp.participate.maxPayment' | translate}}
                </label>
              </div>
              <div class="form-group mt-2" *ngIf="paymentSignUp.controls.showMaxPayment.value">
                <input id="maxPayment" class="form-control content-input" formControlName="maxPayment" type="number" required placeholder="{{'global.currency' | translate}}"/>
                <div class="invalid" *ngIf="paymentSignUp.controls.maxPayment.invalid && (paymentSignUp.controls.maxPayment.dirty || paymentSignUp.controls.maxPayment.touched)">
                  {{'signUp.participate.maxPaymentError' | translate}}
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="entryFee">{{'signUp.participate.entryFee' | translate}}</label>
                <input id="entryFee" class="form-control content-input" type="text" readonly [value]="selectedEvent ? selectedEvent.entryFee : 'N/A'"/>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" value="" id="gdpr" formControlName="gdpr" required>
                <label class="form-check-label" for="gdpr">
                  {{'signUp.participate.gdpr' | translate}}
                  <a [routerLink]="getGdprRoute()" target="_blank">{{'signUp.participate.gdprUrl' | translate}}</a>
                </label>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" value="" id="terms" formControlName="terms" required>
                <label class="form-check-label" for="terms">
                  {{'signUp.participate.terms' | translate}}
                  <a [routerLink]="getTermsRoute()" target="_blank">{{'signUp.participate.termsUrl' | translate}}</a>
                </label>
              </div>
              <div class="mt-5 mb-5">
                {{'signUp.participate.entryFreeDeduction' | translate}}
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer class="mb-5">
        <div class="d-flex justify-content-center flex-wrap">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="register" cdkStepperPrevious>
            {{'global.back' | translate}}
          </button>
          <button class="content-button button-text text-uppercase font-italic" type="button" name="register" (click)="signUp()" [disabled]="paymentSignUp.invalid">
            {{'signUp.participate.signUp' | translate}}
          </button>
        </div>
      </footer>
    </div>
  </cdk-step>
</app-stepper>
