import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: StepperComponent}]
})
export class StepperComponent extends CdkStepper {
  @Input()
  public backDisabled: boolean = false;
  @Input()
  public nextDisabled: boolean = false;
  @Input()
  public doneDisabled: boolean = false;

  @Output()
  public done = new EventEmitter<void>();

  public isNextButtonHidden() {
    return !(this.steps.length === this.selectedIndex + 1);
  }
}
