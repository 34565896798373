<form [formGroup]="createEditEventForm" class="sign-up-form">
  <!--  <div class="form-group">-->
  <!--    <label>{{'signUp.arrange.eventProduct' | translate}}*:</label>-->
  <!--    <select id="eventProduct" class="form-control content-input" formControlName="eventProduct" required>-->
  <!--      <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>-->
  <!--      <option *ngFor="let eventProduct of getEventProducts()" value="{{eventProduct}}">{{'global.eventProductItem.' + eventProduct | translate}}</option>-->
  <!--    </select>-->
  <!--  </div>-->
  <div class="form-group">
    <label class="form-label" for="name">{{'signUp.arrange.name' | translate}}*:</label>
    <input id="name" class="form-control content-input" type="text" formControlName="name" required/>
  </div>
  <div formGroupName="location">
    <div class="form-group">
      <label class="form-label" for="streetOne">{{'signUp.arrange.address1' | translate}}*:</label>
      <input id="streetOne" class="form-control content-input" type="text" formControlName="streetOne" required/>
    </div>
    <div class="form-group">
      <label class="form-label" for="streetTwo">{{'signUp.arrange.address2' | translate}}</label>
      <input id="streetTwo" class="form-control content-input" type="text" formControlName="streetTwo"/>
    </div>
    <div class="form-group">
      <label class="form-label" for="postalCode">{{'signUp.arrange.postalCodeAndCity' | translate}}*</label>
      <div class="d-flex content-input doubleContainer">
        <input id="postalCode" class="form-control postalCode" type="text" min="0" maxlength="4" required formControlName="postalCode"/>
        <input id="city" class="form-control city" type="text" required formControlName="city"/>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="form-label" for="contactPerson">{{'signUp.arrange.contactPerson' | translate}}*:</label>
    <input id="contactPerson" class="form-control content-input" type="text" formControlName="contactPerson" required/>
  </div>
  <div class="form-group">
    <label class="form-label" for="contactEmail">{{'signUp.arrange.contactEmail' | translate}}*:</label>
    <input id="contactEmail" class="form-control content-input" type="text" formControlName="contactEmail" required/>
  </div>
  <div class="form-group">
    <label class="form-label" for="startDate">{{'signUp.arrange.startDate' | translate}}*:</label>
    <div class="d-flex content-input doubleContainer">
      <input id="startDate" class="form-control" type="date" formControlName="startDate" min="{{today() | date: 'yyyy-MM-dd'}}" required/>
      <input id="startTime" class="form-control" type="time" formControlName="startTime" required/>
    </div>
  </div>
  <div class="form-group">
    <label class="form-label" for="endDate">{{'signUp.arrange.endDate' | translate}}*:</label>
    <div class="d-flex content-input doubleContainer">
      <input id="endDate" class="form-control" type="date" formControlName="endDate" min="{{today() | date: 'yyyy-MM-dd'}}" required/>
      <input id="endTime" class="form-control" type="time" formControlName="endTime" required/>
    </div>
  </div>
  <div class="form-group">
    <label class="form-label" for="target">{{'signUp.arrange.target' | translate}}*:</label>
    <input id="target" class="form-control content-input" type="number" formControlName="target" required/>
  </div>
  <div class="form-group">
    <div>
      <label>{{'signUp.arrange.yourActivities' | translate}}*:</label>
    </div>
    <div class="form-check form-check-inline" *ngFor="let sponsorParticipateType of getSponsorParticipateTypes()">
      <input class="form-check-input" type="checkbox" value="" id="{{sponsorParticipateType}}" formControlName="{{sponsorParticipateType}}" (change)="sponsorParticipateTypeSelected()">
      <label class="form-check-label" for="{{sponsorParticipateType}}">{{'global.sponsorParticipateTypeItem.' + sponsorParticipateType | translate}}</label>
    </div>
  </div>
  <!--          <div class="form-group row">-->
  <!--            <label class="col-form-label col-md-3 text-uppercase">{{'signUp.arrange.teamType' | translate}}*</label>-->
  <!--            <div class="col-md-9 ps-0 pe-0">-->
  <!--              <div class="form-row me-0 ms-0">-->
  <!--                <div class="form-group" *ngFor="let sponsorTeamType of getSponsorTeamTypes()">-->
  <!--                  <div class="form-check form-check-inline">-->
  <!--                    <input class="form-check-input" type="checkbox" value="" id="{{sponsorTeamType}}" formControlName="{{sponsorTeamType}}">-->
  <!--                    <label class="form-check-label" for="{{sponsorTeamType}}">{{'global.sponsorTeamTypeItem.' + sponsorTeamType | translate}}</label>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <div class="form-group">
    <label class="form-label" for="entryFee">{{'signUp.arrange.entryFee' | translate}}:</label>
    <input id="entryFee" class="form-control content-input" type="number" formControlName="entryFee"/>
  </div>
  <div class="form-group">
    <label class="form-label" for="comment">{{'signUp.arrange.description' | translate}}:</label>
    <textarea id="comment" class="form-control content-input" rows="10" formControlName="comment" maxlength="1000"></textarea>
  </div>
  <div class="form-group">
    <label class="form-label" for="entryFee">{{'signUp.arrange.webPage' | translate}}:</label>
    <input id="webPage" class="form-control content-input" type="text" formControlName="webPage"/>
  </div>
  <div class="form-group">
    <label class="form-label" for="facebook">facebook:</label>
    <input id="facebook" class="form-control content-input" type="text" formControlName="facebook"/>
  </div>
</form>
