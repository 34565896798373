<div class="container-fluid bigger-body-font-size">
  <div class="row tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header align-items-center">{{'clubTerns.title' | translate }}</div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <div>
          <div>
            <div>
              <strong style="text-decoration: underline">Godkendelsesprocedure</strong>
            </div>
            <div>
             Join & Moves godkendelsesproceduren består af to elementer:
            </div>
            <div class="mt-2">
              Del 1. En forening anmoder om at benytte Join & Moves administrationsplatform til en event, ved at oprette en profil online i Join & Moves administrationsplatform. Profilen kan oprettes af en kontaktperson fra en arrangør forening. I forbindelse med oprettelsen godkender man som forening Join & Moves aftalevilkår, der danner grundlag for benyttelse af platformen.
            </div>
            <div class="mt-2">
            Del 2. Join & Move foretager dernæst en manual handling for godkendelse af foreningens anmodning. Join & Move ringer til foreningens ansvarlige formand og beder vedkommende verificere de indtastede oplysninger. Godkendelsesproceduren består af at verificere tre elementer: 1. forenings navn og CVR nr. - 2. foreningens formand – 3. foreningens konto nummer. Kan formanden verificere og bekræfte de indtastede oplysninger, vil foreningen blive blive godkendt i Join & Move og kunne oprette events.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong style="text-decoration: underline">Bemyndigelse</strong>
            </div>
            <div>
              Ved oprettelse af en arrangørprofil på administrationsplatformen bemyndiger foreningen Join & Move til på arrangørforeningens vegne at forestå alle administrative handlinger, tilhørende transaktioner og afslutte salget i forbindelse med en event.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong style="text-decoration: underline">Lovlighed af arrangement</strong>
            </div>
            <div>
              Join & Move kan ikke gøres ansvarlige for tilladelser til eller lovligheden af en event, idet det alene er foreningen der er arrangør – dette i form af man opretter eventet, rekrutterer deltagere og sponsorer til eventet og afvikler eventet. Join & Moves rolle er alene gennem administrationsplatformen at servicere og supportere foreningen administrativt med henblik på at lette det administrative arbejde omkring eventafviklingen.Er man som arrangørforening i tvivl om lovligheden af eventet man vil afvikle, henviser Join & Move foreningen til at søge råd og vejledning ved at kontakte myndighederne for svar på eventuelle spørgsmål.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong style="text-decoration: underline">Restance og udestående forhold</strong>
            </div>
            <div>
             Join & Move kan ikke drages til ansvar for restancer eller nogen former for udestående forhold af enhver art.  Indgåede aftaler er alene et forhold mellem forening og kunde (bidragyder). Join & Move leverer alene administrativ serviceydelse til foreningen og kan på ingen måde drages til ansvar, eksempelvis i tilfælde af indgåede sponsoraftaler ikke overholdes og indbetalinger udebliver. Restancer og udestående forhold er således alene et anliggende mellem forening og kunde/ bidragyder. Det beløb der udbetales til foreningen vil være det gennem portalen indbetalte beløb fratrukket service gebyr til Eventshop Aps.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong style="text-decoration: underline">Annullering af event på Join & Move.dk</strong>
            </div>
            <div>
             Eventshop.dk har til enhver tid ret til at annullere arrangørforenings brug af Join & Moves administrationsplatform, såfremt arrangørs brug af serviceplatformen ikke lever op til Eventshop.dk værdisæt. Eventshop.dk vil altid have mulighed for at annullere en event på platformen Join & Move uden yderligere begrundelse.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
