import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { SponsorRunEventService } from '../../services/sponsor-run-event.service';
import { first } from 'rxjs/operators';
import { AuthService, PaginationBaseComponent } from 'shared';
import { UtilService } from '../../services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventRegistrations } from '../../models/event-registrations';

@Component({
  selector: 'app-tab-event-latest-event-registrations',
  templateUrl: './tab-event-latest-event-registrations.component.html',
  styleUrls: ['./tab-event-latest-event-registrations.component.scss']
})
export class TabEventLatestEventRegistrationsComponent extends PaginationBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public eventId: number;

  public registrations: EventRegistrations;
  private signedIn = false;

  constructor(private sponsorRunEventService: SponsorRunEventService, private authService: AuthService, public utilService: UtilService, protected router: Router, protected  activatedRoute: ActivatedRoute) {
    super(router, activatedRoute)
    this.subscription.add(this.authService.signedInChanged$.subscribe(v => this.signedIn = v));
  }

  ngOnChanges(changes): void {
    if (changes["eventId"] && this.eventId && this.signedIn) {
      this.goToPage(1);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected updateItems(page: number) {
    if (!this.eventId || !this.signedIn) {
      return;
    }

    this.page = page;
    this.sponsorRunEventService.getLatestEventRegistrations({
      eventId: this.eventId,
      count: this.itemsPerPage,
      page: this.page - 1
    })
      .pipe(first()).subscribe(r => {
      this.registrations = r
      this.totalNumberOfPages = Math.ceil(this.registrations.eventRegistrations.length / this.itemsPerPage);
    });
  }

  public async goToPage(page: number) {
    this.updateItems(page);
  }
}
