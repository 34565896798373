import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyEvent } from '../../models/company-event';
import { EventsService } from '../../services/events.service';
import { Consts } from '../../consts';
import { UtilService } from '../../services/util.service';
import { BreakpointObserverService } from 'shared';

@Component({
  selector: 'app-club-admin-events',
  templateUrl: './club-admin-events.component.html',
  styleUrls: ['./club-admin-events.component.scss']
})
export class ClubAdminEventsComponent implements OnInit {
  public events$: Observable<CompanyEvent[]>;

  constructor(private eventsService: EventsService, public utilService: UtilService, public breakObserverService: BreakpointObserverService) {
    this.events$ = this.eventsService.getAdminEvents();
  }

  ngOnInit(): void {
  }

  public editEvent(eventId: number): string {
    return `/${Consts.editEventRoute}/${eventId}`;
  }

  public goToEvent(eventId: number): string {
    return `/${Consts.eventRoute}/${eventId}`;
  }
}
