<p>Find klub</p>

<input name="companySearchString" [(ngModel)]="companySearchString" matInput type="text"
step="1" placeholder="Søgetext" required> 
<br><br>
<button (click)="search()">Søg</button>
<div *ngIf="foundCompanies">
    Klubber:
    <div *ngFor="let company of foundCompanies">
        {{ company.name }} <button [routerLink]="['/company-homepage', company.id]">GO</button>
    </div>
</div>

<div>{{message}}</div>