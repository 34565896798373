import { Component, OnInit } from '@angular/core';
import { Rank } from '../../models/rank';
import { SponsorRunUserSummaryDto } from '../../models/SponsorRunUserSummaryDto';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-podium',
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss']
})
export class PodiumComponent implements OnInit {
  public first = of<string>();
  public firstAmount = of<string>();
  public second = of<string>();
  public secondAmount = of<string>();
  public third = of<string>()
  public thirdAmount = of<string>();

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  public setRanks(ranks: Rank[]) {
    if (ranks.length > 0) {
      if (ranks[0].companyInformation) {
        this.first = of(ranks[0].companyInformation.name)
      } else {
        this.first = this.translate.get('global.anonymous');
      }
      this.first = of(`${ranks[0].moneyCollected} kr`);
    }

    if (ranks.length > 1) {
      if (ranks[1].companyInformation) {
        this.second = of(ranks[1].companyInformation.name)
      } else {
        this.second = this.translate.get('global.anonymous');
      }
      this.secondAmount = of(`${ranks[1].moneyCollected} kr`);
    }

    if (ranks.length > 2) {
      if (ranks[2].companyInformation) {
        this.third = of(ranks[2].companyInformation.name)
      } else {
        this.third = this.translate.get('global.anonymous');
      }
      this.thirdAmount = of(`${ranks[2].moneyCollected} kr`);
    }
  }


  public setSponsorRunUser(sponsorRunUserSummaries: SponsorRunUserSummaryDto[]) {
    if (sponsorRunUserSummaries.length > 0) {
      if (sponsorRunUserSummaries[0].visible) {
        this.first = of(sponsorRunUserSummaries[0].name)
      } else {
        this.first = this.translate.get('global.anonymous');
      }
      this.firstAmount = of(`${sponsorRunUserSummaries[0].totalUserMoneyToBePayed + sponsorRunUserSummaries[0].totalSponsorMoney} kr`);
    }

    if (sponsorRunUserSummaries.length > 1) {
      if (sponsorRunUserSummaries[1].visible) {
        this.first = of(sponsorRunUserSummaries[1].name)
      } else {
        this.first = this.translate.get('global.anonymous');
      }
      this.secondAmount = of(`${sponsorRunUserSummaries[1].totalUserMoneyToBePayed + sponsorRunUserSummaries[1].totalSponsorMoney} kr`);
    }

    if (sponsorRunUserSummaries.length > 2) {
      if (sponsorRunUserSummaries[2].visible) {
        this.first = of(sponsorRunUserSummaries[2].name)
      } else {
        this.first = this.translate.get('global.anonymous');
      }
      this.thirdAmount = of(`${sponsorRunUserSummaries[2].totalUserMoneyToBePayed + sponsorRunUserSummaries[2].totalSponsorMoney} kr`);
    }
  }
}
