  <div *ngIf="event">
    <div class="tabs-header-container align-items-center ps-4 pe-4">
      <div class="tabs-header">{{'tab-event.title' | translate }}</div>
    </div>
    <div class="ps-4 pe-4">
      <div class="tabs-content mt-4 mb-4">
        <table class="tabs-table">
          <tr>
            <td>
              {{'tab-event.name' | translate}}:
            </td>
            <td>{{event.name}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.startDate' | translate}}:</td>
            <td>{{event.startDate | date: 'dd-MM-yyyy HH:mm'}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.endDate' | translate}}:</td>
            <td>{{event.endDate | date: 'dd-MM-yyyy HH:mm'}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.address1' | translate}}:</td>
            <td>{{event.location?.streetOne}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.address1' | translate}}:</td>
            <td>{{event.location?.streetTwo}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.postalCodeAndCity' | translate}}:</td>
            <td>{{event.location?.postalCode}} {{event.location?.city}}</td>
          </tr>
        </table>
        <table class="tabs-table mt-4">
          <tr>
            <td>{{'tab-event fire.companyName' | translate}}:</td>
            <td>{{event.company.name}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.contactPerson' | translate}}:</td>
            <td>{{event.contactPerson}}</td>
          </tr>
<!--          <tr>-->
<!--            <td>{{'tab-event.contactPhone' | translate}}:</td>-->
<!--            <td>TODO</td>-->
<!--          </tr>-->
          <tr>
            <td>{{'tab-event.contactEmail' | translate}}:</td>
            <td>{{event.contactEmail}}</td>
          </tr>
        </table>
        <table class="tabs-table mt-4">
          <tr>
            <td>{{'global.startAmount' | translate}}:</td>
            <td>{{sponsorEventCompanyOverview.sponsorEvent.entryFee | number :'':customTranslationService.getLang()}} kr</td>
          </tr>
          <tr>
            <td>{{'tab-event.goal' | translate}}:</td>
            <td>{{sponsorEventCompanyOverview.sponsorEvent.target | number :'':customTranslationService.getLang()}} kr</td>
          </tr>
          <tr>
            <td>{{'tab-event.total' | translate}}:</td>
            <td>{{sponsorEventCompanyOverview.totalMoney | number :'':customTranslationService.getLang()}} kr</td>
          </tr>
          <tr>
            <td>{{'tab-event.sponsors' | translate}}:</td>
            <td>{{sponsorEventCompanyOverview.sponsorCount | number :'':customTranslationService.getLang()}}</td>
          </tr>
          <tr>
            <td>{{'tab-event.participants' | translate}}:</td>
            <td>{{sponsorEventCompanyOverview.userCount | number :'':customTranslationService.getLang()}}</td>
          </tr>
        </table>
        <div class="mt-4" *ngIf="event.isAdmin">
          <div *ngIf="utilService.dateBeforeNow(event.startDate)">{{'tab-event.unableToEdit' | translate}}</div>
          <div *ngIf="!utilService.dateBeforeNow(event.startDate)"><a class="link-button" (click)="editEvent()">{{'global.edit' | translate}}</a></div>
        </div>
      </div>
    </div>
  </div>
