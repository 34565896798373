<div class="container-fluid bigger-body-font-size">
  <div class="row tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header align-items-center">{{'participateTerms.title' | translate }}</div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <div>
          <div>
            Join and Move stiller en elektronisk administrationsplatform til rådighed for foreninger eller andre aktører, som ønsker at oprette sponsorevents / støtteevents, og for deltagere og sponsorer, der vil støtte op om disse projekter.
          </div>
          <div class="mt-2">
            Administrationsplatformen giver tilmeldte deltagere en mulighed for at benytte en mailservice til at udsende invitationslinks til andre mulige deltagere til de events, man som deltager har tilmeldt sig. Benytter man denne servicemulighed, forpligter man sig til at benytte invitationslinks efter gældende regler i markedsføringsloven.
          </div>
          <div class="mt-2">
            <div>
              Det betyder blandt andet at:
            </div>
            <div>
              <ul>
                <li>Du må gerne sende personlige beskeder til folk du kender i dit netværk, hvor du inviterer dem til at deltage som deltager eller sponsor fra din egen mailadresse, sms eller andre private beskeder.</li>
                <li>Du må gerne dele invitationslinks mm. på din egen facebook side</li>
              </ul>
            </div>
            <div>
              Det er <span style="font-weight: bold; text-decoration: underline">IKKE</span> tilladt at sende massekommunikation ud til personer uden deres samtykke.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong style="text-decoration: underline">Betaling og betingelser</strong>
            </div>
            <div>
              Når eventet er færdigt, vil du og dine sponsorer modtage et link til betaling. Her vil det samlede beløb skulle indbetales til Join & Move. Såfremt den forening eller aktør der driver eventet er momsfritaget, vil din opkrævning også være uden moms. Hvis foreningen eller aktøren der driver eventet er momsregistreret, vil momsen udgøre 20 % af det der opkræves.
            </div>
            <div class="mt-2">
              Uanset hvilke momsforhold vil det beløb der opkræves altid passe med det du har indtastet. Så hvis du eksempelvis støtter med 10 kr., og momsforholdene gør at der skal svares moms, så fremgår dit støtte beløb til 8 kr. + 2 kr. til moms, altså samlet 10 kr. Spørg din forening om deres momsforhold. Langt de fleste foreninger vil være momsfritaget til disse events, og vil dermed modtage dit fulde støttebeløb.
            </div>
            <div class="mt-2">
              Join and Move opkræver et servicegebyr på 8,8 %, hos foreningen / eventarrangøren, af det indsamlede beløb ved eventet. Dette gebyr dækker diverse transaktions- og administrationsomkostninger samt servicegebyr anvendelse af Join & Moves elektroniske administrationsportal.
            </div>
          </div>
          <div class="mt-3">
            <div>
              <strong style="text-decoration: underline">Ansvar</strong>
            </div>
            <div>
              Join and Move er en serviceplatform, og Eventshop.dk ApS vil ikke dække tab direkte eller indirekte i forbindelse med brug af platformen eller events oprettet via platformen.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
