<div class="tabs-header-container align-items-center ps-4 pe-4">
  <div class="tabs-header align-items-center">{{'tab-event-sponsors.title' | translate }}</div>
  <div class="podium-container-header">
    <app-podium></app-podium>
  </div>
</div>
<div class="ps-4 pe-4">
  <div class="tabs-content mt-4 mb-4">
    <div>
      <form class="mx-auto">
        <div class="input-group mb-3">
          <div class="form-group">
            <label class="form-label" for="sorting">
              {{'tab-event-sponsor-run-users.sorting' | translate}}
            </label>
            <select id="sorting" class="form-select content-input" name="sorting" required [(ngModel)]="sorting" (change)="sortList()">
              <option value=0>{{'tab-event-sponsors.sortedNames' | translate}}</option>
              <option value=1>{{'tab-event-sponsors.money' | translate}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="table-responsive">
      <table class="table tabs-table-rankings">
        <thead>
        <tr>
          <td></td>
          <td>{{'tab-event-sponsors.tableHeaders.name' | translate}}</td>
          <td>{{'tab-event-sponsors.tableHeaders.sponsored' | translate}}</td>
          <td>{{'tab-event-sponsors.tableHeaders.current' | translate}}</td>
          <td>{{'tab-event-sponsors.tableHeaders.max' | translate}}</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let sponsorRank of shownSponsorRanks, let i = index">
          <td>{{indexOffset + i + 1}}</td>
          <td *ngIf="!sponsorRank.companyInformation">{{'global.anonymous' | translate}}</td>
          <td *ngIf="sponsorRank.companyInformation">{{sponsorRank.companyInformation.name}}</td>
          <td *ngIf="sponsorRank.companyInformation">
            <button class="popover-button" (click)="showSponsored.emit(sponsorRank.sponsorSummery)">{{'tab-event-sponsors.showInfo' | translate}}</button>
          </td>
          <td>{{sponsorRank.moneyCollected}},-</td>
          <td *ngIf="sponsorRank.companyInformation">{{(utilService.getCapSumSponsorRank(sponsorRank, sponsorEventCompanyOverviewResponse) === 0 ? ('global.noCap' | translate) : (utilService.getCapSumSponsorRank(sponsorRank, sponsorEventCompanyOverviewResponse) | number: '': customTranslationService.getLang()) + ',-')}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="goToPage($event)" (nextPageEvent)="goToPage($event)" (prevPageEvent)="goToPage($event)"></eventShop-pagination-component>
  </div>
</div>
