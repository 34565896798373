import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { catchError, first } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { Consts } from '../../consts';
import { SponsorEvent } from '../../models/sponsor-event';
import { SponsorRunUser } from '../../models/sponsor-run-user';
import { ActivatedRoute, Router } from '@angular/router';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { AuthService } from 'shared';
import { RegisterActivityRequest } from '../../models/RegisterActivityRequest';
import { FormGroup } from '@angular/forms';
import { FormViewComponent } from '../form-view/form-view.component';

@Component({
  selector: 'app-user-register-activity',
  templateUrl: './user-register-activity.component.html',
  styleUrls: ['./user-register-activity.component.scss']
})
export class UserRegisterActivityComponent extends FormViewComponent implements OnInit, OnDestroy {
  public event: SponsorEvent;
  public sponsorRunUser: SponsorRunUser;

  public formValidValue = false;
  private form: FormGroup;
  private subscription = new Subscription();

  constructor(private sponsorService: SponsorRunService, private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService, protected elementRef: ElementRef) {
    super(elementRef);
    this.subscription.add(this.authService.userChanged$.subscribe(_ => {
      const params = this.activatedRoute.snapshot.params;
      if (params.eventId) {
        forkJoin([
          this.sponsorService.getSponsorEvent(params.eventId),
          this.sponsorService.getCurrentAsSponsorRunUser(params.eventId)
        ]).pipe(catchError(_ => this.router.navigate([Consts.userEventsRoute])), first())
          .subscribe(([event, user]: [SponsorEvent, SponsorRunUser]) => {
            this.event = event;
            this.sponsorRunUser = user;
          });
      } else {
        this.router.navigate([Consts.userEventsRoute])
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public formValid(valid: boolean) {
    this.formValidValue = valid;
  }

  public setForm(form: FormGroup) {
    this.form = form;
  }

  public registerUserSponsorActivity() {
    if (this.formValidValue) {
      const request = this.form.value as RegisterActivityRequest;
      request.timeStamp = new Date();
      this.sponsorService.registerActivity(request).pipe(first()).subscribe(result => {
        this.router.navigate([Consts.userEventsRoute, this.event.id]);
      });
    } else {
      this.form.markAllAsTouched();
      this.showError();
    }
  }
}
