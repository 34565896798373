import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Directive()
export abstract class FormComponent implements OnInit, OnDestroy {
  @Output()
  public formValid = new EventEmitter<boolean>();

  @Output()
  public form = new EventEmitter<FormGroup>();

  @Input()
  public validate: Observable<boolean>;

  protected subscription = new Subscription();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected setForm(form: FormGroup) {
    this.subscription.add(form.valueChanges.subscribe(_ => {
      this.formValid.emit(form.valid && this.customValidated());
    }));
  }

  abstract customValidated(): boolean;
}
