import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-participate',
  templateUrl: './information-participate.component.html',
  styleUrls: ['./information-participate.component.scss']
})
export class InformationParticipateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
