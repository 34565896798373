<div class="toast-container toast-placement">
  <ngb-toast *ngFor="let toast of toasts" [autohide]="true" (hidden)="toastHidden(toast)" [animation]="true">
    <ng-template ngbToastHeader>
      <div class="me-auto">
        <strong class="me-auto">{{toast?.title | translate}}</strong>
      </div>
    </ng-template>
    {{toast?.message | translate}}
  </ngb-toast>
</div>
