<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'userResetPasswordLink.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="sendResetPasswordLink()">
        <div class="form-group">
          <label class="form-label" for="username">{{'signUpUser.username' | translate}}*</label>
          <input id="username" class="form-control content-input" formControlName="username" required/>
        </div>
        <div class="mt-2 mb-3">
          {{'userResetPasswordLink.desc' | translate}}
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update">
            {{'userResetPasswordLink.reset' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
