import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Consts } from '../../consts';
import { AuthService, BreakpointObserverService } from 'shared';
import { GetSponsorSummeryResponse } from '../../models/get-sponsor-summery-response';
import { SponsorRunSponsorService } from '../../services/sponsor-run-sponsor.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-user-sponsor-links',
  templateUrl: './sponsoring-events.component.html',
  styleUrls: ['./sponsoring-events.component.scss']
})
export class SponsoringEventsComponent implements OnInit, OnDestroy {
  public sponsorSummeries$: Observable<GetSponsorSummeryResponse[]>;
  private subscription = new Subscription();

  constructor(private sponsorService: SponsorRunSponsorService, private authService: AuthService, public utilService: UtilService, public breakObserverService: BreakpointObserverService) {
    this.subscription.add(this.authService.userChanged$.subscribe(u => {
      if (u) {
        this.sponsorSummeries$ = this.sponsorService.getSponsorSummery()
      }
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public goToEvent(eventId: number): string {
    return `/${Consts.eventRoute}/${eventId}`;
  }

  public goToPayment(event: GetSponsorSummeryResponse): string {
    return `/${Consts.sponsorPayment}/${event.sponsorEvent.eventId}`;
  }
}
