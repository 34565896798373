import { FormControl, FormGroup } from '@angular/forms';

export enum EEventProduct {
  Basic = 'Basic',
  Plus = 'Plus',
  Pro = 'Pro'
}

export function setDynamicEEventProductFormValues(formGroup: FormGroup) {
  for (const eventProduct of getEventProducts()) {
    formGroup.addControl(eventProduct, new FormControl(false));
  }
}

export function getEventProducts(): string[] {
  return Object.keys(EEventProduct);
}
