import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-sponsors',
  templateUrl: './information-sponsors.component.html',
  styleUrls: ['./information-sponsors.component.scss']
})
export class InformationSponsorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
