<div class="background">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-5">
        <h1 class="big-header-black">{{'information.sponsorsPage.title' | translate}}</h1>
      </div>
    </div>
    <div class="row justify-content-center mt-5 pb-5">
      <div class="col-md-8">
        <div class="body-text">{{'information.sponsorsPage.section1.body1' | translate}}</div>
        <div class="body-text mt-3 fw-bold">{{'information.sponsorsPage.section1.body2' | translate}}</div>
        <div class="body-text">
          <ul>
            <li [innerHTML]="'information.sponsorsPage.section1.body3' | translate"></li>
            <li [innerHTML]="'information.sponsorsPage.section1.body4' | translate"></li>
            <li [innerHTML]="'information.sponsorsPage.section1.body5' | translate"></li>
          </ul>
        </div>
        <div class="body-text mt-3 fw-bold" [innerHTML]="'information.sponsorsPage.section1.body6' | translate"></div>
      </div>
    </div>
  </div>
</div>
