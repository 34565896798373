import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrlToken } from 'shared';
import { Observable } from 'rxjs';
import { GetSponsorEventCompanyOverviewResponse } from '../models/GetSponsorEventCompanyOverviewResponse';
import { ApprovePaymentRequest } from '../models/approve-payment-request';

@Injectable({
  providedIn: 'root'
})
export class SponsorRunAdminService {

  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) { }

  public getSponsorEventCompanyOverview(eventId: number): Observable<GetSponsorEventCompanyOverviewResponse> {
    return this.http.get<GetSponsorEventCompanyOverviewResponse>(`${this.apiUrl}/api/SponsorRunAdmin/GetSponsorEventCompanyOverview?sponsorRunEventId=${eventId}`);
  }

  public approvePayment(approvePaymentRequest: ApprovePaymentRequest): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/SponsorRunAdmin/ApprovePayment`, approvePaymentRequest);
  }
}
