import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormViewComponent } from '../form-view/form-view.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ResetPasswordLink, UsersService } from 'shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-link',
  templateUrl: './reset-password-link.component.html',
  styleUrls: ['./reset-password-link.component.scss']
})
export class ResetPasswordLinkComponent extends FormViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public resetPasswordForm: FormGroup = new FormGroup({
    username: new FormControl(undefined, [Validators.required])
  });


  constructor(protected elementRef: ElementRef, private  usersService: UsersService, private router: Router) {
    super(elementRef);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async ngAfterViewInit() {
    super.ngAfterViewChecked();
  }

  public sendResetPasswordLink() {
    if (this.resetPasswordForm.valid) {
      this.usersService.resetPasswordLink(this.resetPasswordForm.value as ResetPasswordLink).pipe(first())
        .subscribe(_ => this.router.navigate(['/']));
    } else {
      this.resetPasswordForm.markAllAsTouched();
      this.showError();
    }
  }
}
