import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  BreakpointObserverService,
  CompaniesService,
  Company,
  EventShopUser,
  Team,
  TeamsService
} from 'shared';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { ESponsorType, getSponsorTypes } from '../../enums/esponsor-type.enum';
import { SponsorRunUser } from '../../models/sponsor-run-user';
import { Consts } from '../../consts';
import { RegisterUserAsSponsorRequest } from '../../models/register-user-as-sponsor-request';
import { SponsorEvent } from '../../models/sponsor-event';
import { ModalService } from '../../services/modal.service';
import { NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormViewComponent } from '../form-view/form-view.component';
import { SponsorRunSponsorService } from '../../services/sponsor-run-sponsor.service';
import { getUnitTypeTypes } from '../../enums/eunit-type.enum';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-become-sponsor',
  templateUrl: './become-sponsor.component.html',
  styleUrls: ['./become-sponsor.component.scss']
})
export class BecomeSponsorComponent extends FormViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public sponsorUserForm = new FormGroup({
    sponsorType: new FormControl(ESponsorType.Private, [Validators.required]),
    companyId: new FormControl(-1),
  });

  public sponsorForm = new FormGroup({
    eventId: new FormControl(-1, [Validators.required]),
    supportUserOrTeam: new FormControl(false),
    sponsorUserId: new FormControl(null),
    paymentPerUnit: new FormControl(0, [Validators.min(0), Validators.max(100000)]),
    maxPayment: new FormControl(undefined),
    showMaxPayment: new FormControl(false),
    supportCommunity: new FormControl(false),
    singlePayment: new FormControl(0, [Validators.min(0), Validators.max(1000000)]),
    visible: new FormControl(false),
    rankings: new FormControl(false),
    unitType: new FormControl(-1, [selected()]),
    sponsorParticipationType: new FormControl(-1, [selected()]),
    combination: new FormControl(-1, [Validators.required]),
    support: new FormControl(-1, [Validators.required])
  });

  public gdprForm = new FormGroup({
    gdpr: new FormControl(false, [Validators.requiredTrue]),
    terms: new FormControl(false, [Validators.requiredTrue]),
  });

  @Output()
  public stepChanged = new EventEmitter<number>();

  public teams$: Observable<Team[]>;
  public users: SponsorRunUser[];
  public user: EventShopUser;
  public companies: Company[];
  public company: Company;

  public teamSearch: string;
  public userSearch: any;

  public selectedUser: SponsorRunUser;

  public selectedEvent: SponsorEvent;
  public events: SponsorEvent[];

  public showUserNotSelectedError = false;

  @ViewChild('userSearchInput') set input(input: NgbTypeahead) {
    if (input) {
      this.userSearchInput = input;
    }
  }
  private userSearchInput: NgbTypeahead;

  private subscription = new Subscription();

  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();

  public search$ = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => {
      return !this.userSearchInput?.isPopupOpen() ?? false;
    }));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(map(term => {
      return (this.users.filter(u => u.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10);
    }));
  }

  constructor(private sponsorService: SponsorRunService,
              private sponsorRunSponsorService: SponsorRunSponsorService,
              private router: Router,
              private authService: AuthService,
              private teamsService: TeamsService,
              private modalService: ModalService,
              private companiesService: CompaniesService,
              private activatedRoute: ActivatedRoute,
              protected elementRef: ElementRef,
              public breakpointObserverService: BreakpointObserverService,
              private ngbModal: NgbModal) {
    super(elementRef);
    this.sponsorService.getSponsorEvents().pipe(first())
      .subscribe(foundEvents => this.events = foundEvents);
    this.subscription.add(this.authService.userChanged$.subscribe(user => {
      this.user = user;
      this.sponsorUserForm.controls.sponsorType.setValue(ESponsorType.Private);
      this.companiesService.getAdminsCompanies().pipe(first(), map(companies => companies.filter(c => c.isSponsor))).subscribe(c => this.companies = c);
    }));

    this.subscription.add(this.sponsorForm.controls.paymentPerUnit.valueChanges.subscribe(v => {
      this.paymentPerUnitChanged(v);
    }));

    this.subscription.add(this.sponsorForm.controls.maxPayment.valueChanges.subscribe(v => {
      this.maxPaymentChanged(v);
    }));

    this.subscription.add(this.sponsorForm.controls.singlePayment.valueChanges.subscribe(v => {
      this.singlePaymentChanged(v);
    }));

    this.sponsorForm.markAllAsTouched();
    this.gdprForm.markAllAsTouched();
  }

  ngOnInit() {
    const link = this.activatedRoute.snapshot.queryParamMap.get('id');
    const eventId = Number(this.activatedRoute.snapshot.queryParamMap.get('eventId'));
    if (link && eventId) {
      this.sponsorRunSponsorService.getSponsorRunUserDataFromLink(link).pipe(first()).subscribe(result => {
        this.sponsorForm.controls.eventId.patchValue(eventId);
        this.sponsorForm.controls.supportUserOrTeam.patchValue(true);
        this.sponsorForm.controls.sponsorUserId.patchValue(result.sponsorRunUser.userId);
        this.sponsorService.getSponsorRunUser(result.sponsorRunUser.userId, eventId).pipe(first())
          .subscribe(u => this.selectedUser = u);
        this.sponsorService.getSponsorEvents().pipe(first())
          .subscribe(foundEvents => this.selectedEvent = foundEvents.find(event => event.id == eventId));
      });
    }

    super.ngOnInit();
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public sponsor() {
    if (this.sponsorForm.valid && this.customValidation()) {
      const request = this.sponsorForm.value as RegisterUserAsSponsorRequest;
      request.sponsorType = this.sponsorUserForm.controls.sponsorType.value;
      request.companyId = this.sponsorUserForm.controls.companyId.value;

      this.sponsorService.registerUserAsSponsor(request).pipe(first()).subscribe(result => {
        this.router.navigate([Consts.signUpSucceededSponsorRoute, this.selectedEvent.id]);
      });
    }
  }

  public sponsorUserFormValid() {
    if (this.sponsorUserForm.controls.sponsorType.value === ESponsorType.Cvr) {
      return this.sponsorUserForm.controls.companyId.value !== -1;
    }

    return true;
  }

  public sponsorTypeChanged() {
    this.sponsorUserForm.controls.companyId.setValue(-1);
    this.company = null;
  }

  public companySelected() {
    this.company = this.companies.find(c => c.id == this.sponsorUserForm.controls.companyId.value);
    this.sponsorUserForm.controls.companyId.setValue(this.company.id);
  }

  public eventSelected() {
    this.selectedEvent = this.events.find(event => event.id == this.sponsorForm.controls.eventId.value);

    this.sponsorService.searchSponsorRunUsers(this.selectedEvent.id, this.userSearch)
      .pipe(first()).subscribe(r => {
      this.users = r.users;
    });

    this.sponsorForm.controls.supportUserOrTeam.setErrors({});
  }

  public selectUser(selection: NgbTypeaheadSelectItemEvent) {
    this.showUserNotSelectedError = false;
    this.selectedUser = selection.item;
    this.sponsorForm.controls.sponsorUserId.setValue(this.selectedUser.userId);
    this.sponsorForm.controls.combination.setValue(-1);
  }

  public customValidation(): boolean {
    let idSet = true;
    const cvrType = this.sponsorUserForm.controls.sponsorType.value === ESponsorType.Cvr;
    if (cvrType) {
      idSet = this.sponsorUserForm.controls.companyId.value !== -1;
    }

    let supported = true;

    if (this.sponsorForm.controls.supportUserOrTeam.value) {
      supported = this.sponsorForm.controls.sponsorUserId.value != null;
    }

    return supported && idSet && this.authService.signedInChanged$.value;
  }

  public supprtUserOrCommunityChanged($event: any) {
    this.selectedUser = null;
    this.sponsorForm.controls.unitType.setValue(-1);
    this.sponsorForm.controls.sponsorParticipationType.setValue(-1);
    this.sponsorForm.controls.sponsorUserId.setValue(null);
    this.sponsorForm.controls.supportUserOrTeam.setValue($event.target.value == 0);
    this.sponsorForm.controls.supportCommunity.setValue($event.target.value == 1);

    if (this.sponsorForm.controls.supportUserOrTeam.value) {
      this.showUserNotSelectedError = true;
      this.sponsorForm.controls.combination.setValidators([Validators.required, Validators.min(0)]);
      this.sponsorForm.controls.singlePayment.setValidators([Validators.min(0), Validators.max(100000)]);
      this.sponsorForm.controls.paymentPerUnit.setValidators([Validators.min(1), Validators.max(100000)]);
    } else {
      this.sponsorForm.controls.combination.setValidators([]);
      this.sponsorForm.controls.combination.setErrors(null);
      this.sponsorForm.controls.singlePayment.setValidators([Validators.min(1), Validators.max(100000)]);
      this.sponsorForm.controls.paymentPerUnit.setValidators([Validators.min(0), Validators.max(100000)]);
    }

    this.sponsorForm.controls.paymentPerUnit.setErrors({});
    this.sponsorForm.controls.singlePayment.setErrors({});

    this.sponsorForm.markAllAsTouched();
  }

  public getTermsRoute(): string {
    return `/${Consts.sponsorTermsRoute}`;
  }

  public getGdprRoute(): string {
    return `/${Consts.gdprRoute}`
  }

  public getSponsorTypes(): string[] {
    return getSponsorTypes();
  }

  public combinationSelected() {
    const selected = this.selectedUser.sponsorRunUserSignUpCombinations[this.sponsorForm.controls.combination.value];
    this.sponsorForm.controls.unitType.setValue(selected.unitType);
    this.sponsorForm.controls.sponsorParticipationType.setValue(selected.sponsorParticipationType);
  }

  public sponsorParticipateTypeSelected($event: any) {
    this.sponsorForm.controls.sponsorParticipationType.setValue($event.target.value);
  }

  public unitTypeSelected($event: any) {
    this.sponsorForm.controls.unitType.setValue($event.target.value);
  }

  public editUserInfo(): string {
    return `/${Consts.editUserInfoRoute}`;
  }

  public createCompany(): string {
    return `/${Consts.createEditCompanyRoute}`;
  }

  private searchUsers(text$: Observable<string>): Observable<SponsorRunUser[]> {
    return text$.pipe(map(term => {
      return (this.users.filter(u => u.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
    }));
  }

  public searchFormatter(user: SponsorRunUser): string {
    return user.name;
  }

  private paymentPerUnitChanged(newValue: number) {
    const maxPayment = this.sponsorForm.controls.maxPayment.value;
    const min = this.calcMin();
    if (maxPayment && maxPayment < min) {
      this.sponsorForm.controls.paymentPerUnit.setErrors({});
    } else {
      this.sponsorForm.controls.paymentPerUnit.setErrors(null);
    }
  }

  private singlePaymentChanged(newValue: number) {
    const maxPayment = this.sponsorForm.controls.maxPayment.value;
    const min = this.calcMin();
    if (maxPayment && maxPayment < min) {
      this.sponsorForm.controls.singlePayment.setErrors({});
    } else {
      this.sponsorForm.controls.singlePayment.setErrors(null);
    }
  }

  private maxPaymentChanged(newValue: any) {
    const min = this.calcMin();
    if (newValue && min > newValue) {
      this.sponsorForm.controls.maxPayment.setErrors({});
    } else {
      if (this.sponsorForm.controls.supportCommunity) {
        this.sponsorForm.controls.singlePayment.setErrors(null);
      }
      if (this.sponsorForm.controls.supportUserOrTeam) {
        this.sponsorForm.controls.paymentPerUnit.setErrors(null);
      }
      this.sponsorForm.controls.maxPayment.setErrors(null);
    }
  }

  private calcMin(): number {
    let min = 0;
    if (this.sponsorForm.controls.supportCommunity) {
      min = this.sponsorForm.controls.singlePayment.value
    }

    return min;
  }

  public getUnitTypes(): string[] {
    return getUnitTypeTypes();
  }

  public selectionChanged(event: StepperSelectionEvent) {
    this.stepChanged.next(event.selectedIndex);
  }

  public showMaxPaymentChanged($event: any) {
    if (!$event.target.checked) {
      this.sponsorForm.controls.maxPayment.setErrors(null);
      this.sponsorForm.controls.maxPayment.setValue(null);
      this.sponsorForm.controls.maxPayment.setValidators([]);
      this.sponsorForm.controls.maxPayment.markAsUntouched();
    } else {
      this.sponsorForm.controls.maxPayment.markAsTouched();
      this.sponsorForm.controls.maxPayment.setValidators([Validators.min(1), Validators.max(1000000)]);
    }
  }
}

export function selected(): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value != -1
      ? null : {wrongValue: control.value};
  }
}
