<div class="background">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-5">
        <h1 class="big-header-black">{{'information.title' | translate}}</h1>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-8 d-flex justify-content-between">
        <div><img class="model-image" src="assets/information/Modellen_01_-_sort.png"></div>
        <div><img class="model-image" src="assets/information/Modellen_04_-_sort.png"></div>
        <div><img class="model-image" src="assets/information/Modellen_03_-_sort.png"></div>
        <div><img class="model-image" src="assets/information/Modellen_02_-_sort.png"></div>
        <div><img class="model-image" src="assets/information/Modellen_05_-_sort.png"></div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-8">
        <h1 class="section-header"> {{'information.section1.title' | translate}}</h1>
        <div class="body-text">{{'information.section1.body1' | translate}}</div>
        <div class="body-text">{{'information.section1.body2' | translate}}</div>
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-md-8">
        <h1 class="section-header"> {{'information.section2.title' | translate}}</h1>
        <div class="body-text">
          <ul>
            <li>{{'information.section2.body1' | translate}}</li>
            <li>{{'information.section2.body2' | translate}}</li>
            <li>{{'information.section2.body3' | translate}}</li>
            <li>{{'information.section2.body4' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-md-8">
        <h1 class="section-header"> {{'information.section3.title' | translate}}</h1>
        <div class="body-text">
          <ul>
            <li [innerHTML]="'information.section3.body1' | translate: { link: getCreatEventUrl() }"></li>
            <li [innerHTML]="'information.section3.body2' | translate"></li>
            <li [innerHTML]="'information.section3.body3' | translate"></li>
            <li [innerHTML]="'information.section3.body4' | translate"></li>
            <li [innerHTML]="'information.section3.body5' | translate"></li>
            <li [innerHTML]="'information.section3.body5' | translate"></li>
          </ul>
        </div>
        <div class="body-text fst-italic">{{'information.section3.body7' | translate}}</div>
      </div>
    </div>
    <div class="mt-5 pb-5 d-flex justify-content-center" *ngIf="(breakpointObserverService.aboveiPad$ | async)?.matches">
      <div class="col-md-3 button-margin">
        <button class="action-button text-uppercase" (click)="goToCreateEventRouteRoute()">
          {{'information.arrange' | translate}}
        </button>
      </div>
      <div class="col-md-3 button-margin">
        <button class="action-button text-uppercase" (click)="goToParticipateRoute()">
          {{'information.participate' | translate}}
        </button>
      </div>
      <div class="col-md-3 button-margin">
        <button class="action-button text-uppercase" (click)="goToSponsorRoute()">
          {{'information.sponsor' | translate}}
        </button>
      </div>
    </div>
    <div class="mt-5 pb-5 d-flex align-items-center flex-column" *ngIf="(breakpointObserverService.belowiPad$ | async)?.matches">
      <div class="button-margin">
        <button class="action-button text-uppercase" (click)="goToCreateEventRouteRoute()">
          {{'information.arrange' | translate}}
        </button>
      </div>
      <div class="button-margin">
        <button class="action-button text-uppercase" (click)="goToParticipateRoute()">
          {{'information.participate' | translate}}
        </button>
      </div>
      <div class="button-margin">
        <button class="action-button text-uppercase" (click)="goToSponsorRoute()">
          {{'information.sponsor' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
