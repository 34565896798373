<div *ngIf="adminShownSponsorRunUserSummaries">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header align-items-center">{{'tab-all-sponsor-run-users-admin.title' | translate }}</div>
    <div class="podium-container-header">
      <app-podium></app-podium>
    </div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mt-4">
      <div>
        <form class="mx-auto">
          <div class="input-group mb-3">
            <div class="form-group">
              <label class="form-label" for="sorting">
                {{'tab-event-sponsor-run-users.sorting' | translate}}
              </label>
              <select id="sorting" class="form-select content-input" name="sorting" required [(ngModel)]="sorting" (change)="sortList()">
                <option value=0>{{'tab-event-sponsor-run-users.money' | translate}}</option>
                <option value=1>{{'tab-event-sponsor-run-users.activity' | translate}}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="form-check" *ngIf="utilService.dateBeforeNow(event.endDate)">
        <input class="form-check-input" type="checkbox" value="" id="community" (change)="approveCommunityPayments($event)" [checked]="event.approvedByAdminToBePayed">
        <label class="form-check-label" for="community">
          <strong>{{'tab-all-sponsor-run-users-admin.approveCommunity' | translate}}</strong>
        </label>
      </div>
    </div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mb-4">
      <div class="table-responsive">
        <table class="tabs-table-rankings table">
          <thead>
          <tr>
            <td></td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.name' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.participationType' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.moneyPrUnit' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.activity' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.total' | translate}}</td>
            <td class="highlight">{{'tab-event-sponsor-run-users.tableHeaders.potential' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.approved' | translate}}</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sponsorRunUserSummary of sortedSponsorRunUserSummaries, let i = index">
            <td>{{i + 1}}</td>
            <td>{{sponsorRunUserSummary.name}}</td>
            <td>
              <div>
                <div *ngFor="let userUnitTypeSummaries of sponsorRunUserSummary.userUnitTypeSummaries">
                  {{'global.sponsorParticipateTypeItem.' + userUnitTypeSummaries.sponsorParticipationType | translate}}
                </div>
              </div>
            </td>
            <td>
              <div>
                <div *ngFor="let userUnitTypeSummaries of sponsorRunUserSummary.userUnitTypeSummaries">
                  {{(userUnitTypeSummaries.sponsorMoneyPerUnit + userUnitTypeSummaries.userMoneyPerUnit)| number :'':customTranslationService.getLang()}}kr
                </div>
              </div>
            </td>
            <td>
              <div>
                <div *ngFor="let userUnitTypeSummaries of sponsorRunUserSummary.userUnitTypeSummaries">
                  {{userUnitTypeSummaries.totalUnitCount| number :'':customTranslationService.getLang()}} {{'global.unitTypeItem.' + userUnitTypeSummaries.unitType | translate}}
                </div>
              </div>
            </td>
            <td>
              {{(sponsorRunUserSummary.totalSponsorMoney + sponsorRunUserSummary.totalUserMoneyToBePayed)| number :'':customTranslationService.getLang()}}kr
            </td>
            <td class="highlight">
              {{(sponsorRunUserSummary.maxUserPayment ? (utilService.getCapSumSponsorRunUserSummary(sponsorRunUserSummary) | number: '': customTranslationService.getLang()) + ',-' : ('global.noCap' | translate))}}
            </td>
            <td [ngClass]="utilService.getPayedClassFromSponsorRunUser(sponsorRunUserSummary)">
              <div *ngIf="utilService.dateAfterNow(event.endDate)">
                {{'tab-event-sponsor-run-users.unableToApprove' | translate}}
              </div>
              <div class="form-check ps-0" *ngIf="utilService.dateBeforeNow(event.endDate)">
                <div>
                </div>
                <div class="d-flex justify-content-center flex-column">
                  <div class="mx-auto">
                    <button class="popover-button" (click)="showSponsorRunnerDetails.emit(sponsorRunUserSummary)">{{'tab-event-sponsors.showInfo' | translate}}</button>
                  </div>
                  <div class="mx-auto mt-2">
                    <input class="form-check-input position-static check-payment" type="checkbox" (change)="approvedForPayment($event, sponsorRunUserSummary)" [disabled]="checkboxDisabled(sponsorRunUserSummary)" [checked]="allApproved(sponsorRunUserSummary)">
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
