import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getUnitTypeTypes } from '../../enums/eunit-type.enum';
import { ESponsorTeamType } from '../../enums/esponsor-team-type.enum';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { AuthService, EventShopUser } from 'shared';
import { Consts } from '../../consts';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { SponsorRunUser } from '../../models/sponsor-run-user';
import { ModalService } from '../../services/modal.service';
import { SponsorEvent } from '../../models/sponsor-event';
import { forkJoin, Subscription } from 'rxjs';
import { AddParticipationCombinationRequest } from '../../models/add-participation-combination-request';
import { EditSignUpUserRequest } from '../../models/edit-sign-up-user-request';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-edit-signup.component.html',
  styleUrls: ['./user-edit-signup.component.scss']
})
export class UserEditSignupComponent implements OnInit, OnDestroy {
  public sponsorRunUser: SponsorRunUser;
  public event: SponsorEvent;

  public existingParticipationForm = new FormGroup({
    sponsorParticipationType: new FormControl(-1, [Validators.required]),
    paymentPerUnit: new FormControl(0, [Validators.min(0)]),
  });

  public newParticipationForm = new FormGroup({
    sponsorParticipationType: new FormControl(-1, [Validators.required]),
    unitType: new FormControl(-1, [Validators.required]),
    paymentPerUnit: new FormControl(0, [Validators.min(0)]),
  });

  public userSignUpForm = new FormGroup({
    visible: new FormControl(false),
    rankings: new FormControl(false),
    eventId: new FormControl(-1, [Validators.required]),
    maxPayment: new FormControl(0, [Validators.min(0)]),
    singlePayment: new FormControl(0, [Validators.min(0)])
  });

  private readonly previousNavigation: Navigation;
  private user: EventShopUser;
  private subscription = new Subscription();

  constructor(private router: Router, private sponsorService: SponsorRunService, private activatedRoute: ActivatedRoute, private authService: AuthService, private modalService: ModalService) {
    this.previousNavigation = this.router.getCurrentNavigation().previousNavigation;
    this.subscription.add(this.authService.userChanged$.subscribe(u => {
      this.user = u;
      this.fetchData();
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private fetchData() {
    const params = this.activatedRoute.snapshot.params;
    if (params.eventId) {
      forkJoin([
        this.sponsorService.getSponsorRunUser(this.user.id, params.eventId),
        this.sponsorService.getSponsorEvent(params.eventId)
      ]).pipe(catchError(_ => this.router.navigate(['/'])), first())
        .subscribe(([sponsorRunUser, sponsorEvent]: [SponsorRunUser, SponsorEvent]) => {
          this.event = sponsorEvent;
          this.sponsorRunUser = sponsorRunUser;
          this.userSignUpForm.patchValue(sponsorRunUser);
        });
    } else {
      if (this.previousNavigation == null) {
        this.router.navigate([Consts.eventRoute, this.sponsorRunUser.sponsorEvent.id]);
      } else {
        this.router.navigateByUrl(this.previousNavigation.extractedUrl);
      }
    }
  }

  public getUnitTypes(): string[] {
    return getUnitTypeTypes();
  }

  public updateSignUp() {
    const editSignUpUserRequest = this.userSignUpForm.value as EditSignUpUserRequest;
    editSignUpUserRequest.eventId = this.sponsorRunUser.sponsorEvent.id;
    this.sponsorService.editSignUpUser(editSignUpUserRequest).pipe(first())
      .subscribe(_ => {
      });
  }

  public sponsorParticipationTypeSelected(form: FormGroup) {
    const existing = this.sponsorRunUser.sponsorRunUserSignUpCombinations.find(s => s.id == form.controls.sponsorParticipationType.value);
    form.controls.paymentPerUnit.setValue(existing.paymentPerUnit);
  }

  public updateExisting(form: FormGroup) {
    const existing = this.sponsorRunUser.sponsorRunUserSignUpCombinations.find(s => s.id == form.controls.sponsorParticipationType.value);

    const addParticipationCombinationRequest: AddParticipationCombinationRequest = {
      eventId: this.sponsorRunUser.sponsorEvent.id,
      paymentPerUnit: form.controls.paymentPerUnit.value,
      sponsorParticipationType: existing?.sponsorParticipationType ?? form.controls.sponsorParticipationType.value,
      sponsorTeamType: existing?.sponsorTeamType ?? ESponsorTeamType.Individual,
      unitType: existing?.unitType ?? form.controls.unitType.value
    };

    this.sponsorService.addParticipationCombination(addParticipationCombinationRequest).pipe(first())
      .subscribe(_ => {
        this.fetchData();
        form.controls.sponsorParticipationType.setValue(-1);
        form.controls.paymentPerUnit.setValue(0);
        form.controls.unitType?.setValue(-1);
      });
  }

  public customValidation(form: FormGroup, checkUnitType: boolean): boolean {
    let valid = form.controls.sponsorParticipationType.value !== -1;
    if (checkUnitType) {
      valid = valid && form.controls.unitType.value !== -1;
    }

    return valid;
  }

  public alreadyHaveThatCombination(form: FormGroup): boolean {
    return this.sponsorRunUser.sponsorRunUserSignUpCombinations.find(s => s.sponsorParticipationType == form.controls.sponsorParticipationType.value && s.unitType == form.controls.unitType.value) != null;
  }

  public back() {
    if (this.previousNavigation == null) {
      this.router.navigate([Consts.userEventsRoute]);
    } else {
      this.router.navigateByUrl(this.previousNavigation.extractedUrl);
    }
  }
}
