import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, EventShopUser, UsersService } from 'shared';
import { Navigation, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { FormViewComponent } from '../form-view/form-view.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user-info',
  templateUrl: './edit-user-info.component.html',
  styleUrls: ['./edit-user-info.component.scss']
})
export class EditUserInfoComponent extends FormViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public userForm: FormGroup = new FormGroup({
    firstName: new FormControl(undefined, [Validators.required]),
    lastName: new FormControl(undefined, [Validators.required]),
    phoneNumber: new FormControl(undefined, [Validators.pattern("[0-9]{8}"), Validators.required]),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    address: new FormGroup({
      streetOne: new FormControl(undefined, [Validators.required]),
      streetTwo: new FormControl(undefined),
      postalCode: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{4}")]),
      city: new FormControl(undefined, [Validators.required]),
    }),
  });

  private subscription = new Subscription();
  private readonly previousNavigation: Navigation;

  constructor(private userService: UsersService, private authService: AuthService, private router: Router, protected elementRef: ElementRef) {
    super(elementRef);
    this.previousNavigation = this.router.getCurrentNavigation().previousNavigation;
    this.subscription.add(this.authService.userChanged$.subscribe(u => {
      this.userForm.patchValue(u);
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngAfterViewInit() {
    if (!this.authService.signedInChanged$.value) {
      this.router.navigate([`/${Consts.userSignIn}`], {queryParams: {returnUrl: this.router.routerState.snapshot.url}});
    }
    super.ngAfterViewChecked();
  }

  public updateUserInfo() {
    if (this.userForm.valid) {
      this.userService.updateUser(this.userForm.value as EventShopUser).pipe(first())
        .subscribe(_ => {
          this.authService.fetchUserInfo();
          if (this.previousNavigation == null) {
            this.router.navigate([Consts.userInfoRoute]);
          } else {
            this.router.navigateByUrl(this.previousNavigation.extractedUrl);
          }
        });
    } else {
      this.userForm.markAllAsTouched();
      this.showError();
    }
  }
}
