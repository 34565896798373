export class Consts {
  public static readonly eventsRoute = 'events';
  public static readonly signUpRoute = 'sign-up';
  public static readonly informationRoute = 'information';
  public static readonly informationArrangeRoute = 'information/arrrange';
  public static readonly informationParticipateRoute = 'information/participate';
  public static readonly informationSponsorsRoute = 'information/sponsors';
  public static readonly aboutRoute = 'about';
  public static readonly contactRoute = 'contact';
  public static readonly sponsorTermsRoute = 'sponsor-terms';
  public static readonly eventTermsRoute = 'event-terms';
  public static readonly participateTermsRoute = 'participate-terms';
  public static readonly gdprRoute = 'gdpr';
  public static readonly signUpSucceededUserRoute = 'user-signup-succeeded';
  public static readonly signUpSucceededEventRoute = 'event-creation-succeeded';
  public static readonly signUpSucceededSponsorRoute = 'sponsor-signup-succeeded';
  public static readonly sponsorLinkRoute = 'sponsor-link';
  public static readonly inviteLinkRoute = 'invite-link';
  public static readonly eventRoute = 'event';
  public static readonly userSignUpRoute = 'user-sign-up';
  public static readonly userChangePasswordRoute = 'user-change-password';
  public static readonly userResetPasswordLinkRoute = 'user-reset-password-link';
  public static readonly userResetPasswordRoute = 'user-reset-password';
  public static readonly clubTerms = 'club-terms';

  public static readonly userSignIn = 'user-sign-in';
  public static readonly userInfoRoute = 'user';
  public static readonly editUserInfoRoute = 'edit-user';
  public static readonly userClubsRoute = 'my-clubs';
  public static readonly userEventsRoute = 'my-events';
  public static readonly userSponsorLinksRoute = 'my-sponsor-links';
  public static readonly registerActivityRoute = 'register-activity';
  public static readonly userEditSignUp = 'user-edit-signup';
  public static readonly sponsorPayment = 'sponsor-payment';
  public static readonly paymentPending = 'payment-pending';
  public static readonly paymentComplete = 'payment-complete';

  public static readonly createEditClubRoute = 'create-edit-club';
  public static readonly createEditCompanyRoute = 'create-edit-company';
  public static readonly editEventRoute = 'create-edit-event';
  public static readonly adminClubsRoute = 'admin-clubs';
  public static readonly adminCompaniesRoute = 'admin-companies';
  public static readonly adminEventsRoute = 'admin-events';
  public static readonly adminUserRoute = 'admin-user';

  public static readonly adminRoot = 'admin';
  public static readonly adminEventValidate = 'validate-event';

  public static readonly participateTab = 'participate'
  public static readonly arrangeTab = 'arrange'
  public static readonly sponsorTab = 'sponsor'
}
