import { FormControl, FormGroup } from '@angular/forms';

export enum ESponsorTeamType {
  Individual= 'Individual',
  Team = 'Team'
}

export function setDynamicESponsorTeamTypeFormValues(formGroup: FormGroup) {
  for (const sponsorTeamType of getSponsorTeamTypes()) {
    formGroup.addControl(sponsorTeamType, new FormControl(false));
  }
}

export function getSponsorTeamTypes(): string[] {
  return Object.keys(ESponsorTeamType);
}
