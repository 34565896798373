import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { ActivatedRoute, Router } from '@angular/router';
import { SponsorRunSponsorService } from '../../services/sponsor-run-sponsor.service';

@Component({
  selector: 'app-event-link',
  templateUrl: './event-link.component.html',
  styleUrls: ['./event-link.component.scss']
})
export class EventLinkComponent implements OnInit {

  constructor(private route: ActivatedRoute, private sponsorService: SponsorRunSponsorService, private router: Router) { }

  ngOnInit(): void {
    const link = this.route.snapshot.paramMap.get('id');
    this.sponsorService.getEventFromInviteLink(link).pipe(first()).subscribe(result =>{
      this.router.navigate([Consts.signUpRoute], {queryParams: {'tab': 'participate', 'eventId': result.event.id}});
    });
  }

}
