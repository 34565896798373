<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{'user.events' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="col-md-9">
      <div class="btn-group btn-group-sm" role="group">
        <button class="btn" [ngClass]="{'btn-primary': pastOrFuture.Past === selectedPastOrFuture, 'btn-link': pastOrFuture.Future === selectedPastOrFuture}" (click)="togglePastOrFutureEvents(pastOrFuture.Past)">
          {{'user.pastEvents' | translate}}
        </button>
        <button class="btn" [ngClass]="{'btn-primary': pastOrFuture.Future === selectedPastOrFuture, 'btn-link': pastOrFuture.Past === selectedPastOrFuture}" (click)="togglePastOrFutureEvents(pastOrFuture.Future)">
          {{'user.futureEvents' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="col-md-9">
      <div *ngIf="(breakObserverService.tableSmaller$ | async).matches">
        <table class="mobile-list-table mb-5" *ngFor="let event of (events$ | async), let i = index">
          <tbody [class]="i % 2 == 0 ? 'even' : ''">
          <tr>
            <td>{{'eventTable.headers.startDate' | translate}}</td>
            <td>{{event.startDate | date: utilService.getDateFormat()}}</td>
          </tr>
          <tr>
            <td>{{'eventTable.headers.endDate' | translate}}</td>
            <td>{{event.endDate | date: utilService.getDateFormat()}}</td>
          </tr>
          <tr>
            <td>{{'eventTable.headers.event' | translate}}</td>
            <td>{{event.name}}</td>
          </tr>
          <tr>
            <td>{{'eventTable.headers.place' | translate}}</td>
            <td>{{event.company?.address?.postalCode}} {{event.company?.address?.city}}</td>
          </tr>
          <tr>
            <td>{{'eventTable.headers.company' | translate}}</td>
            <td>{{event.company.name}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-4">
              <div>
                <a routerLink="{{editSignUp(event.id)}}" *ngIf="!utilService.dateBeforeNow(event.startDate)">
                  {{'user.editSignup' | translate}}
                </a>
                <span *ngIf="utilService.dateBeforeNow(event.startDate)">
              {{'user.editSignupNotPossible' | translate}}
            </span>
              </div>
              <div>
                <a routerLink="{{goToEvent(event.id)}}" class="font-itali">
                  {{'global.goToEvent' | translate}}
                </a>
              </div>
              <div>
                <a [routerLink]="registerActivity(event.id)">
                  {{'global.registerActivity' | translate}}
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <table *ngIf="!(breakObserverService.tableSmaller$ | async).matches">
        <thead>
        <tr>
          <td class="td-date">{{'eventTable.headers.startDate' | translate}}</td>
          <td class="td-date">{{'eventTable.headers.endDate' | translate}}</td>
          <td class="td-name">{{'eventTable.headers.event' | translate}}</td>
          <td class="td-rest">{{'eventTable.headers.place' | translate}}</td>
          <td class="td-rest">{{'eventTable.headers.company' | translate}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let event of (events$ | async)">
          <td class="td-date">{{event.startDate | date: utilService.getDateFormat()}}</td>
          <td class="td-date">{{event.endDate | date: utilService.getDateFormat()}}</td>
          <td class="td-name">{{event.name}}</td>
          <td class="td-rest">{{event.company?.address?.postalCode}} {{event.company?.address?.city}}</td>
          <td class="td-rest">{{event.company.name}}</td>
          <td class="text-end td-link">
            <a routerLink="{{editSignUp(event.id)}}" *ngIf="!utilService.dateBeforeNow(event.startDate)">
              {{'user.editSignup' | translate}}
            </a>
            <span *ngIf="utilService.dateBeforeNow(event.startDate)">
              {{'user.editSignupNotPossible' | translate}}
            </span>
          </td>
          <td class="text-end td-link">
            <a routerLink="{{goToEvent(event.id)}}">
              {{'global.goToEvent' | translate}}
            </a>
          </td>
          <td class="text-end td-link">
            <a [routerLink]="registerActivity(event.id)">
              {{'global.registerActivity' | translate}}
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
