<div class="container-fluid bigger-body-font-size">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{'user.info' | translate}}</h1>
      </div>
    </div>
  </div>
  <div>
    <div class="row mt-5 mb-5">
      <div class="d-flex justify-content-center">
        <div *ngIf="user$ | async as user">
          <table class="info-table">
            <tr>
              <td>{{'user.name' | translate}}:</td>
              <td>{{user.firstName}} {{user.lastName}}</td>
            </tr>
            <tr>
              <td>{{'user.phone' | translate}}:</td>
              <td>{{user.phoneNumber}}</td>
            </tr>
            <tr>
              <td>{{'user.email' | translate}}:</td>
              <td>{{user.email}}</td>
            </tr>
            <tr>
              <td>{{'user.address' | translate}}:</td>
              <td>{{user.address?.streetOne}} {{user.address?.streetTwo}}</td>
            </tr>
            <tr>
              <td>{{'user.postalCode' | translate}}:</td>
              <td>{{user.address?.postalCode}}</td>
            </tr>
            <tr>
              <td>{{'user.city' | translate}}:</td>
              <td>{{user.address?.city}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="d-flex justify-content-center">
      <div>
        <div>
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [routerLink]="editUserInfo()">
            {{'user.editUserInfo' | translate}}
          </button>
        </div>
        <div class="mt-3">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [routerLink]="changePassword()">
            {{'user.changePassword' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
