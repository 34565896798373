<div *ngIf="event">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header ">{{'tab-event-register-activity.title' | translate }}</div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mt-4 mb-4">
      <app-sponsor-register-activity [sponsorRunUser]="sponsorRunUser" [event]="event" (formValid)="formValid($event)" (form)="setForm($event)"></app-sponsor-register-activity>
      <div class="mt-5">
        <div class="mt-5">
          <button class="content-button button-text text-uppercase font-italic" type="button" name="register" (click)="registerUserSponsorActivity()">
            {{'registerActivity.submit' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
