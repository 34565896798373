<div class="container-fluid font-size">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{getHeaderText() | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="companyForm" (ngSubmit)="updateCompany()">
        <div class="form-group">
          <label class="form-label" for="cvr">{{'global.cvr' | translate}}:*</label>
          <input id="cvr" class="form-control content-input" type="text" formControlName="cvr" required max="99999999" min="1" maxlength="8"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="name">{{'createEditCompany.name' | translate}}*</label>
          <input id="name" class="form-control content-input" formControlName="name" required/>
        </div>
        <div formGroupName="address">
          <div class="form-group">
            <label class="form-label" for="streetOne">{{'createEditCompany.address1' | translate}}*</label>
            <input id="streetOne" class="form-control content-input" type="text" formControlName="streetOne" required/>
          </div>
          <div class="form-group">
            <label class="form-label" for="streetTwo">{{'createEditCompany.address2' | translate}}</label>
            <input id="streetTwo" class="form-control content-input" type="text" formControlName="streetTwo"/>
          </div>
          <div class="form-group">
            <label class="form-label" for="postalCode">{{'createEditCompany.postalCodeAndCity' | translate}}*</label>
            <div class="d-flex content-input doubleContainer">
              <input id="postalCode" class="form-control postalCode" type="text" min="0" maxlength="4" required formControlName="postalCode"/>
              <input id="city" class="form-control city" type="text" required formControlName="city"/>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="!creating">
          <label class="form-label" for="primaryContactUserId">{{'createEditCompany.contactPerson' | translate}}*</label>
          <select id="primaryContactUserId" class="form-select content-input" formControlName="primaryContactUserId" required>
            <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
            <option *ngFor="let user of adminUsers" value="{{user.id}}">{{user.firstName}} {{user.lastName}}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label" for="phoneNumber">{{'createEditCompany.phone' | translate}}*</label>
          <input id="phoneNumber" class="form-control content-input" type="text" formControlName="phoneNumber" required pattern="[0-9]{8}" maxlength="8"/>
        </div>
        <div class="form-group">
          <label class="form-label" for="email">{{'createEditCompany.email' | translate}}*</label>
          <input id="email" class="form-control content-input" type="email" formControlName="email" required/>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update">
            {{getButtonText() | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
