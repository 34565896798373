import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UsersService, EventShopUser } from 'shared';
import { Observable } from 'rxjs';
import { AdminService } from 'shared';

@Component({
  selector: 'app-tab-admin-users',
  templateUrl: './tab-admin-users.component.html',
  styleUrls: ['./tab-admin-users.component.scss']
})
export class TabAdminUsersComponent implements OnInit {
  public users: Observable<EventShopUser[]>
  @Output()
  public userIdEvent = new EventEmitter<string>();

  constructor(private adminService: AdminService) {
    this.users = this.adminService.getAllUsers();
  }

  ngOnInit(): void {
  }

  public userSelected(userId: string) {
    this.userIdEvent.emit(userId);
  }

}
