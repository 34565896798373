import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { SponsorRunUserSummaryDto } from '../../models/SponsorRunUserSummaryDto';
import { CustomTranslationService, PaginationBaseComponent } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import lodash from 'lodash';
import { PodiumComponent } from '../podium/podium.component';

@Component({
  selector: 'app-tab-event-sponsor-run-users',
  templateUrl: './tab-event-sponsor-run-users.component.html',
  styleUrls: ['./tab-event-sponsor-run-users.component.scss']
})
export class TabEventSponsorRunUsersComponent extends PaginationBaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  public sponsorRunUserSummaries: SponsorRunUserSummaryDto[];

  private sortedSponsorRunUserSummaries: SponsorRunUserSummaryDto[];
  public shownSponsorRunUserSummaries: SponsorRunUserSummaryDto[];
  public sorting = '0';

  public indexOffset = 1;

  @ViewChild(PodiumComponent)
  private podiumComponent: PodiumComponent;

  constructor(protected router: Router, protected activatedRoute: ActivatedRoute, public customTranslationService: CustomTranslationService) {
    super(router, activatedRoute)
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes): void {
    if (changes["sponsorRunUserSummaries"] && this.sponsorRunUserSummaries) {
      this.sortList();
      this.totalNumberOfPages = Math.ceil(this.sponsorRunUserSummaries.length / this.itemsPerPage);
      this.updateItems(this.page);

      this.setSums();
    }
  }

  ngAfterViewInit(): void {
    if (this.sponsorRunUserSummaries && this.podiumComponent) {
      this.setSums();
    }
  }

  private setSums() {
    const sums = lodash.sortBy(this.sponsorRunUserSummaries, [function (o) {
      return (o.totalSponsorMoney + o.totalUserMoney);
    }]);
    lodash.reverse(sums)
    sums.slice(0, 3);

    this.podiumComponent?.setSponsorRunUser(sums);
  }

  protected updateItems(page: number) {
    this.page = page;
    this.shownSponsorRunUserSummaries = this.sortedSponsorRunUserSummaries?.slice((page - 1) * this.itemsPerPage, page * this.itemsPerPage);
    this.indexOffset = (page - 1) * this.itemsPerPage;
  }

  public async goToPage(page: number) {
    this.updateItems(page);
  }

  public sortList() {
    switch (this.sorting) {
      case '0':
        this.sortedSponsorRunUserSummaries = lodash.reverse(lodash.sortBy(this.sponsorRunUserSummaries, [function (o) {
          return (o.totalSponsorMoney + o.totalUserMoneyToBePayed);
        }]));
        break;
      case '1':
        this.sortedSponsorRunUserSummaries = lodash.reverse(lodash.sortBy(this.sponsorRunUserSummaries, [function (o) {
          return o.totalUnitSum;
        }]));
        break;
      case '2':
        this.sortedSponsorRunUserSummaries = lodash.sortBy(this.sponsorRunUserSummaries, [function (o) {
          return o.name;
        }]);
        break;
    }

    this.updateItems(1);
  }
}
