<div class="container-fluid bigger-body-font-size">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{'companyAdmin.companies' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="col-md-9">
      <div *ngIf="(breakObserverService.tableSmaller$ | async).matches">
        <table class="mobile-list-table mb-5" *ngFor="let club of (companies | async), let i = index">
          <tbody [class]="i % 2 == 0 ? 'even' : ''">
          <tr>
            <td class="td-date">{{'companyTable.headers.company' | translate}}</td>
            <td class="td-name">{{club.name}}</td>
          </tr>
          <tr>
            <td class="td-name">{{'companyTable.headers.place' | translate}}</td>
            <td class="td-rest">{{club.address?.streetOne}} {{club.address?.streetTwo}} {{club.address?.postalCode}} {{club.address?.city}}</td>
          </tr>
          <tr>
            <td class="td-rest">{{'companyTable.headers.validated' | translate}}</td>
            <td class="td-rest">{{(club.validated ? 'global.validated' : 'global.notValidated') | translate}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-4">
              <a [routerLink]="editCompany(club.id)">
                {{'companyAdmin.editClub' | translate}}
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <table *ngIf="!(breakObserverService.tableSmaller$ | async).matches">
        <thead>
        <tr>
          <td class="td-date">{{'companyTable.headers.company' | translate}}</td>
          <td class="td-name">{{'companyTable.headers.place' | translate}}</td>
          <td class="td-rest">{{'companyTable.headers.validated' | translate}}</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let club of (companies | async)">
          <td class="td-name">{{club.name}}</td>
          <td class="td-rest">{{club.address?.streetOne}} {{club.address?.streetTwo}} {{club.address?.postalCode}} {{club.address?.city}}</td>
          <td class="td-rest">{{(club.validated ? 'global.validated' : 'global.notValidated') | translate}}</td>
          <td class="text-end td-link">
            <a [routerLink]="editCompany(club.id)">
              {{'companyAdmin.editClub' | translate}}
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-12 justify-content-center d-flex">
      <div>
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="update"  [routerLink]="addCompany()">
          {{'companyAdmin.addClub' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
