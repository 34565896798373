<div class="col-md-12">
  <div class="container-fluid ps-0 pe-0">
    <div class="row tabs-header-container">
      <div class="tabs-header align-items-center">{{'admin.users' | translate }}</div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="tabs-content mt-4 mb-4">
      <table class="tabs-table">
        <tr *ngFor="let user of (users | async)">
          <td>
            {{user.firstName}} {{user.lastName}}
          </td>
          <td>
            {{user.username}}
          </td>
          <td>
            <a (click)="userSelected(user.id)">
              {{'admin.goToUser' | translate}}
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
