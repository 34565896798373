import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService, TabComponent, TabsComponent } from 'shared';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Consts } from '../../consts';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewChecked, OnDestroy {
  public tabs: TabComponent[]

  @ViewChildren(TabComponent)
  private tabComponents: QueryList<TabComponent>;

  @ViewChild(TabsComponent)
  public tabsParent: TabsComponent;

  public activeTab: TabComponent;
  public stepIndex: number = 0;

  private stepIndexes: { [tab: string]: number } = {};

  private subscription = new Subscription();

  constructor(private changeDetectorRef: ChangeDetectorRef, public authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.tabs = this.tabComponents.toArray();
    for (let tab of this.tabs) {
      this.stepIndexes[tab.name] = 0;
      this.subscription.add(tab.active.subscribe(active => {
        if (active) {
          this.activeTab = tab;
          this.stepIndex = this.stepIndexes[this.activeTab.name];
        }
      }))
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getActiveTab(): TabComponent {
    return this.activeTab;
  }

  public stepChanged(index: number){
    this.stepIndex = this.stepIndexes[this.activeTab.name] = index;
  }

  public createUser() {
    return this.router.navigate([Consts.userSignUpRoute])

  }
}
