<div *ngIf="company">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header">{{'tab-company-data.title' | translate }}</div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mt-4 mb-4">
      <table class="tabs-table">
        <tr>
          <td>{{'tab-company-data.companyName' | translate}}:</td>
          <td>{{company.name}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.contactPerson' | translate}}:</td>
          <td>{{company.primaryContactDisplayName}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.contactPhone' | translate}}:</td>
          <td>{{company.phoneNumber}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.contactEmail' | translate}}:</td>
          <td>{{company.email}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.address1' | translate}}:</td>
          <td>{{company.address?.streetOne}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.address1' | translate}}:</td>
          <td>{{company.address?.streetTwo}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.postalCodeAndCity' | translate}}:</td>
          <td>{{company.address?.postalCode}} {{company.address?.city}}</td>
        </tr>
        <tr>
          <td>{{'tab-company-data.username' | translate}}:</td>
          <td>{{this.user?.firstName}} {{this.user?.lastName}}</td>
        </tr>
      </table>
      <div class="mt-4" *ngIf="company.isAdmin">
        <a [routerLink]="editClubRoute()">{{'global.edit' | translate}}</a>
      </div>
    </div>
  </div>
</div>
