<div class="container-fluid footer-container">
  <div class="row pt-5">
    <div class="col-md-4 d-flex justify-content-center align-items-center footer-col">
      <img class="foot-logo" src="assets/footer/logo.png">
    </div>
    <div class="col-md-4 footer-col">
      <div class="footer-follow text-center">{{'footer.follow' | translate}}</div>
      <!--      <div class="d-flex mt-3" style="justify-content: space-evenly">-->
      <div class="d-flex mt-3" [ngClass]="(breakpointObserverService.max992$ | async)?.matches ? 'justify-content-center' : 'justify-content-evenly'">
        <div [ngClass]="(breakpointObserverService.max992$ | async)?.matches ? 'me-3' : ''">
          <a href="https://www.facebook.com/JoinAndMove" target="_blank"><img class="footer-social-icon" src="assets/footer/facebook.png"/></a>
        </div>
        <!--        <div>-->
        <!--          <a href="https://www.instagram.com" target="_blank"><img class="footer-social-icon" src="assets/footer/instagram.png"/></a>-->
        <!--        </div>-->
        <!--        <div>-->
        <!--          <a href="https://www.linkedin.com" target="_blank"><img class="footer-social-icon" src="assets/footer/linkedIn.png"/></a>-->
        <!--        </div>-->
        <div>
          <a href="https://www.youtube.com/channel/UCIfzsGXF4v-X6hDelx5VRrg" target="_blank"><img class="footer-social-icon" src="assets/footer/youtube.png"/></a>
        </div>
        <!--        <div>-->
        <!--          <a href="https://www.snapchat.com" target="_blank"><img class="footer-social-icon" src="assets/footer/snapchat.png"/></a>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="col-md-4 footer-col mb-0">
      <div class="footer-links">
        <div class="footer-link-container">
          <div class="footer-text">{{'footer.contact' | translate}}</div>
          <div>
            <a class="footer-link" href="mailto:info@joinandmove.dk">info@joinandmove.dk</a>
          </div>
          <div>
            <a class="footer-link" href="tel:+4522821843">+45 22 82 18 43</a>
          </div>
        </div>
        <div class="footer-link-container">
          <div class="footer-text">{{'footer.support' | translate}}</div>
          <div>
            <a class="footer-link" href="mailto:support@joinandmove.dk">support@joinandmove.dk</a>
          </div>
        </div>
        <div class="footer-link-container mt-4">
          <div>
            <a class="footer-link" routerLink="{{getGdprLink()}}">{{'gdpr.title' | translate}}</a>
          </div>
        </div>
        <div class="footer-link-container">
          <div>
            <a class="footer-link" routerLink="{{getTermsOfTrade()}}">{{'footer.termsOfTrade' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    <div class="footer-copy mt-4 mb-4">
      <div>
        CVR: 41961074 - Paradiset 10, 8585 Glesborg
      </div>
      <div>
        {{'footer.copy'| translate}} {{getToday() | date: 'y' : 'IST' : customTranslationService.getLang() | titlecase}}
      </div>
      <div class="d-flex justify-content-center">
        <img class="payment-logos" src="assets/footer/payment-logos/logoer - V-MC.png">
      </div>
    </div>
  </div>
</div>
