import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SponsorEvent } from '../../models/sponsor-event';
import { SponsorRunUser } from '../../models/sponsor-run-user';
import { FormComponent } from '../form-component/form.component';

@Component({
  selector: 'app-sponsor-register-activity',
  templateUrl: './register-activity.component.html',
  styleUrls: ['./register-activity.component.scss']
})
export class RegisterActivityComponent extends FormComponent implements OnInit, OnChanges {
  public registerActivityForm = new FormGroup({
    unitCount: new FormControl(undefined, [Validators.required, Validators.min(0.01)]),
    activityTrackerLink: new FormControl(undefined),
    companyEventId: new FormControl(undefined, [Validators.required]),
    unitType: new FormControl(),
    sponsorParticipationType: new FormControl(),
    combination: new FormControl(-1,)
  });
  @Input()
  public event: SponsorEvent;
  @Input()
  public sponsorRunUser: SponsorRunUser;

  public showSelectedCombinationError = false;

  constructor() {
    super();
    this.setForm(this.registerActivityForm);
  }

  ngOnInit(): void {
    this.form.emit(this.registerActivityForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["event"] && this.event) {
      this.registerActivityForm.controls.companyEventId.setValue(this.event.id);
    }
  }

  // public registerUserInClubEvent() {
  //   const request = {maxPayment: 50, paymentPerUnit: 1, eventId: 1} as SignUpUserRequest;
  //   this.sponsorService.signUpUser(request).subscribe(result => {
  //     console.error(result);
  //   }, error => console.error(error));
  // }
  //

  public unitCountBlur() {
    const parsedValue = Number(this.registerActivityForm.controls.unitCount.value).toFixed(2);
    this.registerActivityForm.controls.unitCount.setValue(parsedValue);
  }

  public customValidated(): boolean {
    this.showSelectedCombinationError = this.registerActivityForm.controls.combination.value === -1;
    return this.registerActivityForm.controls.combination.value !== -1
  }

  public combinationSelected() {
    this.showSelectedCombinationError = false;
    const selected = this.sponsorRunUser.sponsorRunUserSignUpCombinations[this.registerActivityForm.controls.combination.value];
    this.registerActivityForm.controls.unitType.setValue(selected.unitType);
    this.registerActivityForm.controls.sponsorParticipationType.setValue(selected.sponsorParticipationType);
  }
}
