<div *ngIf="shownSponsorRunUserSummaries">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header">{{'tab-event-sponsor-run-users.title' | translate }}</div>
    <div class="podium-container-header">
      <app-podium></app-podium>
    </div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mt-4 mb-4">
      <div>
        <form class="mx-auto">
          <div class="input-group mb-3">
            <div class="form-group">
              <label class="form-label" for="sorting">
                {{'tab-event-sponsor-run-users.sorting' | translate}}
              </label>
              <select id="sorting" class="form-select content-input" name="sorting" required [(ngModel)]="sorting" (change)="sortList()">
                <option value=0>{{'tab-event-sponsor-run-users.money' | translate}}</option>
                <option value=1>{{'tab-event-sponsor-run-users.activity' | translate}}</option>
                <option value=2>{{'tab-event-sponsors.sortedNames' | translate}}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="table-responsive">
        <table class="table tabs-table-rankings">
          <thead>
          <tr>
            <td></td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.name' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.participationType' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.moneyPrUnit' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.activity' | translate}}</td>
            <td>{{'tab-event-sponsor-run-users.tableHeaders.total' | translate}}</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sponsorRunUserSummary of shownSponsorRunUserSummaries, let i = index">
            <td>{{indexOffset + i + 1}}</td>
            <td *ngIf="sponsorRunUserSummary.visible">{{sponsorRunUserSummary.name}}</td>
            <td *ngIf="!sponsorRunUserSummary.visible">{{'global.anonymous' | translate}}</td>
            <td>
              <div *ngIf="sponsorRunUserSummary.visible">
                <div *ngFor="let userUnitTypeSummaries of sponsorRunUserSummary.userUnitTypeSummaries">
                  {{'global.sponsorParticipateTypeItem.' + userUnitTypeSummaries.sponsorParticipationType | translate}}
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="sponsorRunUserSummary.visible">
                <div *ngFor="let userUnitTypeSummaries of sponsorRunUserSummary.userUnitTypeSummaries">
                  {{(userUnitTypeSummaries.sponsorMoneyPerUnit + userUnitTypeSummaries.userMoneyPerUnit)| number :'':customTranslationService.getLang()}}kr
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="sponsorRunUserSummary.visible">
                <div *ngFor="let userUnitTypeSummaries of sponsorRunUserSummary.userUnitTypeSummaries">
                  {{userUnitTypeSummaries.totalUnitCount| number :'':customTranslationService.getLang()}} {{'global.unitTypeItem.' + userUnitTypeSummaries.unitType | translate}}
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="sponsorRunUserSummary.visible">
                {{(sponsorRunUserSummary.totalSponsorMoney + sponsorRunUserSummary.totalUserMoneyToBePayed)| number :'':customTranslationService.getLang()}}kr
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="goToPage($event)" (nextPageEvent)="goToPage($event)" (prevPageEvent)="goToPage($event)"></eventShop-pagination-component>
    </div>
  </div>
</div>
