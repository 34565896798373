import { FormControl, FormGroup } from '@angular/forms';

export enum ESponsorParticipateType {
  Cycling = 'Cycling',
  Run = 'Run',
  Walk = 'Walk',
  Rowing = 'Rowing',
  Swimming = 'Swimming',
  Rolling = 'Rolling',
  BallActivity = 'BallActivity'
}

export function getSponsorParticipateTypes(): string[] {
  return Object.keys(ESponsorParticipateType);
}

export function setDynamicESponsorParticipateTypeFormValues(formGroup: FormGroup) {
  for (const sponsorParticipateTypes of getSponsorParticipateTypes()) {
    formGroup.addControl(sponsorParticipateTypes, new FormControl(false));
  }
}
