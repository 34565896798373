<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'editUser.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="userForm" (ngSubmit)="updateUserInfo()">
        <div class="form-group">
          <label class="form-label" for="firstName">{{'editUser.firstName' | translate}}*</label>
          <input id="firstName" class="form-control content-input" formControlName="firstName" required/>
        </div>
        <div class="form-group">
          <label class="form-label" for="lastName">{{'editUser.lastName' | translate}}*</label>
          <input id="lastName" class="form-control content-input" formControlName="lastName" required/>
        </div>
        <div class="form-group">
          <label class="form-label" for="email">{{'editUser.email' | translate}}*</label>
          <input id="email" class="form-control content-input" formControlName="email" required/>
        </div>
        <div class="form-group">
          <label class="form-label" for="phoneNumber">{{'editUser.phoneNumber' | translate}}*</label>
          <input id="phoneNumber" class="form-control content-input" formControlName="phoneNumber" type="text" maxlength="8" required/>
        </div>
        <div formGroupName="address">
          <div class="form-group">
            <label class="form-label" for="streetOne">{{'editUser.address1' | translate}}*</label>
            <input id="streetOne" class="form-control content-input" type="text" formControlName="streetOne" required/>
          </div>
          <div class="form-group">
            <label class="form-label" for="streetTwo">{{'editUser.address2' | translate}}</label>
            <input id="streetTwo" class="form-control content-input" type="text" formControlName="streetTwo"/>
          </div>
          <div class="form-group">
            <label class="form-label" for="postalCode">{{'editUser.postalCodeAndCity' | translate}}*</label>
            <div class="d-flex content-input doubleContainer">
              <input id="postalCode" class="form-control postalCode" type="text" min="0" maxlength="4" required formControlName="postalCode"/>
              <input id="city" class="form-control city" type="text" required formControlName="city"/>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update">
            {{'editUser.update' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
