import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangePassword, UsersService } from 'shared';
import { Router } from '@angular/router';
import { FormViewComponent } from '../form-view/form-view.component';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { PasswordValidation, UtilService } from '../../services/util.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent extends FormViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public changePasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(undefined, [Validators.required]),
    newPassword: new FormControl(undefined, [Validators.required]),
    repeatPassword: new FormControl(undefined, [Validators.required])
  });

  public passwordValidation: PasswordValidation;

  constructor(private usersService: UsersService, private router: Router, protected elementRef: ElementRef, private utilService: UtilService, private modalService: ModalService) {
    super(elementRef);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async ngAfterViewInit() {
    super.ngAfterViewChecked();
  }

  public changePassword() {
    if (this.changePasswordForm.valid && this.changePasswordForm.controls.newPassword.value == this.changePasswordForm.controls.repeatPassword.value) {
      this.usersService.changePassword(this.changePasswordForm.value as ChangePassword).pipe(first())
        .subscribe(_ => this.router.navigate([Consts.userInfoRoute]), _ => this.modalService.showModal('modal.error', 'modal.wrongPassword'));
    } else {
      if (this.changePasswordForm.controls.newPassword.value != this.changePasswordForm.controls.repeatPassword.value) {
        this.changePasswordForm.controls.newPassword.setErrors({});
        this.changePasswordForm.controls.repeatPassword.setErrors({})
      }
      this.changePasswordForm.markAllAsTouched();
      this.showError();
    }
  }

  public validatePassword() {
    this.passwordValidation = this.utilService.validatePassword(this.changePasswordForm.controls.newPassword.value);
  }

  public validateRepeatedPassword() {
    if (this.changePasswordForm.controls.newPassword.value != this.changePasswordForm.controls.repeatPassword.value) {
      this.changePasswordForm.controls.repeatPassword.setErrors({});
    } else {
      this.changePasswordForm.controls.repeatPassword.setErrors(null);
    }
  }
}
