import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateSponsorEventRequest } from '../../models/create-sponsor-event-request';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ESponsorParticipateType, getSponsorParticipateTypes } from '../../enums/esponsor-participate-type.enum';
import { ESponsorTeamType, getSponsorTeamTypes } from '../../enums/esponsor-team-type.enum';
import { AuthService, BreakpointObserverService, CompaniesService, Company } from 'shared';
import * as moment from 'moment';
import { Consts } from '../../consts';
import { FormViewComponent } from '../form-view/form-view.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilService } from '../../services/util.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-company-create-event',
  templateUrl: './company-create-event.component.html',
  styleUrls: ['./company-create-event.component.scss']
})
export class CompanyCreateEventComponent extends FormViewComponent implements OnInit, OnDestroy, AfterContentInit {

  @Output()
  public stepChanged = new EventEmitter<number>();

  public createEventForm = new FormGroup({
    companyId: new FormControl(-1, [Validators.required, Validators.min(0)])
  })

  public termsGdprForm = new FormGroup({
    gdpr: new FormControl(undefined, [Validators.requiredTrue]),
    terms: new FormControl(undefined, [Validators.requiredTrue])
  })

  public validate$ = new BehaviorSubject(false);

  public adminsCompanies: Company[];
  public selectedCompany: Company;

  private eventFormValidValue = false;
  public eventForm: FormGroup;

  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private sponsorService: SponsorRunService,
              private authService: AuthService,
              private companiesService: CompaniesService,
              protected elementRef: ElementRef,
              public utilService: UtilService,
              public breakpointObserverService: BreakpointObserverService) {
    super(elementRef);
    this.subscriptions.add(this.authService.signedInChanged$.subscribe(signedIn => {
      if (signedIn) {
        this.companiesService.getAdminsCompanies().pipe(first())
          .subscribe(companies => this.adminsCompanies = companies);
      }
    }));
  }

  ngOnInit(): void {
    this.createEventForm.markAllAsTouched();
    this.termsGdprForm.markAllAsTouched();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngAfterContentInit() {
    this.validate$.next(true);
  }

  public valid(): boolean {
    return this.createEventForm.valid && this.termsGdprForm.valid && this.eventFormValidValue;
  }

  public createEvent() {
    if (this.valid()) {
      const createSponsorEventRequest = this.eventForm.value as CreateSponsorEventRequest;
      createSponsorEventRequest.companyId = this.createEventForm.controls.companyId.value;
      createSponsorEventRequest.sponsorParticipateTypes = [];
      createSponsorEventRequest.sponsorTeamTypes = [];
      createSponsorEventRequest.startDate = moment(`${this.eventForm.controls.startDate.value} ${this.eventForm.controls.startTime.value}`).toDate();
      createSponsorEventRequest.endDate = moment(`${this.eventForm.controls.endDate.value} ${this.eventForm.controls.endTime.value}`).toDate();

      for (const sponsorParticipateTypes of getSponsorParticipateTypes()) {
        if (this.eventForm.controls[sponsorParticipateTypes].value) {
          if (this.eventForm.controls[sponsorParticipateTypes].value) {
            createSponsorEventRequest.sponsorParticipateTypes.push(sponsorParticipateTypes as ESponsorParticipateType);
          }
        }
      }

      for (const sponsorTeamType of getSponsorTeamTypes()) {
        if (this.eventForm.controls[sponsorTeamType].value) {
          if (this.eventForm.controls[sponsorTeamType].value) {
            createSponsorEventRequest.sponsorTeamTypes.push(sponsorTeamType as ESponsorTeamType);
          }
        }
      }

      this.sponsorService.createSponsorEvent(createSponsorEventRequest).pipe(first()).subscribe(result => {
        this.router.navigate([Consts.signUpSucceededEventRoute, result]);
      });
    }
  }

  public companySelected() {
    this.selectedCompany = this.adminsCompanies.find(company => company.id == this.createEventForm.controls.companyId.value);
  }

  public getSummarySponsorParticipateTypes(): string[] {
    const types = [];
    for (const sponsorParticipateType of getSponsorParticipateTypes()) {
      if (this.eventForm.controls[sponsorParticipateType].value) {
        types.push(sponsorParticipateType);
      }
    }
    return types;
  }

  public getSummarySponsorTeamTypes(): string[] {
    const types = [];
    for (const sponsorTeamType of getSponsorTeamTypes()) {
      if (this.eventForm.controls[sponsorTeamType].value) {
        types.push(sponsorTeamType);
      }
    }
    return types;
  }

  public getGdprRoute(): string {
    return `/${Consts.gdprRoute}`;
  }

  public getTermsRoute(): string {
    return `/${Consts.eventTermsRoute}`;
  }

  public eventFormValid(valid: boolean) {
    this.eventFormValidValue = valid;
  }

  public setEventForm(eventForm: FormGroup) {
    this.eventForm = eventForm;
    this.subscriptions.add(this.eventForm.valueChanges.subscribe(_ => this.validate$.next(true)));
  }

  public getCreateEditCompany() {
    return `/${Consts.createEditClubRoute}`;
  }

  public selectionChanged(event: StepperSelectionEvent){
    this.stepChanged.next(event.selectedIndex);
  }
}
