<div class="col-md-12" xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid ps-0 pe-0">
    <div class="row tabs-header-container">
      <div class="tabs-header align-items-center">{{'admin.company.title' | translate}}</div>
    </div>
  </div>
  <div class="row mt-5">
    <tr class="tabs-content mt-4 mb-4">
      <table class="tabs-table" *ngIf="(user$ | async) as user">
        <tr>
          <td>{{'admin.user.name' | translate}}:</td>
          <td>{{user.firstName}} {{user.lastName}}</td>
        </tr>
        <tr>
          <td>{{'admin.user.username' | translate}}:</td>
          <td>{{user.username}}</td>
        </tr>
      </table>
      <div class="row mt-5">
        <button class="content-button button-text text-uppercase font-italic" (click)="validate()">
          {{'admin.user.reset' | translate}}
        </button>
      </div>
  </div>
</div>
