<div class="container-fluid bigger-body-font-size" xmlns="http://www.w3.org/1999/html">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <div class="item-header">
        <h1 class="big-header-black">{{'payment.title' | translate}}</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center" *ngIf="paymentSummeryResponse">
    <div class="col-md-8" *ngFor="let summeryEvent of paymentSummeryResponse.summeryEvents">
      <div class="event-info">
        <div class="event-name text-center">
          <span>{{summeryEvent.eventName}}</span>
        </div>
        <div class="text-center">
          <span class="company-name">{{summeryEvent.eventCompanyName}}</span></div>
        <div class="text-right event-date">
          <span class="event-date">{{'payment.to' | translate}} {{summeryEvent.to | date:utilService.getDateFormat()}}</span>
        </div>
        <div class="text-right event-date">
          <span class="event-date">{{'payment.from' | translate}} {{summeryEvent.from | date:utilService.getDateFormat()}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 ps-0 pe-0">
          <table class="table-payment">
            <thead>
            <tr>
              <td>{{'payment.tableHeaders.name' | translate}}</td>
              <td>{{'payment.tableHeaders.activity' | translate}}</td>
              <td>{{'payment.tableHeaders.sponsored' | translate}}</td>
              <td>{{'payment.tableHeaders.registered' | translate}}</td>
              <td>{{'payment.tableHeaders.payment' | translate}}</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let eventSignup of summeryEvent.eventSignups">
              <td>{{eventSignup.name}}</td>
              <td>{{'global.sponsorParticipateTypeItem.'+eventSignup.sponsorParticipationType | translate}}</td>
              <td>{{eventSignup.paymentPerUnit | number:'':customTranslationService.getLang()}} kr/{{'global.unitTypeItem.' + eventSignup.unitType | translate}}</td>
              <td>{{eventSignup.unitCount | number:'':customTranslationService.getLang()}} {{'global.unitTypeItem.' + eventSignup.unitType | translate}}</td>
              <td>{{eventSignup.sum | number:'':customTranslationService.getLang()}} kr</td>
            </tr>
            <tr class="sum-event mt-1">
              <td colspan="4">{{'payment.totalEvent' | translate:eventNameParam()}}</td>
              <td class="event-total">{{summeryEvent.sum  | number:'':customTranslationService.getLang()}} kr</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-md-8 ps-0 pe-0">
      <table class="total-payment">
        <tbody>
        <tr class="tr-border">
          <td colspan="4">{{'payment.total' | translate}}</td>
          <td>
            <div *ngIf="paymentSummeryResponse">{{paymentSummeryResponse.totalAmount  | number:'':customTranslationService.getLang()}} kr</div>
            <div class="total-number"></div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <div class="col-md-8">
      <div class="form-group d-flex justify-content-end mt-5">
        <div class="form-check mt-3">
          <input class="form-check-input" type="checkbox" value="" id="agree" [(ngModel)]="termsOfTradeChecked">
          <label class="form-check-label" for="agree">
            {{'payment.agree' | translate}}
            <a href="{{getTermsOfTrade()}}" target="_blank">{{'payment.termsOfTrade' | translate}}</a>
          </label>
        </div>
      </div>
      <div class="form-group d-flex justify-content-end mt-5">
        <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" (click)="pay()" [disabled]="!termsOfTradeChecked">
          {{'payment.button' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
