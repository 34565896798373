import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { AuthService, CompaniesService, Company, EventShopUser, EditCompany, CreateCompany } from 'shared';
import { FormViewComponent } from '../form-view/form-view.component';

@Component({
  selector: 'app-create-edit-company',
  templateUrl: './create-edit-company.component.html',
  styleUrls: ['./create-edit-company.component.scss']
})
export class CreateEditCompanyComponent extends FormViewComponent implements OnInit, OnDestroy {
  public companyForm: FormGroup = new FormGroup({
    cvr: new FormControl(undefined, [Validators.required, Validators.min(1), Validators.max(99999999), Validators.maxLength(8)]),
    name: new FormControl(undefined, [Validators.required]),
    address: new FormGroup({
      streetOne: new FormControl(undefined, [Validators.required]),
      streetTwo: new FormControl(undefined),
      postalCode: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{4}")]),
      city: new FormControl(undefined, [Validators.required])
    }),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    phoneNumber: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{8}")]),
    primaryContactUserId: new FormControl(undefined, [Validators.required])
  });
  public adminUsers: EventShopUser[];
  private readonly id?: number;
  public readonly creating: boolean;
  private user: EventShopUser;

  private readonly previousNavigation: Navigation;
  private subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private companiesService: CompaniesService,
              private authService: AuthService,
              private router: Router,
              protected elementRef: ElementRef) {
    super(elementRef);
    this.previousNavigation = this.router.getCurrentNavigation().previousNavigation;
    const params = this.activatedRoute.snapshot.params;
    this.creating = true;
    if (params.companyId) {
      this.id = params.companyId as number;
      this.creating = false;
      forkJoin([
        this.companiesService.getCompany(this.id),
        this.companiesService.getCompanyAdmins(params.companyId)
      ]).pipe(catchError(_ => this.router.navigate([Consts.adminCompaniesRoute])), first())
        .subscribe(([company, users]: [Company, EventShopUser[]]) => {
          this.companyForm.patchValue(company);
          this.companyForm.controls.primaryContactUserId.setValue(company.primaryContactId);
          this.adminUsers = users;
        });
    }

    this.subscription.add(this.authService.userChanged$.subscribe(u => {
      this.user = u
      if (this.creating) {
        this.companyForm.controls.primaryContactUserId.setValue(u.id);
      }
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getHeaderText(): string {
    if (this.creating) {
      return 'createEditCompany.createTitle';
    } else {
      return 'createEditCompany.updateTitle';
    }
  }

  public getButtonText(): string {
    if (this.creating) {
      return 'createEditCompany.create';
    } else {
      return 'createEditCompany.update';
    }
  }

  public updateCompany() {
    // data.filePath = this.tempImagePath;
    //
    //TODO like news with logo
    if (this.companyForm.valid) {
      if (this.creating) {
        const data = this.companyForm.value as CreateCompany;
        data.userId = this.user.id;
        data.isSponsor = true;
        this.companiesService.createCompany(data).pipe(first()).subscribe(_ => {
          if (this.previousNavigation == null) {
            this.router.navigate([Consts.adminClubsRoute]);
          } else {
            this.router.navigateByUrl(this.previousNavigation.extractedUrl);
          }
        }, error => console.error(error));
      } else {
        const data = this.companyForm.value as EditCompany;
        data.isSponsor = true;
        this.companiesService.updateCompany(this.id, data).pipe(first())
          .subscribe(_ => {
            if (this.previousNavigation == null) {
              this.router.navigate([Consts.adminClubsRoute]);
            } else {
              this.router.navigateByUrl(this.previousNavigation.extractedUrl);
            }
          }, error => console.error(error));
      }
    } else {
      this.companyForm.markAllAsTouched();
      this.showError();
    }
  }
}
