<div class="tabs-header-container align-items-center ps-4 pe-4">
  <div class="tabs-header">{{'tab-event-user-admin.title' | translate }}</div>
</div>
<div class=" mt-5 justify-content-center">
  <div class="d-flex justify-content-center">
    <div>{{'tab-event-user-admin.addUser' | translate}}</div>
  </div>
  <div class="d-flex justify-content-center">
    <form class="mx-auto">
      <div class="input-group mb-3" style="width: 350px;">
        <input type="text" class="form-control search-input" name="search" placeholder="{{'tab-event-user-admin.searchEvent' | translate}}" [(ngModel)]="userSearch">
        <button class="btn btn-outline-secondary" type="button" id="search" (click)="searchUsers()">{{'tab-event-user-admin.search' | translate}}</button>
      </div>
    </form>
  </div>
</div>
<div class="tabs-content mt-4 mb-4">
  <table class="user-table">
    <thead>
    <tr>
      <td>
        {{'tab-event-user-admin.username' | translate}}
      </td>
      <td>
        {{'tab-event-user-admin.name' | translate}}
      </td>
      <td></td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let adminUser of (adminUsers$ | async)">
      <td>{{adminUser.username}}</td>
      <td>{{adminUser.firstName}} {{adminUser.lastName}}</td>
      <td>
        <button *ngIf="user.id !== adminUser.id" class="content-button-small" type="button" (click)="removeUser(adminUser.id)">
          <span>{{'global.remove' | translate}}</span>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<ng-template #modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'signUp.sponsor.chooseUser' | translate}}</h5>
  </div>
  <div class="modal-body">
    <table class="mt-5 mb-5">
      <tbody>
      <tr *ngFor="let user of foundUsers">
        <td>{{user.firstName}} {{user.lastName}}</td>
        <td>
          <div class="d-flex justify-content-center">
            <button class="content-button-small" type="button" name="search" (click)="addUser(user.userId)">
              <span>{{'global.choose' | translate}}</span>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="cancelButtonPressed()">{{'modal.cancel' | translate}}</button>
  </div>
</ng-template>
