import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SponsorRunSponsorService } from '../../services/sponsor-run-sponsor.service';
import { AuthService, CustomTranslationService } from 'shared';
import { UtilService } from '../../services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { environment } from '../../../environments/environment';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { PaymentSummeryEvent, PaymentSummeryResponse } from '../../models/payment-summery-response';

@Component({
  selector: 'app-sponsor-payment',
  templateUrl: './sponsor-payment.component.html',
  styleUrls: ['./sponsor-payment.component.scss']
})
export class SponsorPaymentComponent implements OnInit, OnDestroy {
  public paymentSummeryResponse: PaymentSummeryResponse;
  public termsOfTradeChecked = false;
  private subscription = new Subscription();
  private eventId: number;

  constructor(private sponsorService: SponsorRunSponsorService, private sponsorRunService: SponsorRunService, private activatedRoute: ActivatedRoute, private authService: AuthService, public utilService: UtilService, public customTranslationService: CustomTranslationService, private router: Router) {
    const params = this.activatedRoute.snapshot.params;
    this.eventId = params.eventId;
    if (this.eventId) {
      this.subscription.add(this.authService.userChanged$.subscribe(u => {
        if (u) {
          this.sponsorService.getPaymentSummery({eventId: params.eventId}).pipe(first())
            .subscribe(responses => this.paymentSummeryResponse = responses);
        }
      }));
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public pay(): void {
    const request = {
      sponsoredSponsorEventId: this.eventId,
      callbackUrl: `${environment.frontendUrl}/${Consts.paymentPending}`
    };
    this.sponsorService.createPayment(request).pipe(first()).subscribe(response => window.location.href = response.url);
  }

  public eventNameParam(): any {
    return {name: 'test event'};
  }

  public getTermsOfTrade(): string {
    let newRelativeUrl = this.router.createUrlTree([Consts.informationRoute]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    return baseUrl + newRelativeUrl;
  }
}
