<div *ngIf="event">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header">{{'tab-event-edit.title' | translate }}</div>
  </div>
  <div class="s-4 pe-4">
    <div class="tabs-content mt-4 mb-4">
      <app-create-edit-event [event]="event" [company]="event?.company" (formValid)="eventFormValid($event)" (form)="setEventForm($event)"></app-create-edit-event>
      <div class="mt-5">
        <button class="content-button button-text text-uppercase font-italic" type="button" name="register" (click)="updateEvent()">
          {{'tab-event-edit.update' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
