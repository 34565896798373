import { Component, OnInit } from '@angular/core';
import { Consts } from '../../consts';
import { Observable } from 'rxjs';
import { AuthService, EventShopUser } from 'shared';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  public user$: Observable<EventShopUser>;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.user$ = this.authService.userChanged$;
  }

  public editUserInfo(): string {
    return `/${Consts.editUserInfoRoute}`;
  }

  public changePassword(): string {
    return `/${Consts.userChangePasswordRoute}`;
  }
}
