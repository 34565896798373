import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, BreakpointObserverService, EventShopUser, SearchTeams, Team, TeamsService } from 'shared';
import { first } from 'rxjs/operators';
import { ESponsorTeamType } from '../../enums/esponsor-team-type.enum';
import { Observable, Subscription } from 'rxjs';
import { SignUpUserRequest } from '../../models/sign-up-user-request';
import { Consts } from '../../consts';
import { SponsorEvent } from '../../models/sponsor-event';
import { ModalService } from '../../services/modal.service';
import { getUnitTypeTypes } from '../../enums/eunit-type.enum';
import { EventsService } from '../../services/events.service';
import { CompanyEvent } from '../../models/company-event';
import { FormViewComponent } from '../form-view/form-view.component';
import { UtilService } from '../../services/util.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-sponsor-user-signup',
  templateUrl: './sponsor-user-signup.component.html',
  styleUrls: ['./sponsor-user-signup.component.scss']
})
export class SponsorUserSignupComponent extends FormViewComponent implements OnInit, OnDestroy {
  public userForm = new FormGroup({
    userId: new FormControl(undefined, [Validators.required]),
    visible: new FormControl(false),
    rankings: new FormControl(false),
  });

  public userSignUpForm = new FormGroup({
    eventId: new FormControl(-1, [Validators.required, Validators.min(0)]),
    sponsorParticipationType: new FormControl(-1, [Validators.required]),
    unitType: new FormControl(-1, [Validators.required]),
    sponsorTeamType: new FormControl(-1, [Validators.required]),
    teamId: new FormControl(undefined)
  });

  public paymentSignUp = new FormGroup({
    paymentPerUnit: new FormControl(0, [Validators.min(0), Validators.max(1000000)]),
    maxPayment: new FormControl(undefined),
    showMaxPayment: new FormControl(false),
    singlePayment: new FormControl(0, [Validators.min(0), Validators.max(1000000)]),
    gdpr: new FormControl(undefined, [Validators.requiredTrue]),
    terms: new FormControl(undefined, [Validators.requiredTrue])
  });

  @Output()
  public stepChanged = new EventEmitter<number>();

  public teams$: Observable<Team[]>;

  public user: EventShopUser;
  public teamName: string;
  public teamCreationError = false;
  public teamSearch: string;
  public selectedTeam: Team;

  public selectedEvent: SponsorEvent;
  public events: SponsorEvent[];

  private userEvents: CompanyEvent[];
  private subscription = new Subscription();

  constructor(private sponsorService: SponsorRunService,
              private authService: AuthService,
              private router: Router,
              private teamsService: TeamsService,
              private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private eventsService: EventsService,
              protected elementRef: ElementRef,
              public utilService: UtilService,
              private changeDetectorRef: ChangeDetectorRef,
             public breakpointObserverService: BreakpointObserverService) {
    super(elementRef);
    this.subscription.add(this.authService.userChanged$.subscribe(user => {
      if (!user) {
        return;
      }
      this.userForm.controls.userId.setValue(user.id);
      this.user = user;
      this.eventsService.getUsersEvents().pipe(first())
        .subscribe(e => this.userEvents = e);
    }));
    this.sponsorService.getSponsorEvents().pipe(first())
      .subscribe(foundEvents => {
        this.events = foundEvents
      });

    this.subscription.add(this.paymentSignUp.controls.maxPayment.valueChanges.subscribe(v => {
      this.maxPaymentChanged(v);
    }));

    this.subscription.add(this.paymentSignUp.controls.singlePayment.valueChanges.subscribe(v => {
      this.singlePaymentChanged(v);
    }));

    this.paymentSignUp.markAllAsTouched();
    this.paymentSignUp.controls.maxPayment.markAsUntouched();
    this.userSignUpForm.markAllAsTouched();
  }

  ngOnInit() {
    const eventId = Number(this.activatedRoute.snapshot.queryParamMap.get('eventId'));
    if (eventId) {
      this.userSignUpForm.controls.eventId.patchValue(eventId);
      this.sponsorService.getSponsorEvents().pipe(first())
        .subscribe(foundEvents => this.selectedEvent = foundEvents.find(event => event.id == eventId));
    }

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public signUp() {
    if (this.userSignUpForm.valid && this.customValidation()) {
      const signUpUserRequest = this.userSignUpForm.value as SignUpUserRequest;
      signUpUserRequest.visible = this.userForm.controls.visible.value;
      signUpUserRequest.rankings = this.userForm.controls.rankings.value;
      signUpUserRequest.userId = this.userForm.controls.userId.value;
      signUpUserRequest.paymentPerUnit = this.paymentSignUp.controls.paymentPerUnit.value;
      if (this.paymentSignUp.controls.maxPayment.value && this.paymentSignUp.controls.maxPayment.value > 0) {
        signUpUserRequest.maxPayment = this.paymentSignUp.controls.maxPayment.value;
      }
      signUpUserRequest.singlePayment = this.paymentSignUp.controls.singlePayment.value;
      signUpUserRequest.teamId = 0;

      this.sponsorService.signUpUser(signUpUserRequest).pipe(first())
        .subscribe(_ => {
          this.router.navigate([Consts.signUpSucceededUserRoute, this.selectedEvent.id]);
        });
    }
  }

  public eventSelected() {
    this.selectedEvent = this.events.find(event => event.id == this.userSignUpForm.controls.eventId.value);
    this.paymentSignUp.controls.maxPayment.markAsTouched();
    this.maxPaymentChanged(this.paymentSignUp.controls.maxPayment.value);

    this.userSignUpForm.controls.sponsorParticipationType.setValue(-1);
    this.userSignUpForm.controls.unitType.setValue(-1);
    this.userSignUpForm.controls.unitType.setErrors({});
    this.userSignUpForm.controls.sponsorParticipationType.setErrors({});
    this.userSignUpForm.controls.sponsorTeamType.setValue(ESponsorTeamType.Individual);
    // this.userSignUpForm.controls.sponsorTeamType.setValue(-1);
  }

  public showTeamOptions() {
    const show = this.userSignUpForm.controls.sponsorTeamType.value === ESponsorTeamType.Team;
    if (show) {
      this.userSignUpForm.controls.teamId.setValidators([Validators.required])
    } else {
      this.userSignUpForm.controls.teamId.setValidators([])
    }
    return show;
  }

  public createTeam() {
    this.teamsService.createValidatedTeam({name: this.teamName, location: null}).pipe(first())
      .subscribe(team => {
          this.teamCreationError = false;
          this.selectedTeam = team;
        }
        , error => this.teamCreationError = true);
  }

  public searchTeams() {
    this.teams$ = this.teamsService.searchTeams({teamName: this.teamSearch} as SearchTeams)
  }

  public selectTeam(team: Team) {
    this.selectedTeam = team;
    this.userSignUpForm.controls.teamId.setValue(this.selectedTeam.id);
  }

  public getTermsRoute(): string {
    return `/${Consts.participateTermsRoute}`;
  }

  public getGdprRoute(): string {
    return `/${Consts.gdprRoute}`
  }

  public customValidation(): boolean {
    const min = this.calcMin();
    let maxValidated = (!this.paymentSignUp.controls.maxPayment.value || this.paymentSignUp.controls.maxPayment.value >= min);
    return this.authService.signedInChanged$.value && this.userSignUpForm.controls.eventId.value !== -1 && this.userSignUpForm.controls.sponsorParticipationType.value !== -1 && this.userSignUpForm.controls.unitType.value !== -1 && maxValidated;
  }

  public getUnitTypes(): string[] {
    return getUnitTypeTypes();
  }

  public editUserInfo(): string {
    return `/${Consts.editUserInfoRoute}`;
  }

  public alreadyParticipating(): boolean {
    return this.userEvents?.find(e => e.id === this.selectedEvent?.id) != null;
  }

  public getEditSignUpRoute(): string {
    return `/${Consts.userEditSignUp}/${this.selectedEvent.id}`;
  }

  private singlePaymentChanged(newValue: number) {
    const maxPayment = this.paymentSignUp.controls.maxPayment.value;
    const min = this.calcMin();
    if (maxPayment && maxPayment < min) {
      this.paymentSignUp.controls.singlePayment.setErrors({});
    } else {
      this.paymentSignUp.controls.singlePayment.setErrors(null);
    }
  }

  private maxPaymentChanged(newValue: any) {
    const min = this.calcMin();
    if (newValue && min > newValue) {
      this.paymentSignUp.controls.maxPayment.setErrors({});
    } else {
      this.paymentSignUp.controls.paymentPerUnit.setErrors(null);
      this.paymentSignUp.controls.singlePayment.setErrors(null);
      this.paymentSignUp.controls.maxPayment.setErrors(null);
    }
  }

  private calcMin(): number {
    return this.paymentSignUp.controls.paymentPerUnit.value + this.paymentSignUp.controls.singlePayment.value + (this.selectedEvent?.entryFee ?? 0);
  }

  public selectionChanged(event: StepperSelectionEvent) {
    this.stepChanged.next(event.selectedIndex);
  }

  public showMaxPaymentChanged($event: any) {
    if (!$event.target.checked) {
      this.paymentSignUp.controls.maxPayment.setErrors(null);
      this.paymentSignUp.controls.maxPayment.setValue(null);
      this.paymentSignUp.controls.maxPayment.setValidators([]);
      this.paymentSignUp.controls.maxPayment.markAsUntouched();
    } else {
        this.paymentSignUp.controls.maxPayment.markAsTouched();
        this.paymentSignUp.controls.maxPayment.setValidators([Validators.min(1), Validators.max(1000000)]);
        this.changeDetectorRef.detectChanges();
    }
  }
}
