import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { BreakpointObserverService, PaginationBaseComponent } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { Consts } from '../../consts';
import { UtilService } from '../../services/util.service';
import { EventBase } from '../../../../../shared/src/lib/interfaces/event-base';

@Component({
  selector: 'app-find-event',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends PaginationBaseComponent implements OnInit {
  public activeEvents: EventBase[] = [];
  public pastEvents: EventBase[] = [];
  public searchText: string;

  constructor(private eventsService: EventsService, protected router: Router, protected activatedRoute: ActivatedRoute, public breakObserverService: BreakpointObserverService, public utilService: UtilService) {
    super(router, activatedRoute)
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected updateItems(page: number) {
    this.page = page;
    this.subscription?.unsubscribe();
    this.subscription = this.eventsService.getEvents(page - 1, this.itemsPerPage).pipe(shareReplay(1)).subscribe(events => {
      this.activeEvents = events.events.filter(e => this.utilService.dateAfterNow(e.endDate));
      this.pastEvents = events.events.filter(e => this.utilService.dateBeforeNow(e.endDate));
      this.totalNumberOfPages = Math.ceil(events.total / this.itemsPerPage);
    });
  }

  public goToEvent(eventId: number): string {
    return `/${Consts.eventRoute}/${eventId}`;
  }

  public searchEvents() {
    if(!this.searchText){
      this.clearSearch();
      return;
    }
    this.subscription?.unsubscribe();
    this.subscription = this.eventsService.searchEvents(this.searchText).subscribe(events => {
      this.activeEvents = events.filter(e => this.utilService.dateAfterNow(e.endDate));
      this.pastEvents = events.filter(e => this.utilService.dateBeforeNow(e.endDate));
      this.totalNumberOfPages = 1;
    });
  }

  public clearSearch() {
    this.searchText = "";
    this.updateItems(1);
  }
}
