<div class="background">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-5">
        <h1 class="big-header-black">{{'about.title' | translate}}</h1>
      </div>
    </div>
    <div class="row justify-content-center logo-margin">
      <div class="col-md-8">
        <div class="logo-container">
          <img class="logo" src="assets/about/logo.png">
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <h1 class="subheader"> {{'about.mission' | translate}}</h1>
        <div class="body-text">
          {{'about.missionText' | translate}}
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <h1 class="subheader"> {{'about.about' | translate}}</h1>
        <div class="body-text">
          {{'about.aboutText' | translate}}
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <h1 class="subheader"> {{'about.contact' | translate}}</h1>
        <div class="body-text">
          {{'about.contactText' | translate}}
        </div>
        <div class="body-text">
          <a href="mailto:info@joinandmove.dk">info@joinandmove.dk</a>
        </div>
        <div class="body-text">
          <a href="tel:+4522821843">22 82 18 43</a>
        </div>
      </div>
    </div>
  </div>
</div>
