import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { GetSponsorEventCompanyOverviewResponse } from '../../models/GetSponsorEventCompanyOverviewResponse';
import { CustomTranslationService } from 'shared';
import { SponsorRunUserSummaryDto } from '../../models/SponsorRunUserSummaryDto';
import { SponsorRunAdminService } from '../../services/sponsor-run-admin.service';
import { first } from 'rxjs/operators';
import { SponsorEvent } from '../../models/sponsor-event';
import { UtilService } from '../../services/util.service';
import { PodiumComponent } from '../podium/podium.component';
import lodash from 'lodash';
import { ESponsorParticipateType } from '../../enums/esponsor-participate-type.enum';
import { SponsorRankSummary } from '../../models/sponsor-rank-summary';

@Component({
  selector: 'app-tab-all-sponsor-run-users-admin',
  templateUrl: './tab-all-sponsor-run-users-admin.component.html',
  styleUrls: ['./tab-all-sponsor-run-users-admin.component.scss']
})
export class TabAllSponsorRunUsersAdminComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public adminShownSponsorRunUserSummaries: GetSponsorEventCompanyOverviewResponse

  @Input()
  public event: SponsorEvent;

  @ViewChild(PodiumComponent)
  private podiumComponent: PodiumComponent;

  @Output()
  public showSponsorRunnerDetails = new EventEmitter<SponsorRunUserSummaryDto>();

  public sortedSponsorRunUserSummaries: SponsorRunUserSummaryDto[];
  public sorting = '0';

  constructor(public customTranslationService: CustomTranslationService, private sponsorRunAdminService: SponsorRunAdminService, public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (changes["adminShownSponsorRunUserSummaries"] && this.adminShownSponsorRunUserSummaries) {
      this.sortList();
      this.setSums();
    }
  }

  ngAfterViewInit(): void {
    if (this.adminShownSponsorRunUserSummaries && this.podiumComponent) {
      this.setSums();
    }
  }

  private setSums() {
    const sums = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRunUserSummaries, [function (o) {
      return (o.totalSponsorMoney + o.totalUserMoney);
    }]);
    lodash.reverse(sums)
    sums.slice(0, 3);

    this.podiumComponent?.setSponsorRunUser(sums);
  }

  public approveCommunityPayments($event: any) {
    this.sponsorRunAdminService.approvePayment({
      approved: $event.target.checked,
      eventId: this.event.id,
      participationType: ESponsorParticipateType.Run
    }).pipe(first()).subscribe();
  }

  public approvedForPayment($event: any, sponsorRunUserSummary: SponsorRunUserSummaryDto) {
    for (let userUnitTypeSummary of sponsorRunUserSummary.userUnitTypeSummaries) {
      this.sponsorRunAdminService.approvePayment({
        approved: $event.target.checked,
        userId: sponsorRunUserSummary.userId,
        eventId: this.event.id,
        participationType: userUnitTypeSummary.sponsorParticipationType
      }).pipe(first()).subscribe();
    }
  }

  public checkboxDisabled(sponsorRunUserSummary: SponsorRunUserSummaryDto): boolean {
    if (sponsorRunUserSummary.userUnitTypeSummaries.length == 0) {
      return true;
    }

    let payed = false;
    for (let userUnitTypeSummary of sponsorRunUserSummary.userUnitTypeSummaries) {
      for (let summaryPerSponsor of userUnitTypeSummary.summaryPerSponsorList) {
        payed &&= this.adminShownSponsorRunUserSummaries.sponsorRanks.find(s => s.sponsorId == summaryPerSponsor.sponsorId)?.isSponsorshipPayed ?? false;
      }
    }

    return payed;
  }

  public sortList() {
    switch (this.sorting) {
      case '0':
        this.sortedSponsorRunUserSummaries = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRunUserSummaries, [function (o) {
          return (o.totalSponsorMoney + o.totalUserMoney);
        }]);
        lodash.reverse(this.sortedSponsorRunUserSummaries);
        break;
      case '1':
        this.sortedSponsorRunUserSummaries = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRunUserSummaries, [function (o) {
          return o.totalUnitSum;
        }]);
        lodash.reverse(this.sortedSponsorRunUserSummaries);
        break;
    }
  }

  public allApproved(sponsorRunUserSummary: SponsorRunUserSummaryDto): boolean {
    let approved = true;
    for (let userUnitTypeSummary of sponsorRunUserSummary.userUnitTypeSummaries) {
      approved = approved && userUnitTypeSummary.approvedByAdminToBePayed;
    }

    return approved;
  }
}
