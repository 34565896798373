import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { GetAllUserRegistrationsResponse } from '../models/GetAllUserRegistrationsResponse';
import { GetAllUserRegistrationsRequest } from '../models/GetAllUserRegistrationsRequest';
import { SponsorSignUpRequest } from '../models/SponsorSignUpRequest';
import { RegisterUserAsSponsorRequest } from '../models/register-user-as-sponsor-request';
import { SignUpUserRequest } from '../models/sign-up-user-request';
import { RegisterActivityRequest } from '../models/RegisterActivityRequest';
import { SearchSponsorRunUsersRequest } from '../models/SearchSponsorRunUsersRequest';
import { SearchSponsorRunUsersResponse } from '../models/SearchSponsorRunUsersResponse';
import { GetSponsorEventCompanyOverviewResponse } from '../models/GetSponsorEventCompanyOverviewResponse';
import { CreateSponsorEventRequest } from '../models/create-sponsor-event-request';
import { SponsorRunUser } from '../models/sponsor-run-user';
import { SponsorDto } from '../models/SponsorDto';
import { SponsorLink } from '../models/sponsor-link';
import { apiUrlToken } from 'shared';
import { SponsorEvent } from '../models/sponsor-event';
import { AddParticipationCombinationRequest } from '../models/add-participation-combination-request';
import { EditSignUpUserRequest } from '../models/edit-sign-up-user-request';

//TODO split this into more services
@Injectable({
  providedIn: 'root'
})
export class SponsorRunService {
  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getSponsorEvents(): Observable<SponsorEvent[]> {
    return this.http.get<SponsorEvent[]>(`${this.apiUrl}/api/SponsorRun/GetSponsorEvents`);
  }

  public getSponsorEvent(eventId: number): Observable<SponsorEvent> {
    return this.http.get<SponsorEvent>(`${this.apiUrl}/api/SponsorRun/GetSponsorEvent/${eventId}`);
  }

  public getSponsorEventForAdmin(eventId: number): Observable<SponsorEvent> {
    return this.http.get<SponsorEvent>(`${this.apiUrl}/api/SponsorRun/GetSponsorEventForUser/${eventId}`);
  }

  public getAllUserRegistrations(companyEventId: number): Observable<GetAllUserRegistrationsResponse> {
    return this.http.post<GetAllUserRegistrationsResponse>(`${this.apiUrl}/api/SponsorRun/GetAllUserRegistrations`,
      new GetAllUserRegistrationsRequest(companyEventId));
  }

  public signUpSponsor(request: SponsorSignUpRequest): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/api/SponsorRun/SignUpSponsor/`, request);
  }

  public registerUserAsSponsor(request: RegisterUserAsSponsorRequest) {
    return this.http.post(`${this.apiUrl}/api/SponsorRun/RegisterUserAsSponsor/`, request);
  }

  public registerActivity(request: RegisterActivityRequest) {
    return this.http.post(`${this.apiUrl}/api/SponsorRun/RegisterActivity/`, request);
  }

  public signUpUser(request: SignUpUserRequest): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/api/SponsorRun/SignUpUserV2/`, request);
  }

  public createSponsorEvent(request: CreateSponsorEventRequest): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/api/SponsorRun/CreateSponsorEventv2/`, request);
  }

  public updateSponsorEvent(request: CreateSponsorEventRequest): Observable<number> {
    return this.http.put<number>(`${this.apiUrl}/api/SponsorRun/UpdateSponsorEvent/`, request);
  }

  public searchSponsorRunUsers(companyEventId: number, name: string): Observable<SearchSponsorRunUsersResponse> {
    const request = {companyEventId, nameSearchString: name} as SearchSponsorRunUsersRequest;
    return this.http.post<SearchSponsorRunUsersResponse>(`${this.apiUrl}/api/SponsorRun/SearchSponsorRunUsers/`, request);
  }

  public getSponsorEventCompanyOverview(companyEventId: number): Observable<GetSponsorEventCompanyOverviewResponse> {
    return this.http.get<GetSponsorEventCompanyOverviewResponse>(`${this.apiUrl}/api/SponsorRun/GetSponsorEventCompanyOverview?sponsorRunEventId=` + companyEventId);
  }

  public getSponsorRunUser(sponsorRunUserId: string, sponsorRunEventId: number): Observable<SponsorRunUser> {
    return this.http.get<SponsorRunUser>(`${this.apiUrl}/api/SponsorRun/GetSponsorRunUser?sponsorRunUserId=` + sponsorRunUserId + '&sponsorRunEventId=' + sponsorRunEventId);
  }

  public getCurrentAsSponsorRunUser(sponsorRunEventId: number): Observable<SponsorRunUser> {
    return this.http.get<SponsorRunUser>(`${this.apiUrl}/api/SponsorRun/GetCurrentAsSponsorRunUser/${sponsorRunEventId}`);
  }

  public getUsersSignedUpEvents(): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiUrl}/api/SponsorRun/GetUsersSignedUpEvents`);
  }

  public getSponsorData(): Observable<SponsorDto> {
    return this.http.get<SponsorDto>(`${this.apiUrl}/api/SponsorRun/GetSponsorData`);
  }

  public getSponsorLinks(): Observable<SponsorLink[]> {
    return this.http.get<SponsorLink[]>(`${this.apiUrl}/api/SponsorRun/GetActiveSponsorLinks`);
  }

  public getSponsorLink(eventId: number): Observable<SponsorLink> {
    return this.http.get<SponsorLink>(`${this.apiUrl}/api/SponsorRunUser/GetActiveSponsorLink/${eventId}`);
  }

  public addParticipationCombination(addParticipationCombination: AddParticipationCombinationRequest): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/SponsorRun/AddParticipationCombination`, addParticipationCombination);
  }

  public editSignUpUser(editSignUpUserRequest: EditSignUpUserRequest): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/SponsorRun/EditSignUpUser`, editSignUpUserRequest);
  }
}
