import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { catchError, first, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { AdminService, Company } from 'shared';
import { Consts } from '../../consts';

@Component({
  selector: 'app-tab-admin-company-validate',
  templateUrl: './tab-admin-club-validate.component.html',
  styleUrls: ['./tab-admin-club-validate.component.scss']
})
export class TabAdminClubValidateComponent implements OnInit, OnDestroy {
  public club$: Observable<Company>;

  private previousNavigation: Navigation;
  private readonly subscription = new Subscription();

  private clubId: number;

  constructor(private adminService: AdminService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.subscription.add(this.activatedRoute.queryParams.subscribe(queryParam => {
      if (queryParam.clubId) {
        this.previousNavigation = this.router.getCurrentNavigation().previousNavigation;
        this.clubId = queryParam.clubId;
        this.club$ = this.adminService.getInvalidCompany(queryParam.clubId as number)
          .pipe(catchError(err => {
            this.router.navigate([Consts.adminRoot])
            return of<Company>();
          }));
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public validate() {
    this.adminService.validateCompany(this.clubId, true).pipe(first())
      .subscribe(_ => {
        if (this.previousNavigation == null) {
          this.router.navigate([Consts.userInfoRoute]);
        } else {
          this.router.navigateByUrl(this.previousNavigation.extractedUrl)
            .then(() => {
            window.location.reload();
          });
        }
      });
  }
}
