import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Consts } from '../../consts';
import { Router } from '@angular/router';
import { BreakpointObserverService, CustomTranslationService } from 'shared';

@Component({
  selector: 'app-frontpage',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public eventsCount$: Observable<number> = of(176);
  public total$: Observable<number> = of(578961000);
  public sponsorRunners$: Observable<number> = of(289745);

  public countOptions = {
    duration: 2,
    useGrouping:  true,
    separator: '.',
    useEasing: false
  };

  constructor(private router: Router, public customTranslationService: CustomTranslationService, public breakpointObserverService: BreakpointObserverService) {
  }

  ngOnInit(): void {
  }

  public goToParticipateRoute() {
    this.router.navigate([Consts.signUpRoute], {queryParams: {tab: Consts.participateTab}});
  }

  public goToCreateEventRouteRoute() {
    this.router.navigate([Consts.signUpRoute], {queryParams: {tab: Consts.arrangeTab}});
  }

  public goToSponsorRoute() {
    this.router.navigate([Consts.signUpRoute], {queryParams: {tab: Consts.sponsorTab}});
  }
}
