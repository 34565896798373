<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'userResetPassword.title' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 mb-5 d-flex justify-content-center">
    <div class="col-md-6">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
        <div class="form-group">
          <label class="form-label" for="newPassword">{{'signUpUser.password' | translate}}*</label>
          <input id="newPassword" class="form-control content-input" formControlName="newPassword" required type="password" (ngModelChange)="validatePassword()"/>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.length">
            {{'signUpUser.length' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.capitalLetter">
            {{'signUpUser.capitalLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.smallLetter">
            {{'signUpUser.smallLetter' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.digit">
            {{'signUpUser.digit' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.special">
            {{'signUpUser.special' | translate}}
          </div>
          <div class="password-invalid" *ngIf="passwordValidation && !passwordValidation.spacing">
            {{'signUpUser.spacing' | translate}}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="repeatedNewPassword">{{'signUpUser.repeatPassword' | translate}}*</label>
          <input id="repeatedNewPassword" class="form-control content-input" formControlName="repeatedNewPassword" required type="password" (ngModelChange)="validateRepeatedPassword()"/>
          <div class="password-invalid" *ngIf="resetPasswordForm.controls.repeatedNewPassword.invalid && (resetPasswordForm.controls.repeatedNewPassword.dirty || resetPasswordForm.controls.repeatedNewPassword.touched)">
            {{'signUpUser.notMatching' | translate}}
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button class="content-button button-text text-uppercase font-italic" type="submit" name="update">
            {{'userResetPassword.reset' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
