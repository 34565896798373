<div class="container-fluid" xmlns="http://www.w3.org/1999/html">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'userEditSignup.title' | translate}}</h1>
    </div>
  </div>
  <div *ngIf="sponsorRunUser">
    <div class="row mt-5">
      <div class="col-md-12 text-center">
        <h2>{{sponsorRunUser.sponsorEvent.name}}</h2>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-flex justify-content-center">
      <div class="col-md-6">
        <h4>{{'userEditSignup.existing' | translate}}</h4>
        <form [formGroup]="existingParticipationForm" (submit)="updateExisting(existingParticipationForm)">
          <div class="form-group">
            <label class="form-label" for="existingSponsorParticipationType">{{'signUp.participate.sponsorParticipateType' | translate}}*</label>
            <select id="existingSponsorParticipationType" class="form-control content-input" formControlName="sponsorParticipationType" required [attr.disabled]="sponsorRunUser ? null : ''" (change)="sponsorParticipationTypeSelected(existingParticipationForm)">
              <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
              <option *ngFor="let sponsorRunUserSignUpCombination of sponsorRunUser.sponsorRunUserSignUpCombinations" value="{{sponsorRunUserSignUpCombination.id}}">{{'global.sponsorParticipateTypeItem.' + sponsorRunUserSignUpCombination.sponsorParticipationType | translate}} - {{'global.unitTypeItem.' + sponsorRunUserSignUpCombination.unitType | translate}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label" for="existingPaymentPerUnit">{{'signUp.participate.paymentPerUnit' | translate}}</label>
            <input id="existingPaymentPerUnit" class="form-control content-input" formControlName="paymentPerUnit" type="number" required placeholder="{{'global.paymentPer' | translate}}"/>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [disabled]="!existingParticipationForm.valid || !customValidation(existingParticipationForm, false)">
              {{'userEditSignup.update' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-flex justify-content-center">
      <div class="col-md-6">
        <h4>{{'userEditSignup.add' | translate}}</h4>
        <form [formGroup]="newParticipationForm" (submit)="updateExisting(newParticipationForm)">
          <div class="form-group">
            <label class="form-label" for="newSponsorParticipationType">{{'signUp.participate.sponsorParticipateType' | translate}}*</label>
            <select id="newSponsorParticipationType" class="form-select content-input" formControlName="sponsorParticipationType" required>
              <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
              <option *ngFor="let sponsorParticipateType of event.sponsorParticipateTypes" value="{{sponsorParticipateType}}">{{'global.sponsorParticipateTypeItem.' + sponsorParticipateType | translate}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label" for="unitType">{{'signUp.participate.unitType' | translate}}*</label>
            <select id="unitType" class="form-select content-input" formControlName="unitType">
              <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
              <option *ngFor="let unitType of getUnitTypes()" value="{{unitType}}">{{'global.unitTypeItem.' + unitType | translate}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label" for="newPaymentPerUnit">{{'signUp.participate.paymentPerUnit' | translate}}</label>
            <input id="newPaymentPerUnit" class="form-control content-input" formControlName="paymentPerUnit" type="number" required placeholder="{{'global.paymentPer' | translate}}"/>
          </div>
          <div class="form-group d-flex justify-content-center">
            <div *ngIf="alreadyHaveThatCombination(newParticipationForm)" style="color: red"> {{'userEditSignup.alreadyExist' | translate}}</div>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [disabled]="!newParticipationForm.valid || !customValidation(newParticipationForm, true) || alreadyHaveThatCombination(newParticipationForm)">
              {{'userEditSignup.add' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-flex justify-content-center">
      <div class="col-md-6">
        <h4>{{'userEditSignup.editPayment' | translate}}</h4>
        <form [formGroup]="userSignUpForm" (submit)="updateSignUp()">
          <div class="form-group">
            <label class="form-label" for="maxPayment">{{'signUp.participate.maxPayment' | translate}}</label>
            <input id="maxPayment" class="form-control content-input" formControlName="maxPayment" type="number" required placeholder="{{'global.currency' | translate}}"/>
          </div>
          <div class="form-group">
            <label class="form-label" for="singlePayment">{{'signUp.participate.singlePayment' | translate}}</label>
            <input id="singlePayment" class="form-control content-input" formControlName="singlePayment" type="number" required placeholder="{{'global.currency' | translate}}"/>
          </div>
          <div class="form-check mt-3">
            <input class="form-check-input" type="checkbox" id="visible" formControlName="visible">
            <label class="form-check-label" for="visible">
              {{'signUp.participate.visible' | translate}}
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="checkbox" id="rankings" formControlName="rankings">
            <label class="form-check-label" for="rankings">
              {{'signUp.participate.rank' | translate}}
            </label>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" [disabled]="!userSignUpForm.valid">
              {{'userEditSignup.update' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="form-group d-flex justify-content-center">
      <button class="content-button button-text text-uppercase font-italic" type="submit" name="update" (click)="back()">
        {{'userEditSignup.back' | translate}}
      </button>
    </div>
  </div>
</div>
