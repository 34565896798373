import { AfterViewChecked, Directive, ElementRef, OnInit } from '@angular/core';

@Directive()
export abstract class FormViewComponent implements OnInit, AfterViewChecked {
  private scrollToError = false;

  protected constructor(protected elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    if (this.scrollToError) {
      this.scrollToFirstInvalidControl(this.elementRef);
      this.scrollToError = false
    }
  }

  public showError(): void {
    this.scrollToError = true;
  }

  private scrollToFirstInvalidControl(elementRef: ElementRef) {
    const firstInvalidControl: HTMLElement = elementRef.nativeElement.querySelector(
      "form .ng-invalid"
    );

    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth"
      });
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

}
