import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { Contact } from '../../models/contact';
import { ModalService } from '../../services/modal.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contactForm = new FormGroup({
    name: new FormControl(undefined, [Validators.required]),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    subject: new FormControl(undefined, [Validators.required]),
    message: new FormControl(undefined, [Validators.required]),
    webUrl: new FormControl(undefined),
  });

  constructor(private contactService: ContactService, private modalService: ModalService) {
  }

  ngOnInit(): void {
  }

  public sendEmail(): void {
    if (this.contactForm.valid) {
      this.contactService.sendContactForm(this.contactForm.value as Contact).pipe(first())
        .subscribe(_ => {
          this.modalService.showModal('contact.modalTitle', 'contact.modalBody');
          this.contactForm.reset();
        });

    } else {
      this.contactForm.markAllAsTouched();
    }
  }
}
