<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-12 text-center">
      <h1 class="big-header-black">{{'registerActivity.title' | translate}}</h1>
    </div>
  </div>
  <div *ngIf="event">
    <div class="row mt-5">
      <div class="col-md-12 text-center">
        <h1 class="subheader">{{event.name}}</h1>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-flex justify-content-center">
      <app-sponsor-register-activity [event]="event" [sponsorRunUser]="sponsorRunUser" (form)="setForm($event)" (formValid)="formValid($event)"></app-sponsor-register-activity>
    </div>
    <div class="form-group d-flex justify-content-center">
      <button class="content-button button-text text-uppercase font-italic" type="button" name="register" (click)="registerUserSponsorActivity()">
        {{'registerActivity.submit' | translate}}
      </button>
    </div>
  </div>
</div>
