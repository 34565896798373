import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'eventShop-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, OnDestroy {
  @Input()
  public name: string;
  @Input()
  public title: string;

  public active = new BehaviorSubject<boolean>(false)
  private subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (!this.name) {
      console.error(`Please provide a name for the tab with name: ${this.name}`);
    }

    this.subscription.add(this.activatedRoute.queryParamMap.subscribe(p => this.active.next(p.get('tab') === this.name)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
