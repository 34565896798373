import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthService, CompaniesService, Company, EventShopUser } from 'shared';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-company-data',
  templateUrl: './tab-company-data.component.html',
  styleUrls: ['./tab-company-data.component.scss']
})
export class TabCompanyDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public company: Company;
  public user: EventShopUser;
  private subscription = new Subscription();

  constructor(private companiesService: CompaniesService, private authService: AuthService) {
    this.subscription.add(this.authService.userChanged$.subscribe(u => this.user = u));
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (changes["company"] && this.company) {
      this.companiesService.getCompany(this.company.id).pipe(first())
        .subscribe(c => this.company = c);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editClubRoute(): string {
    return `/${Consts.createEditClubRoute}/${this.company.id}`
  }
}
