<div class="tabs-header-container align-items-center ps-4 pe-4">
  <div class="tabs-header">{{'tab-event-invite-participant.title' | translate}}</div>
</div>
<div class="ps-4 pe-4">
  <div class="tabs-content mt-4 mb-4">
    <div class="mt-2" *ngIf="event">
      <div class="mb-2">{{'tab-event-invite-participant.text' | translate}}</div>
      <div class="mb-2">
        {{getParticipantLinkUrl()}}
      </div>
      <div>
        <button [cdkCopyToClipboard]="getParticipantLinkUrl()" (cdkCopyToClipboardCopied)="copied($event)" class="content-button-small" type="button" name="copy">
                <span>
                  {{'global.copy' | translate}}
                </span>
        </button>
      </div>
    </div>
  </div>
</div>
