<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-9 text-center mt-5">
      <h1 class="big-header-black">{{'events.listTitle' | translate}}</h1>
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-md-9 d-flex justify-content-center">
      <form class="mx-auto">
        <div class="input-group mb-3" style="width: 350px;">
          <input type="text" class="form-control search-input" name="search" placeholder="{{'events.searchEvent' | translate}}" [(ngModel)]="searchText">
          <button class="btn btn-outline-secondary" type="button" id="search" (click)="searchEvents()">{{'events.search' | translate}}</button>
          <button class="btn btn-outline-secondary" type="button" id="clear" (click)="clearSearch()">{{'events.clear' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-md-12 d-flex justify-content-center">
      <div class="table-list-container">
        <div class="text-center event-section-header">{{'events.activeEvents' | translate}}</div>
        <!--        <div *ngIf="(breakObserverService.belowiPad$ | async).matches">-->
        <!--          <table class="mobile-list-table mb-5" *ngFor="let event of activeEvents, let i = index">-->
        <!--            <tbody [class]="i % 2 == 0 ? 'even' : ''">-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.startDate' | translate}}</td>-->
        <!--              <td>{{event.startDate | date: utilService.getDateFormat()}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.endDate' | translate}}</td>-->
        <!--              <td>{{event.endDate | date: utilService.getDateFormat()}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.event' | translate}}</td>-->
        <!--              <td>{{event.name}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.place' | translate}}</td>-->
        <!--              <td>{{event.company?.address?.postalCode}} {{event.company?.address?.city}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.company' | translate}}</td>-->
        <!--              <td>{{event.company.name}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td colspan="2" class="pt-4">-->
        <!--                <a routerLink="{{goToEvent(event.id)}}" class="font-italic">-->
        <!--                  {{'global.goToEvent' | translate}}-->
        <!--                </a>-->
        <!--              </td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--        </div>-->
        <!--        <table *ngIf="!(breakObserverService.belowiPad$ | async).matches">-->
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <td class="td-date">{{'eventTable.headers.startDate' | translate}}</td>
              <td class="td-date">{{'eventTable.headers.endDate' | translate}}</td>
              <td class="td-name">{{'eventTable.headers.event' | translate}}</td>
              <td class="td-rest">{{'eventTable.headers.place' | translate}}</td>
              <td class="td-rest">{{'eventTable.headers.company' | translate}}</td>
              <td>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let event of activeEvents">
              <td class="td-date">{{event.startDate | date: utilService.getDateFormat()}}</td>
              <td class="td-date">{{event.endDate | date: utilService.getDateFormat()}}</td>
              <td class="td-name">{{event.name}}</td>
              <td class="td-rest">{{event.company?.address?.postalCode}} {{event.company?.address?.city}}</td>
              <td class="td-rest">{{event.company.name}}</td>
              <td class="text-end td-link">
                <a routerLink="{{goToEvent(event.id)}}">
                  {{'global.goToEvent' | translate}}
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!--        <div class="mt-4 text-center event-section-header" *ngIf="(breakObserverService.belowiPad$ | async).matches">{{'events.pastEvents' | translate}}-->
        <div class="mt-4 text-center event-section-header">{{'events.pastEvents' | translate}}</div>
        <!--          <table class="mobile-list-table mb-5" *ngFor="let event of pastEvents, let i = index">-->
        <!--            <tbody [class]="i % 2 == 0 ? 'even' : ''">-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.startDate' | translate}}</td>-->
        <!--              <td>{{event.startDate | date: utilService.getDateFormat()}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.endDate' | translate}}</td>-->
        <!--              <td>{{event.endDate | date: utilService.getDateFormat()}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.event' | translate}}</td>-->
        <!--              <td>{{event.name}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.place' | translate}}</td>-->
        <!--              <td>{{event.company?.address?.postalCode}} {{event.company?.address?.city}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td>{{'eventTable.headers.company' | translate}}</td>-->
        <!--              <td>{{event.company.name}}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td colspan="2" class="pt-4">-->
        <!--                <a routerLink="{{goToEvent(event.id)}}" class="font-italic">-->
        <!--                  {{'global.goToEvent' | translate}}-->
        <!--                </a>-->
        <!--              </td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--        </div>-->
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <td class="td-date">{{'eventTable.headers.startDate' | translate}}</td>
              <td class="td-date">{{'eventTable.headers.endDate' | translate}}</td>
              <td class="td-name">{{'eventTable.headers.event' | translate}}</td>
              <td class="td-rest">{{'eventTable.headers.place' | translate}}</td>
              <td class="td-rest">{{'eventTable.headers.company' | translate}}</td>
              <td>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let event of pastEvents">
              <td class="td-date">{{event.startDate | date: utilService.getDateFormat()}}</td>
              <td class="td-date">{{event.endDate | date: utilService.getDateFormat()}}</td>
              <td class="td-name">{{event.name}}</td>
              <td class="td-rest">{{event.company?.address?.postalCode}} {{event.company?.address?.city}}</td>
              <td class="td-rest">{{event.company.name}}</td>
              <td class="text-end td-link">
                <a routerLink="{{goToEvent(event.id)}}">
                  {{'global.goToEvent' | translate}}
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row justify-content-center mt-5">
          <eventShop-pagination-component [totalNumberOfPages]="totalNumberOfPages" [currentPage]="this.page" (goToPageEvent)="gotToPage($event)" (nextPageEvent)="getNextPage($event)" (prevPageEvent)="prevPage($event)"></eventShop-pagination-component>
        </div>
      </div>
    </div>
  </div>
</div>
