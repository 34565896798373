<div class="container-fluid bigger-body-font-size">
  <div class="row tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header align-items-center">{{'sponsorTerms.title' | translate }}</div>
  </div>
  <div class="row mt-5 mb-5 justify-content-center">
    <div class="row justify-content-center mt-5 mb-5">
      <div class="col-md-8">
        <div>
          <div>
            Join and Move stiller en administrationsplatform til rådighed for foreninger eller andre aktører som ønsker at oprette sponsorevents / støtteevents, og for deltagere og sponsorere der vil støtte op om disse events
          </div>
          <div>
            Som sponsor har du mulighed for at vælge at støtte eventet, og/eller en eller flere deltagere med et beløb der afhænger af deres aktivitetsniveau. Eks. 10 kr. Pr KM, dog max 1000 kr. Se mere info under vores fane med informationer.
          </div>
          <div class="mt-2">
            Når eventets opkrævningsperiode er afsluttet, vil dit samlede støttebeløb blive sendt til arrangørforeningen 3-5 bankdage efter sidste opkrævning er indbetalt. Dog fratrukket 8,8 % til Join and Move, der blandt andet dækker transaktions- og administrationsomkostninger.
          </div>
        </div>
        <div class="mt-3">
          <div>
            <strong style="text-decoration: underline">Momsforhold</strong>
          </div>
          <div>
            Foreningen / eventarrangøren har fra start angivet sine momsforhold, og det vil have indflydelse på jeres opkrævning. Såfremt foreningen er momsfritaget vil en opkrævning til dig på eks. 1.000 hvoraf der ikke vil være mulighed for jer for at fratrække moms på dette beløb. Hvis foreningen / eventarrangøreren er momspligtig vil en opkrævning på 1.000 kr. Blive opkrævet med en faktura på 1000 kr. Incl. Moms, hvoraf der så vil være fradrag for momsen.
          </div>
        </div>
        <div class="mt-3">
          <div>
            <strong style="text-decoration: underline">Ansvar</strong>
          </div>
          <div>
            Join and Move er en serviceplatform, og Eventshop.dk ApS vil ikke dække tab direkte eller indirekte i forbindelse med brug af platformen eller events oprettet via platformen.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
