<div class="col-md-12">
  <div class="container-fluid ps-0 pe-0">
    <div class="row tabs-header-container">
      <div class="tabs-header align-items-center">{{'admin.companies' | translate }}</div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="tabs-content mt-4 mb-4">
      <table class="tabs-table">
        <tr *ngFor="let club of (clubs$ | async)">
          <td>
            {{club.name}}
          </td>
          <td>
            <a (click)="clubSelected(club.id)">
              {{'admin.goToCompany' | translate}}
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
