<div>
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header">{{'arrangeTerms.title' | translate }}</div>
  </div>
  <div class="ps-4 pe-4 mt-5 mb-5 justify-content-center">
    <div>
      <div>
        <div>
          Join and Move stiller en administrationsplatform til rådighed for foreninger eller andre aktører som ønsker at oprette sponsorevents / støtteevents, og for deltagere der vil støtte op om disse projekter.
        </div>
        <div class="mt-2">
          Som deltager får man mulighed for at benytte invitationslinks til de oprettede events man tilmelder sig, men er forpligtet til at benytte invitationslinks efter gældende regler i markedsføringsloven.
        </div>
        <div class="mt-2">
          Det betyder blandt andet at:
          <ul>
            <li>Du må gerne sende personlige beskeder til folk du kender i dit netværk hvor du invitere dem til at deltage som deltager eller sponsor fra din egen mailadresse, sms eller andre private beskeder.</li>
            <li>Du må gerne dele links mm. På din egen facebook side</li>
          </ul>
        </div>
        <div>
          Det er
          <strong style="text-decoration: underline">IKKE</strong> tilladt at sende massekommunikation ud til personer uden samtykke. Dog vil det være naturligt at i har flere mailgrupper i jeres forening som har givet tilladelse til at i kan udsende nyhedsbreve mm. Til dem, og i såfald vil det selvfølgelig være helt ok at sende nyheder og informationer om jeres event til dem der.
        </div>
      </div>
      <div class="mt-3">
        <div>
          <strong style="text-decoration: underline">Eventet</strong>
        </div>
        <div>
          Når først eventet er oprettet og godkendt kan hverken arrangøreren eller Join and Move rette i beskrivelse af hvad støtteformålet er, eller hvem der er arrangør. Men Join and Move forbeholder sig retten til enhver tid at annullere eventet såfremt eventet ikke er oprettet i god tro, eller viser sig at stride imod Join and Moves værdier.
        </div>
      </div>
      <div class="mt-3">
        <div>
          <strong style="text-decoration: underline">Betaling og betingelser</strong>
        </div>
        <div>
          Når eventet er færdigt vil I som arrangør skulle godkende jeres deltageres indberettede aktivitet samt godkende dem til betaling. Når I har godkendt en deltager, vil der via Join and Moves portal automatisk blive sendt opkrævning ud til betaling hos deltageren selv (såfremt der opkræves et deltagergebyr), samt til de sponsorer, der er tilknyttet hver deltager. Join and Moves elektroniske administrationsportal samler derefter automatisk indbetalingerne fra jeres event ind. Når støttebeløbene er indbetalt til os, fortager vi en samlet udbetaling til jer, dog fratrukket transaktions- og administrationsgebyr. Join and Move tager 8,8 % af det indsamlede beløb til at dække transaktions- og administrationsomkostninger samt anvendelse af Join and Moves elektroniske administrationsplatform. Beløbet modregnes det endelige event resultat inden det samlede støttebeløb overføres til foreningens konto. I vil modtage jeres udbetaling 3-5 bankdage efter alle sponsorere har betalt deres opkrævning
        </div>
        <div class="mt-2">
          Join and Move stiller servicen ´automatisk opkrævning´ til rådighed. Sponsorer, der skal betale vil efter godkendelse fra eventarrangøren modtage et betalingslink, hvorefter eventarrangøren kan tilgå oplysninger om hvem der har betalt, og hvem der mangler. Det er eventarrangøren, der fortager eventuelle opfølgende rykkere. Opkrævningsperioden forventes afsluttet senest 14 dage efter eventet blev afsluttet. Arrangøren kan her være nødsaget til at træffe beslutning om eventuelle forfalden betaling skal afskrives. Eventuelle omkostninger til afskrivninger modregnes foreningens udbetaling.
        </div>
      </div>
      <div class="mt-3">
        <div>
          <strong style="text-decoration: underline">Momsforhold</strong>
        </div>
        <div>
          Det er vigtigt at i ved start angiver jeres momsforhold, altså om jeres forening er momspligtig eller ikke momspligtig, da det skal fremgå af vores opkrævninger til både deltagere og sponsorer.
        </div>
      </div>
      <div class="mt-3">
        <div>
          <strong style="text-decoration: underline">Ansvar</strong>
        </div>
        <div>
          Join and Move er en serviceplatform, og Eventshop.dk ApS vil ikke dække tab direkte eller indirekte i forbindelse med brug af platformen eller events oprettet via platformen.
        </div>
      </div>
      <div class="mt-3">
        <div>
          <strong style="text-decoration: underline">Ansvarsbegrænsning</strong>
        </div>
        <div>
           Eventshop ApS har ikke mulighed for at overvåge velgørenhedsorganisationernes eller de private velgørende formåls anvendelse af donerede beløb, hvorfor Eventshop ApS ikke er ansvarlig for hverken direkte eller indirekte tab opstået som følge af brug af www.joinandmove.dk. Sponsorer bør derfor kun donere til en velgørenhedsorganisation eller et privat velgørende formål, som sponsoren har tillid til.
        </div>
      </div>
      <div class="mt-3">
        <div>
          <strong style="text-decoration: underline">Lovgivning</strong>
        </div>
        <div>
          Eventshop ApS rådgiver ikke om lovmæssige forhold vedrørende danske indsamlinger. Hvis du ønsker råd og vejledning af lovmæssig karakter til din indsamling opfordrer vi til at kontakte Indsamlingsnævnet. Eventshop ApS er ikke arrangør, men alene en administrativ serviceplatform, og det vil til enhver tid være arrangøreren der benytter www.joinandmove.dk, der er ansvarlig for eventuelle tilladelser. Se mere info via dette link <a href="https://civilstyrelsen.dk/sagsomraader/indsamlingsnaevnet" target="_blank">https://civilstyrelsen.dk/sagsomraader/indsamlingsnaevnet</a>
        </div>
      </div>
    </div>
  </div>
</div>
