import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AdminService, Company, TabComponent, TabsComponent } from 'shared';
import { Consts } from '../../consts';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(TabComponent)
  private tabComponents: QueryList<TabComponent>;

  @ViewChild(TabsComponent)
  public tabsParent: TabsComponent;

  private tabs: TabComponent[]
  private activeTab: TabComponent;

  private subscription = new Subscription()

  constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router,  private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.tabs = this.tabComponents.toArray();
    for (let tab of this.tabs) {
      this.subscription.add(tab.active.subscribe(active => {
        if (active) {
          this.activeTab = tab;
        }
      }))
    }
    this.changeDetectorRef.detectChanges()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isTabActive(tabName: string): boolean {
    return this.activeTab?.name == tabName;
  }

  public selectTab(tabName: string) {
    const tab = this.tabComponents.find(t => t.name === tabName);
    this.tabsParent.selectTab(tab);
  }

  public invalidClubSelected(clubId: number) {
    const params = {
      tab: 'tab-admin-company-validate',
      clubId: clubId
    };
    this.router.navigate(['.'], {relativeTo: this.route, queryParams: params, replaceUrl: true,});
  }

  public userSelected(userId: string) {
    const params = {
      tab: 'tab-admin-user',
      userId: userId
    };
    this.router.navigate(['.'], {relativeTo: this.route, queryParams: params, replaceUrl: true,});
  }
}
