import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SponsorEvent } from '../../models/sponsor-event';
import { Company, TabsComponent } from 'shared';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { CreateSponsorEventRequest } from '../../models/create-sponsor-event-request';
import { ESponsorParticipateType, getSponsorParticipateTypes } from '../../enums/esponsor-participate-type.enum';
import { ESponsorTeamType, getSponsorTeamTypes } from '../../enums/esponsor-team-type.enum';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { Consts } from '../../consts';
import { FormViewComponent } from '../form-view/form-view.component';

@Component({
  selector: 'app-tab-event-edit',
  templateUrl: './tab-event-edit.component.html',
  styleUrls: ['./tab-event-edit.component.scss']
})
export class TabEventEditComponent extends FormViewComponent implements OnInit {
  @Input()
  public event: SponsorEvent;

  public eventFormValidValue = false;
  public eventForm: FormGroup;

  constructor(private sponsorService: SponsorRunService, private modalService: ModalService, private router: Router, protected elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit(): void {
  }


  public eventFormValid(valid: boolean) {
    this.eventFormValidValue = valid;
  }

  public setEventForm(eventForm: FormGroup) {
    this.eventForm = eventForm;
  }

  public updateEvent() {
    if (this.eventFormValidValue) {
      const createSponsorEventRequest = this.eventForm.value as CreateSponsorEventRequest;
      createSponsorEventRequest.id = this.event.id;
      createSponsorEventRequest.sponsorParticipateTypes = [];
      for (const sponsorParticipateTypes of getSponsorParticipateTypes()) {
        if (this.eventForm.controls[sponsorParticipateTypes].value) {
          createSponsorEventRequest.sponsorParticipateTypes.push(sponsorParticipateTypes as ESponsorParticipateType);
        }
      }

      createSponsorEventRequest.sponsorTeamTypes = [];
      for (const sponsorTeamType of getSponsorTeamTypes()) {
        if (this.eventForm.controls[sponsorTeamType].value) {
          createSponsorEventRequest.sponsorTeamTypes.push(sponsorTeamType as ESponsorTeamType);
        }
      }

      createSponsorEventRequest.startDate = moment(`${this.eventForm.controls.startDate.value} ${this.eventForm.controls.startTime.value}`).toDate();
      createSponsorEventRequest.endDate = moment(`${this.eventForm.controls.endDate.value} ${this.eventForm.controls.endTime.value}`).toDate();
      this.sponsorService.updateSponsorEvent(createSponsorEventRequest).pipe(first())
        .subscribe(_ => {
          this.router.navigate([`/${Consts.eventRoute}/${this.event.id}`])
            .then(() => {
              window.location.reload();
            });
        })
    } else {
      for (const sponsorParticipateTypes of getSponsorParticipateTypes()) {
        this.eventForm.controls[sponsorParticipateTypes].setErrors({});
      }
      this.eventForm.markAllAsTouched();
      this.showError();
    }
  }
}
