import { FormControl, FormGroup } from '@angular/forms';

export enum EUnitType {
  Meters = 'Meters',
  Laps = 'Laps',
  Kilometers = 'Kilometers'
}

export function getUnitTypeTypes(): string[] {
  return Object.keys(EUnitType);
}

export function setDynamicEUnitTypeTypeFormValues(formGroup: FormGroup) {
  for (const unitType of getUnitTypeTypes()) {
    formGroup.addControl(unitType, new FormControl(false));
  }
}
