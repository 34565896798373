import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { Company } from '../interfaces/company';
import { CreateCompany } from '../interfaces/create-company';
import { Companies } from '../interfaces/companies';
import { apiUrlToken } from '../injection-tokens';
import { EditCompany } from '../interfaces/edit-company';
import { EventShopUser } from '../interfaces/eventShopUser';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getCompany(companyId: number): Observable<Company> {
    return this.http.get<Company>(`${this.apiUrl}/api/Companies/GetCompany/${companyId}`);
  }

  public getCompanies(page: number, count: number): Observable<Companies> {
    return this.http.get<Companies>(`${this.apiUrl}/api/Companies/GetCompanies?page=` + page + '&count=' + count);
  }

  public getUsersCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.apiUrl}/api/Companies/GetUsersCompanies`);
  }

  public getAdminsCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.apiUrl}/api/Companies/GetAdminsCompanies`);
  }

  public createCompany(request: CreateCompany): Observable<Company> {
    return this.http.post<Company>(`${this.apiUrl}/api/Companies/CreateCompany`, request);
  }

  public updateCompany(companyId: number, request: EditCompany): Observable<Company> {
    return this.http.put<Company>(`${this.apiUrl}/api/Companies/UpdateCompany/${companyId}`, request);
  }

  public getCompanyAdmins(companyId: number): Observable<EventShopUser[]> {
    return this.http.get<EventShopUser[]>(`${this.apiUrl}/api/Companies/GetCompanyAdmins/${companyId}`);
  }


}
