<div class="background">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-5">
        <h1 class="big-header-black">{{'information.arrangePage.title' | translate}}</h1>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-8">
        <div class="body-text">{{'information.arrangePage.section1.body1' | translate}}</div>
        <div class="body-text section-header mt-3">{{'information.arrangePage.section1.body2' | translate}}</div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section1.body3' | translate"></div>
        <div class="body-text section-header mt-3">{{'information.arrangePage.section1.body4a' | translate}}</div>
        <div class="body-text mt-3">{{'information.arrangePage.section1.body4b' | translate}}</div>
        <div class="body-text section-header mt-3">{{'information.arrangePage.section1.body5' | translate}}</div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section1.body6' | translate"></div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-8">
        <h1 class="section-header"> {{'information.arrangePage.section2.title' | translate}}</h1>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body1' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body2' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section2.body3' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body4' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section2.body5' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body6' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section2.body7' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body8' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section2.body9' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body10' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body11' | translate"></div>
        <div class="body-text">
          <table class="activity-table">
            <tr>
              <td>{{'information.arrangePage.section2.bodyRun1' | translate}}</td>
              <td>{{'information.arrangePage.section2.bodyRun2' | translate}}</td>
            </tr>
            <tr>
              <td>{{'information.arrangePage.section2.bodyCycling1' | translate}}</td>
              <td>{{'information.arrangePage.section2.bodyCycling2' | translate}}</td>
            </tr>
            <tr>
              <td>{{'information.arrangePage.section2.bodyWalk1' | translate}}</td>
              <td>{{'information.arrangePage.section2.bodyWalk2' | translate}}</td>
            </tr>
            <tr>
              <td>{{'information.arrangePage.section2.bodySwimming1' | translate}}</td>
              <td>{{'information.arrangePage.section2.bodySwimming2' | translate}}</td>
            </tr>
            <tr>
              <td>{{'information.arrangePage.section2.bodyBallActivity1' | translate}}</td>
              <td>{{'information.arrangePage.section2.bodyBallActivity2' | translate}}</td>
            </tr>
            <tr>
              <td>{{'information.arrangePage.section2.bodyRow1' | translate}}</td>
              <td>{{'information.arrangePage.section2.bodyRow2' | translate}}</td>
            </tr>
          </table>
        </div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body18' | translate"></div>
        <h1 class="body-text mt-3" [innerHTML]="'information.arrangePage.section2.body19' | translate"></h1>
        <div class="body-text" [innerHTML]="'information.arrangePage.section2.body20' | translate"></div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-8">
        <h1 class="section-header" [innerHTML]="'information.arrangePage.section3.title' | translate"></h1>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section3.body1' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section3.body2' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section3.body3' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section3.body4' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section3.body5' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section3.body6' | translate"></div>
        <div class="body-text mt-3" [innerHTML]="'information.arrangePage.section3.body7' | translate"></div>
        <div class="body-text" [innerHTML]="'information.arrangePage.section3.body8' | translate"></div>

      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-8">
        <h1 class="section-header">Ansvarsbegrænsning</h1>
        <div class="body-text mt-3">Eventshop ApS har ikke mulighed for at overvåge velgørenhedsorganisationernes eller de private velgørende formåls anvendelse af donerede beløb, hvorfor Eventshop ApS ikke er ansvarlig for hverken direkte eller indirekte tab opstået som følge af brug af www.joinandmove.dk. Sponsorer bør derfor kun donere til en velgørenhedsorganisation eller et privat velgørende formål, som sponsoren har tillid til.</div>
      </div>
    </div>
    <div class="row justify-content-center mt-5 pb-5">
      <div class="col-md-8">
        <h1 class="section-header">Lovgivning</h1>
        <div class="body-text mt-3">Eventshop ApS rådgiver ikke om lovmæssige forhold vedrørende danske indsamlinger. Hvis du ønsker råd og vejledning af lovmæssig karakter til din indsamling opfordrer vi til at kontakte Indsamlingsnævnet. Eventshop ApS er ikke arrangør, men alene en administrativ serviceplatform, og det vil til enhver tid være arrangøreren der benytter www.joinandmove.dk, der er ansvarlig for eventuelle tilladelser. Se mere info via dette link <a href="https://civilstyrelsen.dk/sagsomraader/indsamlingsnaevnet" target="_blank">https://civilstyrelsen.dk/sagsomraader/indsamlingsnaevnet</a></div>
      </div>
    </div>
  </div>
</div>
