<div *ngIf="adminShownSponsorRunUserSummaries">
  <div class="tabs-header-container align-items-center ps-4 pe-4">
    <div class="tabs-header">{{'tab-all-sponsors-admin.title' | translate }}</div>
    <div class="podium-container-header">
      <app-podium></app-podium>
    </div>
  </div>
  <div class="ps-4 pe-4">
    <div class="tabs-content mt-4 mb-4">
      <div>
        <form class="mx-auto">
          <div class="input-group mb-3">
            <div class="form-group">
              <label class="form-label" for="sorting">
                {{'tab-event-sponsor-run-users.sorting' | translate}}
              </label>
              <select id="sorting" class="form-select content-input" name="sorting" required [(ngModel)]="sorting" (change)="sortList()">
                <option value=0>{{'tab-event-sponsors.sortedNames' | translate}}</option>
                <option value=1>{{'tab-event-sponsors.money' | translate}}</option>
                <option value=2>{{'tab-event-sponsors.potential' | translate}}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="table-responsive">
        <table class="tabs-table-rankings table">
          <thead>
          <tr>
            <td></td>
            <td>{{'tab-event-sponsors.tableHeaders.name' | translate}}</td>
            <td>{{'tab-event-sponsors.tableHeaders.sponsored' | translate}}</td>
            <td>{{'tab-event-sponsors.tableHeaders.current' | translate}}</td>
            <td>{{'tab-event-sponsors.tableHeaders.max' | translate}}</td>
            <td class="highlight">{{'tab-event-sponsors.tableHeaders.potential' | translate}}</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sponsorRank of sortedSponsorRanks, let i = index">
            <td>{{i + 1}}</td>
            <td>{{sponsorRank.companyInformation.name}}</td>
            <td>
              <button class="popover-button" (click)="showSponsored.emit(sponsorRank.sponsorSummery)">{{'tab-event-sponsors.showInfo' | translate}}</button>
            </td>
            <td>{{sponsorRank.moneyCollected}},-</td>
            <td>{{(utilService.getSponsorMaxPayment(sponsorRank) ? (utilService.getSponsorMaxPayment(sponsorRank) | number: '': customTranslationService.getLang()) + ',-' : ('global.noCap' | translate))}}</td>
            <td class="highlight">{{(unusedPotential(sponsorRank) === 0 ? ('global.noCap' | translate) : (unusedPotential(sponsorRank) | number: '': customTranslationService.getLang()) + ',-')}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
