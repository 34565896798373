import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eventShop-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input()
  public setDefault = true;

  @ContentChildren(TabComponent)
  public tabs: QueryList<TabComponent>;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngAfterContentInit() {
    this.subscription.add(this.route.queryParamMap.subscribe(params => {
      if (this.setDefault && !params.has('tab')) {
        const params = {
          tab: this.tabs.first.name
        }
        this.router.navigate(['.'], {
          relativeTo: this.route,
          replaceUrl: true,
          queryParams: params,
          queryParamsHandling: 'merge'
        });
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public async selectTab(selectedTab: TabComponent) {
    const params = {
      tab: selectedTab.name
    }
    await this.router.navigate(['.'], {relativeTo: this.route, queryParams: params, replaceUrl: true,});
  }
}
