<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-9 text-center mt-5">
      <h1 class="big-header-black">{{'signUp.title' | translate}}</h1>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="!(authService.signedInChanged$ | async)">
    <div class="col-md-6 d-flex flex-column align-content-center">
      <div class="my-5 mx-auto">
        <div class="text-center">
          {{'global.loginOrCreate' | translate}}
        </div>
        <div class="text-center">
          <button class="content-button button-text text-uppercase font-italic mt-5" type="button" name="register" (click)="createUser()">
            {{'global.createUser' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(authService.signedInChanged$ | async)">
    <div class="row" [ngClass]="activeTab ? '' : 'mb-5'">
      <div class="col-md-12 mt-5">
        <div class="container-fluid banner-buttons">
          <div class="row justify-content-center">
            <div class="col-md-3 text-center" *ngFor="let tab of tabs">
              <button class="action-button button-text text-uppercase" [ngClass]="!(tab.active | async) ? 'action-button-inactive' : ''" type="button" name="register" (click)="tabsParent.selectTab(tab)">
                {{tab.title | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activeTab">
      <div class="col-md-12 mt-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-9 text-center">
              <h1 class="sign-up-big-header-green">
                <span [ngClass]="stepIndex == 0 ? 'current-step': 'no-focus-step'">{{'signUp.one' | translate}}</span>
                <span> - </span>
                <span [ngClass]="stepIndex == 1 ? 'current-step': 'no-focus-step'">{{'signUp.two' | translate}}</span>
                <span> - </span>
                <span [ngClass]="stepIndex == 2 ? 'current-step': 'no-focus-step'">{{'signUp.three' | translate}}</span>
              </h1>
              <h1 class="sign-up-header-green">{{'signUp.' + getActiveTab()?.name + '.subtitle' | translate}}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <eventShop-tabs [setDefault]="false">
        <eventShop-tab [title]="'global.arrange' | translate" [name]="'arrange'">
          <app-company-create-event (stepChanged)="this.stepChanged($event)"></app-company-create-event>
        </eventShop-tab>
        <eventShop-tab [title]="'global.participate' | translate" [name]="'participate'">
          <app-sponsor-user-signup (stepChanged)="this.stepChanged($event)"></app-sponsor-user-signup>
        </eventShop-tab>
        <eventShop-tab [title]="'global.sponsor' | translate" [name]="'sponsor'">
          <app-become-sponsor (stepChanged)="this.stepChanged($event)"></app-become-sponsor>
        </eventShop-tab>
      </eventShop-tabs>
    </div>
  </div>
</div>
