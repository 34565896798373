import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { SponsorEvent } from '../../models/sponsor-event';
import { ActivatedRoute, Router } from '@angular/router';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { catchError, first } from 'rxjs/operators';
import { AuthService, CustomTranslationService, TabComponent, TabsComponent, EventShopUser, BreakpointObserverService } from 'shared';
import { GetSponsorEventCompanyOverviewResponse } from '../../models/GetSponsorEventCompanyOverviewResponse';
import lodash from 'lodash';
import { SponsorRunUser } from '../../models/sponsor-run-user';
import { SponsorRunAdminService } from '../../services/sponsor-run-admin.service';
import { UtilService } from '../../services/util.service';
import { SponsorRunUserSummaryDto } from '../../models/SponsorRunUserSummaryDto';
import { SponsorRankSummary } from '../../models/sponsor-rank-summary';
import { SponsorRunUserUnitTypeSummaryPerSponsor } from '../../models/sponsor-run-user-unit-type-summary-per-sponsor';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {
  public event: SponsorEvent
  public sponsorRunUser: SponsorRunUser;
  public sponsorEventCompanyOverview: GetSponsorEventCompanyOverviewResponse
  public user: EventShopUser;
  private tabs: TabComponent[]
  private activeTab: TabComponent;

  public adminSponsorEventCompanyOverview: GetSponsorEventCompanyOverviewResponse
  public sponsorRankSummaries: SponsorRankSummary[];
  public sponsorRunUserSummaryDto: SponsorRunUserSummaryDto;

  @ViewChildren(TabComponent)
  private tabComponents: QueryList<TabComponent>;

  @ViewChild(TabsComponent)
  public tabsParent: TabsComponent;

  @ViewChild('sponsored') sponsoredElement: NgbModal;
  @ViewChild('sponsorRunPaymentDetails') sponsorRunPaymentDetailsElement: NgbModal;

  private subscription = new Subscription()

  private sponsorRunPaymentDetailsModal: NgbModalRef;
  private sponsoredModal: NgbModalRef;

  constructor(private sponsorService: SponsorRunService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public authService: AuthService,
              private changeDetectorRef: ChangeDetectorRef,
              public customTranslationService: CustomTranslationService,
              private sponsorRunAdminService: SponsorRunAdminService,
              public utilService: UtilService,
              private ngbModal: NgbModal,
              public breakObserverService: BreakpointObserverService) {
    const params = this.activatedRoute.snapshot.params;
    if (params.eventId) {
      this.subscription.add(this.authService.userChanged$.subscribe(u => {
        if (!u) {
          return;
        }
        this.user = u;
        this.sponsorService.getSponsorRunUser(u.id, params.eventId).pipe(first())
          .subscribe(sp => this.sponsorRunUser = sp);
      }));
      this.subscription.add(this.authService.signedInChanged$
        .subscribe(_ => {
          forkJoin([
            this.sponsorService.getSponsorEvent(params.eventId as number),
            this.sponsorService.getSponsorEventCompanyOverview(params.eventId)
          ]).pipe(catchError(_ => this.router.navigate(['/'])), first())
            .subscribe(([sponsorEvent, getSponsorEventCompanyOverviewResponse]: [SponsorEvent, GetSponsorEventCompanyOverviewResponse]) => {
              this.event = sponsorEvent;
              this.sponsorEventCompanyOverview = getSponsorEventCompanyOverviewResponse;

              if (this.event.isAdmin) {
                this.sponsorRunAdminService.getSponsorEventCompanyOverview(this.event.id).pipe(first())
                  .subscribe(s => this.adminSponsorEventCompanyOverview = s);
              }

              this.tabs = this.tabComponents.toArray();
              for (let tab of this.tabs) {
                this.subscription.add(tab.active.subscribe(active => {
                  if (active) {
                    this.activeTab = tab;
                  }
                }))
              }
              this.changeDetectorRef.detectChanges()
            });
        }));
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getEmail(): string {
    if (this.user) {
      return this.user.email;
    }

    return '';
  }

  public calculateRotation(): string {
    // min: 108deg
    // max rotate(104deg)
    const minRotation = -108;
    const maxRotation = 104;
    const endYellowRotation = 36.5;
    let rotation = minRotation;
    if (this.event) {
      // get the percentage  = current/target
      let percentage = this.sponsorEventCompanyOverview.totalMoney / this.sponsorEventCompanyOverview.sponsorEvent.target;
      // do the calculations for (percentage * (max - min)) + min
      rotation = (percentage * (endYellowRotation - minRotation)) + minRotation;
      rotation = lodash.clamp(rotation, minRotation, maxRotation);
    }

    return `transform: rotate(${rotation}deg)`;
  }

  public isTabActive(tabName: string): boolean {
    return this.activeTab?.name == tabName;
  }

  public selectTab(tabName: string) {
    const tab = this.tabComponents.find(t => t.name === tabName);
    this.activeTab = tab;
    this.tabsParent.selectTab(tab);
  }

  public showSponsored(sponsorRankSummaries: SponsorRankSummary[]) {
    this.sponsorRankSummaries = sponsorRankSummaries;
    this.sponsoredModal = this.ngbModal.open(this.sponsoredElement, {size: 'xl', scrollable: true, centered: true, keyboard: false})
  }

  public cancelButtonPressed() {
    this.sponsoredModal?.close();
    this.sponsorRunPaymentDetailsModal?.close()
  }

  public showSponsorRunDetails(sponsorRunUserSummaryDto: SponsorRunUserSummaryDto) {
    this.sponsorRunUserSummaryDto = sponsorRunUserSummaryDto;
    this.sponsorRunPaymentDetailsModal = this.ngbModal.open(this.sponsorRunPaymentDetailsElement, {size: 'xl', scrollable: true, centered: true, keyboard: false})
  }

  public getSponsorName(sponsorRunUserUnitTypeSummaryPerSponsor: SponsorRunUserUnitTypeSummaryPerSponsor): string {
    const sponsorRank = this.adminSponsorEventCompanyOverview.sponsorRanks.find(s => s.sponsorId == sponsorRunUserUnitTypeSummaryPerSponsor.sponsorId);
    return sponsorRank.companyInformation.name;
  }

  public getTotalPayment(sponsorRunUserSummaryDto: SponsorRunUserSummaryDto): number {
    return sponsorRunUserSummaryDto.totalUserMoneyToBePayed + sponsorRunUserSummaryDto.totalSponsorMoney;
  }

  public getSponsorRunPayment(sponsorRunUserSummaryDto: SponsorRunUserSummaryDto): number {
    return sponsorRunUserSummaryDto.userUnitTypeSummaries.map(u => u.totalUserMoney).reduce((sum, current) => sum+current, 0);
  }
}
