import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminService, Company } from 'shared';

@Component({
  selector: 'app-tab-admin-invalid-clubs',
  templateUrl: './tab-admin-invalid-clubs.component.html',
  styleUrls: ['./tab-admin-invalid-clubs.component.scss']
})
export class TabAdminInvalidClubsComponent implements OnInit {
  public clubs$: Observable<Company[]>;

  @Output()
  public clubIdEvent = new EventEmitter<number>();

  constructor(private adminService: AdminService) {
    this.clubs$ = adminService.getInvalidCompanies();
  }

  ngOnInit(): void {
  }

  public clubSelected(clubId: number) {
    this.clubIdEvent.emit(clubId);
  }
}
