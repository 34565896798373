import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Consts } from '../../consts';

@Component({
  selector: 'app-sign-up-succeeded-event',
  templateUrl: './sign-up-succeeded-event.component.html',
  styleUrls: ['./sign-up-succeeded-event.component.scss']
})
export class SignUpSucceededEventComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  public eventLink() {
    return `/${Consts.eventRoute}/${this.activatedRoute.snapshot.params.eventId}`
  }
}
