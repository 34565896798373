<form [formGroup]="registerActivityForm">
  <div class="form-group">
    <label class="form-label" for="unitCount">{{'registerActivity.distance' | translate}}*</label>
    <input id="unitCount" class="form-control content-input" formControlName="unitCount" required type="number" step="0.01" min="0.01" (blur)="unitCountBlur()"/>
  </div>
  <div class="form-group">
    <label class="form-label" for="combination">{{'signUp.sponsor.chooseSponsorParticipateType' | translate}}</label>
    <select id="combination" class="form-select content-input" formControlName="combination" required (change)="combinationSelected()" [ngClass]="showSelectedCombinationError ? 'ng-touched ng-invalid' : ''">
      <option value="-1" disabled selected>{{'global.chooseFromList' | translate}}</option>
      <option *ngFor="let combination of sponsorRunUser?.sponsorRunUserSignUpCombinations, let i = index" value="{{i}}">{{'global.sponsorParticipateTypeItem.' + combination.sponsorParticipationType | translate}} - {{'global.unitTypeItem.' + combination.unitType | translate}}</option>
    </select>
  </div>
</form>

