import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ESponsorParticipateType,
  getSponsorParticipateTypes,
  setDynamicESponsorParticipateTypeFormValues
} from '../../enums/esponsor-participate-type.enum';
import {
  ESponsorTeamType,
  getSponsorTeamTypes,
  setDynamicESponsorTeamTypeFormValues
} from '../../enums/esponsor-team-type.enum';
import { getEventProducts, setDynamicEEventProductFormValues } from '../../enums/eevent-product.enum';
import { Consts } from '../../consts';
import { CreateSponsorEventRequest } from '../../models/create-sponsor-event-request';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { AuthService, Company } from 'shared';
import { SponsorEvent } from '../../models/sponsor-event';
import { FormComponent } from '../form-component/form.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-edit-event',
  templateUrl: './create-edit-event.component.html',
  styleUrls: ['./create-edit-event.component.scss']
})
export class CreateEditEventComponent extends FormComponent implements OnInit, OnChanges {
  @Input()
  public company: Company;
  @Input()
  public event: SponsorEvent;

  public createEditEventForm = new FormGroup({
    companyId: new FormControl(-1, [Validators.required]),
    // eventProduct: new FormControl(-1, [Validators.required]),
    name: new FormControl(undefined, [Validators.required]),
    contactPerson: new FormControl(undefined, [Validators.required]),
    contactEmail: new FormControl(undefined, [Validators.required, Validators.email]),
    location: new FormGroup({
      streetOne: new FormControl(undefined, [Validators.required]),
      streetTwo: new FormControl(undefined),
      postalCode: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{4}")]),
      city: new FormControl(undefined, [Validators.required]),
    }),
    startDate: new FormControl(undefined, [Validators.required]),
    startTime: new FormControl(undefined, [Validators.required]),
    endDate: new FormControl(undefined, [Validators.required]),
    endTime: new FormControl(undefined, [Validators.required]),
    entryFee: new FormControl(0, [Validators.required]),
    comment: new FormControl(undefined, [Validators.maxLength(1000)]),
    webPage: new FormControl(undefined),
    facebook: new FormControl(undefined),
    target: new FormControl(undefined, [Validators.required, Validators.min(1)])
  })

  constructor(
    private sponsorService: SponsorRunService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService
  ) {
    super();
    this.setDynamicFormValues();

    //TODO remove when supporting teams
    this.createEditEventForm.controls[ESponsorTeamType.Individual].setValue(true);

    this.setForm(this.createEditEventForm);
  }

  ngOnInit(): void {
    this.form.emit(this.createEditEventForm);
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["event"] && this.event) {
      this.createEditEventForm.patchValue(this.event);

      this.createEditEventForm.controls.startDate.setValue(moment(this.event.startDate).format('YYYY-MM-DD'));
      this.createEditEventForm.controls.startTime.setValue(moment(this.event.startDate).format('HH:mm'))
      this.createEditEventForm.controls.endDate.setValue(moment(this.event.endDate).format('YYYY-MM-DD'));
      this.createEditEventForm.controls.endTime.setValue(moment(this.event.startDate).format('HH:mm'))

      for (const sponsorParticipateTypes of getSponsorParticipateTypes()) {
        if (this.event.sponsorParticipateTypes.indexOf(ESponsorParticipateType[sponsorParticipateTypes]) > -1) {
          this.createEditEventForm.controls[sponsorParticipateTypes].setValue(true);
        }
      }

      for (const sponsorTeamType of getSponsorTeamTypes()) {
        if (this.event.sponsorTeamTypes.indexOf(ESponsorTeamType[sponsorTeamType]) > -1) {
          this.createEditEventForm.controls[sponsorTeamType].setValue(true);
        }
      }
    }

    if (changes["company"] && this.company) {
      this.createEditEventForm.controls.companyId.setValue(this.company.id);
    }

    if (changes["validate"] && this.validate) {
      this.subscription.add(this.validate.subscribe(v => {
        if (v) {
          if (!this.sponsorParticipateTypesValid()) {
            for (const sponsorParticipateTypes of getSponsorParticipateTypes()) {
              this.createEditEventForm.controls[sponsorParticipateTypes].setErrors({});
            }
          }

          // for (const sponsorTeamType of getSponsorTeamTypes()) {
          //   this.createEditEventForm.controls[sponsorTeamType].setErrors({});
          // }
          this.createEditEventForm.markAllAsTouched();
        }
      }))
    }
  }

  private setDynamicFormValues() {
    setDynamicESponsorParticipateTypeFormValues(this.createEditEventForm);
    setDynamicESponsorTeamTypeFormValues(this.createEditEventForm);
    setDynamicEEventProductFormValues(this.createEditEventForm);
  }

  public customValidated(): boolean {
    //&& this.createEditEventForm.controls.eventProduct.value !== -1
    return this.sponsorParticipateTypesValid() && this.participationTypeValid() && this.authService.signedInChanged$.value && this.createEditEventForm.controls.companyId.value !== -1;
  }

  public sponsorParticipateTypesValid(): boolean {
    let selected = false;
    for (const sponsorParticipateType of getSponsorParticipateTypes()) {
      selected = this.createEditEventForm.controls[sponsorParticipateType].value;
      if (selected) {
        break;
      }
    }

    return selected;
  }

  public sponsorParticipateTypeSelected() {
    for (const sponsorParticipateType of getSponsorParticipateTypes()) {
      this.createEditEventForm.controls[sponsorParticipateType].setErrors(null);
      this.createEditEventForm.controls[sponsorParticipateType].markAsUntouched();
    }
    this.createEditEventForm.controls[ESponsorTeamType.Individual].setValue(true);
  }

  public participationTypeValid(): boolean {
    // let selected = false;
    // for (const sponsorTeamType of getSponsorTeamTypes()) {
    //   selected = this.createEditEventForm.controls[sponsorTeamType].value;
    //   if (selected) {
    //     break;
    //   }
    // }
    //
    // return selected;
    return true;
  }

  public today(): Date {
    return new Date();
  }

  public getSponsorTeamTypes(): string[] {
    return getSponsorTeamTypes();
  }

  public getSponsorParticipateTypes(): string[] {
    return getSponsorParticipateTypes();
  }

  public getEventProducts(): string[] {
    return getEventProducts();
  }
}
