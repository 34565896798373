import { Component, OnInit } from '@angular/core';
import { Consts } from '../../consts';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sign-up-succeeded-sponsor',
  templateUrl: './sign-up-succeeded-sponsor.component.html',
  styleUrls: ['./sign-up-succeeded-sponsor.component.scss']
})
export class SignUpSucceededSponsorComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  public eventLink() {
    return `/${Consts.eventRoute}/${this.activatedRoute.snapshot.params.eventId}`
  }
}
