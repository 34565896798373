<div class="col-md-12" xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid ps-0 pe-0">
    <div class="row tabs-header-container">
      <div class="tabs-header align-items-center">{{'admin.company.title' | translate}}</div>
    </div>
  </div>
  <div class="row mt-5">
    <tr class="tabs-content mt-4 mb-4">
      <table class="tabs-table" *ngIf="(club$ | async) as club">
        <tr>
          <td>{{'admin.company.name' | translate}}:</td>
          <td>{{club.name}}</td>
        </tr>
        <tr>
          <td>{{'global.cvr' | translate}}:</td>
          <td>{{club.cvr}}</td>
        </tr>
        <tr>
          <td>{{'admin.company.address1' | translate}}:</td>
          <td>{{club.address.streetOne}}</td>
        </tr>
        <tr>
          <td>{{'admin.company.address2' | translate}}:</td>
          <td>{{club.address.streetTwo}}</td>
        </tr>
        <tr>
          <td>{{'admin.company.postalCodeAndCity' | translate}}:</td>
          <td>{{club.address.postalCode}} {{club.address.city}}</td>
        </tr>
      </table>
      <div class="row mt-5">
        <button class="content-button button-text text-uppercase font-italic" (click)="validate()">
          {{'admin.company.validate' | translate}}
        </button>
      </div>
  </div>
</div>
