import { Component, OnInit } from '@angular/core';
import { CompaniesService, Company } from 'shared';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit {

  companySearchString: string;
  message: string;
  foundCompanies: Company[];
  constructor(private companiesService: CompaniesService) { }

  ngOnInit(): void {
  }

  search() {
    this.companiesService.getCompanies(0, 5).subscribe(result => {
      if (result.total === 0) {
        this.message = 'Intet fundet!';
        console.warn(result);
      } else {
        this.message = '';
        this.foundCompanies = result.companies;
        console.warn(result.companies);
      }
    });
  }
}
