import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { GetSponsorEventCompanyOverviewResponse } from '../../models/GetSponsorEventCompanyOverviewResponse';
import { CustomTranslationService } from 'shared';
import { RankCompanyInformation } from '../../models/rank-company-information';
import { ESponsorType } from '../../enums/esponsor-type.enum';
import { UtilService } from '../../services/util.service';
import { Rank } from '../../models/rank';
import lodash from 'lodash';
import { PodiumComponent } from '../podium/podium.component';
import { SponsorRankSummary } from '../../models/sponsor-rank-summary';

@Component({
  selector: 'app-tab-all-sponsors-admin',
  templateUrl: './tab-all-sponsors-admin.component.html',
  styleUrls: ['./tab-all-sponsors-admin.component.scss']
})
export class TabAllSponsorsAdminComponent implements OnInit, OnChanges, AfterViewInit{
  @Input()
  public adminShownSponsorRunUserSummaries: GetSponsorEventCompanyOverviewResponse

  @Output()
  public showSponsored = new EventEmitter<SponsorRankSummary[]>();
  public sortedSponsorRanks: Rank[];

  public sorting = '0';

  @ViewChild(PodiumComponent)
  private podiumComponent: PodiumComponent;

  constructor(public customTranslationService: CustomTranslationService, public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (changes["adminShownSponsorRunUserSummaries"] && this.adminShownSponsorRunUserSummaries) {
      this.sortList();
      this.setRanks();
    }
  }

  ngAfterViewInit(): void {
    if (this.adminShownSponsorRunUserSummaries && this.podiumComponent) {
      this.setRanks();
    }
  }

  private setRanks() {
    const ranks = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRanks, [function (o) {
      return o.moneyCollected;
    }]).slice(0, 3);

    this.podiumComponent?.setRanks(ranks);
  }


  public isCompany(rankCompanyInformation: RankCompanyInformation) {
    return rankCompanyInformation.sponsorType === ESponsorType.Cvr;
  }

  public unusedPotential(sponsorRank: Rank): number {
    let cap = this.utilService.getCapSumSponsorRank(sponsorRank, this.adminShownSponsorRunUserSummaries);
    if(cap > 0){
      return cap - sponsorRank.moneyCollected;
    }
    return cap;
  }

  public sortList() {
    switch (this.sorting) {
      case '0':
        this.sortedSponsorRanks = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRanks, [function (o) {
          return o.name;
        }]);
        break;
      case '1':
        this.sortedSponsorRanks = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRanks, [function (o) {
          return o.moneyCollected;
        }]);
        break;
      case '2':
        this.sortedSponsorRanks = lodash.sortBy(this.adminShownSponsorRunUserSummaries.sponsorRanks, [function (o) {
          return this.utilService.getCapSumSponsorRank(o, this.adminShownSponsorRunUserSummaries);
        }]);
        break;
    }
  }
}
