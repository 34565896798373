import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Rank } from '../../models/rank';
import { CustomTranslationService, PaginationBaseComponent } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import lodash from 'lodash';
import { GetSponsorEventCompanyOverviewResponse } from '../../models/GetSponsorEventCompanyOverviewResponse';
import { UtilService } from '../../services/util.service';
import { PodiumComponent } from '../podium/podium.component';
import { SponsorRankSummary } from '../../models/sponsor-rank-summary';

@Component({
  selector: 'app-tab-event-sponsors',
  templateUrl: './tab-event-sponsors.component.html',
  styleUrls: ['./tab-event-sponsors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabEventSponsorsComponent extends PaginationBaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  public sponsorEventCompanyOverviewResponse: GetSponsorEventCompanyOverviewResponse;

  private sortedSponsorRanks: Rank[];

  public shownSponsorRanks: Rank[];
  public sorting = '0';

  public indexOffset = 1;

  @Output()
  public showSponsored = new EventEmitter<SponsorRankSummary[]>();

  @ViewChild(PodiumComponent)
  private podiumComponent: PodiumComponent;

  constructor(protected router: Router, protected  activatedRoute: ActivatedRoute, public customTranslationService: CustomTranslationService, public utilService: UtilService) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes): void {
    if (changes["sponsorEventCompanyOverviewResponse"] && this.sponsorEventCompanyOverviewResponse) {
      this.sortList();
      this.totalNumberOfPages = Math.ceil(this.sponsorEventCompanyOverviewResponse.sponsorRanks.length / this.itemsPerPage);
      this.updateItems(this.page);

      this.setRanks();
    }
  }

  ngAfterViewInit(): void {
    if (this.sponsorEventCompanyOverviewResponse && this.podiumComponent) {
      this.setRanks();
    }
  }

  private setRanks() {
    const ranks = lodash.sortBy(this.sponsorEventCompanyOverviewResponse.sponsorRanks, [function (o) {
      return o.moneyCollected;
    }]).slice(0, 3);

    this.podiumComponent?.setRanks(ranks);
  }

  protected updateItems(page: number) {
    this.page = page;
    this.shownSponsorRanks = this.sortedSponsorRanks?.slice((page - 1) * this.itemsPerPage, page * this.itemsPerPage);
    this.indexOffset = (page - 1) * this.itemsPerPage;
  }

  public async goToPage(page: number) {
    this.updateItems(page);
  }

  public sortList() {
    switch (this.sorting) {
      case '0':
        this.sortedSponsorRanks = lodash.sortBy(this.sponsorEventCompanyOverviewResponse.sponsorRanks, [function (o) {
          return o.companyInformation.name;
        }]);
        break;
      case '1':
        this.sortedSponsorRanks = lodash.reverse(lodash.sortBy(this.sponsorEventCompanyOverviewResponse.sponsorRanks, [function (o) {
          return o.moneyCollected;
        }]));
        break;
    }

    this.updateItems(1);
  }
}
