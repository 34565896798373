import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SponsorRunService } from '../../services/sponsor-run.service';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { SponsorRunSponsorService } from '../../services/sponsor-run-sponsor.service';

@Component({
  selector: 'app-sponsorlink',
  templateUrl: './sponsorlink.component.html',
  styleUrls: ['./sponsorlink.component.scss']
})
export class SponsorlinkComponent implements OnInit {

  constructor(private route: ActivatedRoute, private sponsorService: SponsorRunSponsorService, private router: Router) {
  }

  ngOnInit(): void {
    const link = this.route.snapshot.paramMap.get('id');
    this.sponsorService.getSponsorRunUserDataFromLink(link).pipe(first()).subscribe(result =>{
      this.router.navigate([Consts.signUpRoute], {queryParams: {'tab': 'sponsor', 'id': link, 'eventId': result.event.id}});
    });
  }
}
